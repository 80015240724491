import { useMaterialsContext } from "hooks/materials";
import React from "react";
import ReactDOM from "react-dom";
import { CgClose } from "react-icons/cg";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import * as Constants from "constants.js";

import * as S from "./styles";
import { useSelector } from "react-redux";
import * as authSelector from "redux/selectors/auth";

function TopicTypeModal({ isEnabled }) {
  const authenticatedUser = useSelector(authSelector.authenticatedUser);
  const {
    setSelectedTopicType,
    setCurrentPage,
    currentPage
  } = useMaterialsContext();
  const history = useHistory();
  const location = useLocation();

  return isEnabled
    ? ReactDOM.createPortal(
        <S.TopicTypeModalContainer>
          <S.TopicTypeModalContent>
            <S.CloseModalContainer
              onClick={() => {
                if (location.state && location.state.returnTo)
                  history.push(location.state.returnTo);
                else if (
                  currentPage === "show-ebooks" ||
                  currentPage === "show-videos"
                ) {
                  setCurrentPage("");
                  setSelectedTopicType(0);
                } else setSelectedTopicType(1);
              }}>
              <CgClose />
            </S.CloseModalContainer>
            <S.ModalTitle>Selecione a categoria</S.ModalTitle>
            <S.ModalButton
              onClick={() => {
                setSelectedTopicType(Constants.TOPIC_TYPE_ARGUMENTATIVE);
              }}>
              Redação dissertativa-argumentativa
            </S.ModalButton>
            <S.ModalButton
              onClick={() =>
                setSelectedTopicType(Constants.TOPIC_TYPE_EXPOSITIVE)
              }>
              Redação dissertativa-expositiva (Cebraspe)
            </S.ModalButton>
            <S.ModalButton
              onClick={() =>
                setSelectedTopicType(Constants.TOPIC_TYPE_JURIDICAL)
              }>
              Redação jurídica (Estudo de caso)
            </S.ModalButton>
            <S.ModalButton
              onClick={() =>
                setSelectedTopicType(Constants.TOPIC_TYPE_GRAMMAR_LESSONS)
              }>
              Correção gramatical (Concurso CNU - NÍVEL SUPERIOR)
            </S.ModalButton>
            {currentPage === "show-videos" ? (
              <>
                <S.ModalButton
                  onClick={() =>
                    setSelectedTopicType(Constants.TOPIC_TYPE_GRAMMAR_LESSONS)
                  }>
                  Aulas de gramática
                </S.ModalButton>
                <S.ModalButton
                  onClick={() =>
                    setSelectedTopicType(
                      Constants.TOPIC_TYPE_COMPLEMENTARY_LESSONS
                    )
                  }>
                  Aulas complementares
                </S.ModalButton>
              </>
            ) : null}
          </S.TopicTypeModalContent>
        </S.TopicTypeModalContainer>,
        document.body
      )
    : null;
}

export default TopicTypeModal;
