const hasPermission = (permissions, entity, action) => {

  const currentPermission = permissions?.find(
    (perm) => perm.entity === entity && perm.action === action
  );

  return !!currentPermission;
};

export default hasPermission;
