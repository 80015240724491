import { createReducer } from "@reduxjs/toolkit";
import set from "lodash/fp/set";
import * as act from "../actions/types";

const DEFAULT_STATE = {
  byEssay: {}
};

const reviews = createReducer(DEFAULT_STATE, {
  [act.reviewNew.type]: (state, action) => {
    // const { user, authenticated } = action.payload;
    // return set("session", {
    //   user,
    //   authenticated,
    // })(state);
    return state;
  },
  [act.reviews.type]: (state, action) => {
    // const { user, authenticated } = action.payload;
    const { essay, ...review } = action.payload;
    return set(["byEssay", essay], review)(state);
  }
});

export default reviews;
