import get from "lodash/fp/get";
import { createSelector } from "reselect";

const essaysSelector = get("essays");

export const essays = createSelector(essaysSelector, get("byId"));

export const essaysSimulationRanking = (simulationid) =>
  createSelector(essaysSelector, get(["bySimulationIdRanking", simulationid]));

const byUserId = createSelector(essaysSelector, get("byUserId"));

export const essaysByUserId = (id) =>
  createSelector(essays, byUserId, (byId, byUserId) => {
    if (byUserId[id] === undefined) return []; // no essays for this userid in store
    return byUserId[id].map((essayId) => byId[essayId]);
  });

export const essayById = (id) => createSelector(essays, get(id));

export const essaysInfo = createSelector(essaysSelector, get("info"));
