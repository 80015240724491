import React from "react";
import ReactDOM from "react-dom";
import { IoSendSharp } from "react-icons/io5";
import { useHistory } from "react-router";

import * as S from "./styles";

function SendEssayButton() {
  const history = useHistory();
  return ReactDOM.createPortal(
    <S.Container id="send-essay-button" onClick={() => history.push("/aluno/temas")}>
      <IoSendSharp size={20} /> <span>Enviar Redação</span>
    </S.Container>,
    document.body
  );
}

export default SendEssayButton;
