import * as api from "lib/api";
import * as act from "./types";

export const onLogin = (email, password) => async (dispatch) => {
  try {
    const response = await api.login(email, password);
    // TODO: passar dados descritivos pra action, ao inves do objeto inteiro
    // de data;

    dispatch(act.login(response.data));
    return response.data.user;
  } catch (err) {
    throw err.response;
  }
};

export const onLogout = () => async (dispatch) => {
  try {
    const response = await api.logout();
    dispatch(act.logout(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onSignUp = (name, email, password, token) => async () => {
  try {
    const response = await api.signup(name, email, password, token);
    return response;
  } catch (err) {
    throw err.response;
  }
};

export const onRegister = (name, email, password, type, credits) => async (
  dispatch
) => {
  try {
    const response = await api.register(name, email, password, type, credits);
    return dispatch(act.register(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onSession = () => async (dispatch) => {
  try {
    const response = await api.session();
    dispatch(act.session(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onUserPasswordReset = (email) => async () => {
  try {
    const response = await api.userPasswordRequestReset(email);
    return response;
  } catch (err) {
    throw err.response;
  }
};

export const onUserConfirmPasswordReset = (password, token) => async () => {
  try {
    const response = await api.userPasswordConfirmReset(password, token);
    return response;
  } catch (err) {
    throw err.response;
  }
};
