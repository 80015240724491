import React, { useState, useEffect, useCallback } from "react";
import { useMaterialsContext } from "hooks/materials";
import { useNotification } from "utils/hooks";
import { http } from "lib/api";

import * as S from "./styles";
import { FiArrowLeft } from "react-icons/fi";
import useWindowSize from "hooks/useWindowSize";

function VideoPage() {
  const {
    videoId,
    setCurrentPage,
    videosArray,
    setVideoId
  } = useMaterialsContext();

  const { width, height } = useWindowSize();

  const [currentVideo, setCurrentVideo] = useState(null);

  const handleCurrentVimeoSize = useCallback(() => {
    if (width > 1000) return 550;
    if (width > 900) return 500;
    if (width > 800) return 370;
    if (width > 600) return 350;
    if (width > 400) return 200;
  }, [width]);
  const [currentVimeoSize, setCurrentVimeoSize] = useState(
    handleCurrentVimeoSize
  );

  const { setNotificationSuccess } = useNotification();

  const getVideoLink = useCallback(async () => {
    const { data } = await http.get("materials/" + videoId);

    setCurrentVideo(data);
  }, [videoId, setCurrentVideo]);

  const markVideoAsWatched = useCallback(async () => {
    try {
      await http.patch(`materials/${videoId}/finish`);
      setNotificationSuccess("Vídeo terminado com sucesso!");
    } catch (error) {}
  }, [videoId]);

  const getPathUrl = useCallback((path) => {
    if (path?.includes('https://'))
      return path;

    return `https://${path}`;
  }, []);

  useEffect(() => {
    getVideoLink();
  }, [videoId]);

  useEffect(() => {
    setCurrentVimeoSize(handleCurrentVimeoSize());
  }, [handleCurrentVimeoSize]);

  return (
    currentVideo && (
      <S.Container>
        <FiArrowLeft
          size={25}
          onClick={() => {
            setCurrentPage("show-videos");
          }}
        />
        <S.Header>
          <S.Title>{currentVideo.name}</S.Title>
          <S.DownloadButton
            href={getPathUrl(currentVideo.content.video?.pdf?.path)}
            isEnabled={!!currentVideo.content.video?.pdf?.path}
            target="_blank"
            rel="noopener noreferrer"
            download>
            Baixar pdf da Aula
          </S.DownloadButton>
        </S.Header>
        <S.VideoContent>
          <S.VimeoElement
            video={currentVideo.content.video.url}
            controls={true}
            speed={true}
            showTitle={false}
            autoplay
            height={currentVimeoSize}
            onEnd={markVideoAsWatched}
          />
        </S.VideoContent>
        <S.DescriptionContainer>
          <h3>Descrição</h3>
          <div>
            <p>{currentVideo.description}</p>
          </div>
        </S.DescriptionContainer>
        <S.ChangeVideo>
          <button
            onClick={() => {
              const previousVideoIndex = videosArray.indexOf(videoId) - 1;
              const videoIndex =
                previousVideoIndex < 0
                  ? previousVideoIndex + videosArray.length
                  : previousVideoIndex;
              setVideoId(videosArray[videoIndex]);
            }}>
            Anterior
          </button>
          <button
            onClick={() => {
              const videoIndex =
                (videosArray.indexOf(videoId) + 1) % videosArray.length;
              setVideoId(videosArray[videoIndex]);
            }}>
            Próximo
          </button>
        </S.ChangeVideo>
      </S.Container>
    )
  );
}

export default VideoPage;
