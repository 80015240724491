import React, { useCallback, useEffect, useState } from "react";

import { http } from "lib/api";

import * as S from "./styles";
import { FiArrowLeft, FiDownload, FiEdit, FiTrash } from "react-icons/fi";
import { useMaterialsContext } from "hooks/materials";
import { useSelector } from "react-redux";
import { useNotification } from "utils/hooks";
import ModalConfirm from "components/Modal/ModalConfirm";
import { useLocation } from "react-router-dom";
import CreditsBanner from "components/CreditsBanner";

function EbookList() {
  const user = useSelector((state) => state.auth.session.user);

  const { pathname } = useLocation();

  const {
    selectedTopicType,
    setCurrentPage,
    setSelectedTopicType,
    setMaterialToEdit,
    currentPage
  } = useMaterialsContext();

  const isEssayTemplatePage =
    pathname.includes("folhas-de-redacao") || !selectedTopicType;

  const { setNotificationSuccess, setNotificationError } = useNotification();

  const [deleteEbookObject, setDeleteEbookObject] = useState({
    openModal: false,
    id: ""
  });

  const [ebooks, setEbooks] = useState([]);
  const getEbooks = useCallback(async () => {
    const { data } = await http.get("materials");

    setEbooks(
      selectedTopicType && !isEssayTemplatePage
        ? data.filter(
            (ebook) =>
              ebook.content_type === "ebook" &&
              selectedTopicType === ebook.category
          )
        : data.filter(
            (ebook) =>
              (ebook.content_type === "ebook" && !isEssayTemplatePage) ||
              (isEssayTemplatePage && ebook.content_type === "ebook")
          )
    );
  }, [selectedTopicType, setEbooks]);

  const handleDeleteEbook = useCallback(async () => {
    try {
      await http.delete("materials/" + deleteEbookObject.id);
      setNotificationSuccess("Vídeo deletado com sucesso!");
      setEbooks((prev) =>
        prev.filter((ebook) => ebook.id !== deleteEbookObject.id)
      );
      setDeleteEbookObject({ openModal: false, id: "" });
    } catch (error) {
      setNotificationError("Não foi possível deletar o vídeo.");
    }
  }, [deleteEbookObject.id]);

  useEffect(() => {
    if (
      selectedTopicType ||
      currentPage === "show-essays-paper" ||
      isEssayTemplatePage
    )
      getEbooks();
  }, [selectedTopicType]);

  return (
    <>
      <ModalConfirm
        show={deleteEbookObject.openModal}
        onConfirm={handleDeleteEbook}
        body="Você tem certeza que quer deletar esse ebook?"
        onClose={() => setDeleteEbookObject({ openModal: false, id: "" })}
      />
      <S.Container>
        <CreditsBanner />
        {isEssayTemplatePage ? (
          <h2>Folhas de Redação</h2>
        ) : (
          <>
            <S.GoBackButton
              onClick={() => {
                setCurrentPage("");
                setSelectedTopicType(0);
              }}>
              <FiArrowLeft size={25} />
              <span>Voltar para materiais</span>
            </S.GoBackButton>
            <h2>E-books</h2>
            <S.SelectCategoryButton
              onClick={() => {
                setSelectedTopicType(0);
              }}>
              Selecionar Categoria
            </S.SelectCategoryButton>
          </>
        )}
        <S.ItemsWrapper>
          {ebooks.map((ebook) => (
            <S.MaterialElement key={ebook._id}>
              <h3>{ebook.name}</h3>
              <S.ImageContainer>
                <img
                  src={ebook.thumbnailPath}
                  alt={ebook.name}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://www.skyscrapercenter.com/img/my-ctbuh/video-thumbnail.jpg";
                  }}
                />
              </S.ImageContainer>
              <S.DownloadButton
                href={
                  ebook.content.ebook.materialUploadPath ||
                  ebook.content.essayTemplate.materialUploadPath
                }
                target="_blank"
                download>
                <FiDownload /> Download
              </S.DownloadButton>

              {user.type === "admin" && (
                <>
                  <S.DeleteVideo>
                    <FiTrash
                      size={20}
                      color="red"
                      onClick={() =>
                        setDeleteEbookObject({ openModal: true, id: ebook.id })
                      }
                    />
                  </S.DeleteVideo>
                  <S.EditVideo>
                    <FiEdit
                      size={20}
                      color="black"
                      onClick={() => {
                        setMaterialToEdit(ebook);
                        setCurrentPage("edit-material");
                      }}
                    />
                  </S.EditVideo>
                </>
              )}
            </S.MaterialElement>
          ))}
        </S.ItemsWrapper>
      </S.Container>
    </>
  );
}

export default EbookList;
