import React, { useCallback, useEffect, useState, fetchData } from "react";
import { useSelector } from "react-redux";

import { http } from "lib/api";

import * as S from "./styles";
import { FiArrowLeft, FiTrash, FiEdit } from "react-icons/fi";
import { useMaterialsContext } from "hooks/materials";
import { FaPlay } from "react-icons/fa";
import useNotification from "utils/hooks/useNotification";
import ModalConfirm from "components/Modal/ModalConfirm";

function VideoList() {
  const {
    selectedTopicType,
    setVideoId,
    setCurrentPage,
    setSelectedTopicType,
    setVideosArray,
    setMaterialToEdit
  } = useMaterialsContext();

  const [deleteVideoObject, setDeleteVideoObject] = useState({
    openModal: false,
    id: ""
  });

  const { setNotificationSuccess, setNotificationError } = useNotification();

  const user = useSelector((state) => state.auth.session.user);

  const [videos, setVideos] = useState([]);

  const getVideos = useCallback(async () => {
    const { data } = await http.get("materials");
    const videosMaterials = data.filter(
      (video) =>
        video.content_type === "video" && selectedTopicType === video.category
    );
    setVideos(videosMaterials);
    setVideosArray(videosMaterials.map((video) => video.id));
  }, [selectedTopicType, setVideos]);

  const handleDeleteVideo = useCallback(async () => {
    try {
      await http.delete("materials/" + deleteVideoObject.id);
      setNotificationSuccess("Vídeo deletado com sucesso!");
      setVideos((prev) =>
        prev.filter((video) => video.id !== deleteVideoObject.id)
      );
      setDeleteVideoObject({ openModal: false, id: "" });
    } catch (error) {
      setNotificationError("Não foi possível deletar o vídeo.");
    }
  }, [deleteVideoObject.id]);

  const getVideoStatus = useCallback((video) => {
    if (!video.isStarted) return "Não assistido";

    if (video.isStarted && video.isFinished) return "Assistido";

    return "Em andamento";
  }, []);

  const getStatusColor = useCallback((video) => {
    if (!video.isStarted) return "#d1d1d1";

    if (video.isStarted && video.isFinished) return "#5cba54";

    return "#ffad3b";
  }, []);

  useEffect(() => {
    if (selectedTopicType) getVideos();
  }, [selectedTopicType]);

  return (
    <>
      <ModalConfirm
        show={deleteVideoObject.openModal}
        onConfirm={handleDeleteVideo}
        body="Você tem certeza que quer deletar esse vídeo?"
        onClose={() => setDeleteVideoObject({ openModal: false, id: "" })}
      />
      <S.Container>
        <S.GoBackButton
          onClick={() => {
            setCurrentPage("");
            setSelectedTopicType(0);
          }}>
          <FiArrowLeft size={25} />
          <span>Voltar para materiais</span>
        </S.GoBackButton>
        <h2>Vídeos</h2>
        <S.SelectCategoryButton
          onClick={() => {
            setSelectedTopicType(0);
          }}>
          Selecionar Categoria
        </S.SelectCategoryButton>
        <S.ItemsWrapper>
          {videos.map((video) => (
            <S.MaterialElement key={video.id}>
              <h3>{video.name}</h3>
              <S.ImageContainer>
                <img
                  src={
                    video.thumbnailPath ??
                    "http://aquiporti.ec/dreamlab/wp-content/uploads/2019/03/default-video-image.png"
                  }
                  alt={video.name}
                  onError={(e) => {
                    e.target.src =
                      "http://aquiporti.ec/dreamlab/wp-content/uploads/2019/03/default-video-image.png";
                  }}
                />
              </S.ImageContainer>

              {user.type === "admin" && (
                <>
                  <S.DeleteVideo>
                    <FiTrash
                      size={20}
                      color="red"
                      onClick={() =>
                        setDeleteVideoObject({ openModal: true, id: video.id })
                      }
                    />
                  </S.DeleteVideo>
                  <S.EditVideo>
                    <FiEdit
                      size={20}
                      color="black"
                      onClick={() => {
                        setMaterialToEdit(video);
                        setCurrentPage("edit-material");
                      }}
                    />
                  </S.EditVideo>
                </>
              )}
              <S.PlayIcon
                onClick={() => {
                  setVideoId(video.id);
                  setCurrentPage("video-player");
                }}>
                <FaPlay size={50} />
              </S.PlayIcon>
              <S.VideoStatus color={getStatusColor(video)}>
                {getVideoStatus(video)}
              </S.VideoStatus>
            </S.MaterialElement>
          ))}
        </S.ItemsWrapper>
      </S.Container>
    </>
  );
}

export default VideoList;
