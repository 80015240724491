import get from "lodash/fp/get";
import { createSelector } from "reselect";

const authSelector = get("auth");

export const authenticated = createSelector(
  authSelector,
  get(["session", "authenticated"])
);

export const authenticatedUser = createSelector(
  authSelector,
  get(["session", "user"])
);
