import React, { useRef, useState, useEffect } from "react";
import * as S from "./styles.js";
import { Mic, Pause, PlayArrow } from "@material-ui/icons";
import { FaCircleStop } from "react-icons/fa6";

const AudioRecorder = ({
  audioBlob,
  setAudioBlob,
  onStartRecording,
  onStopRecording
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  useEffect(() => {
    let interval = null;
    if (isRecording && !isPaused) {
      interval = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
    } else if (isPaused || !isRecording) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRecording, isPaused]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        chunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: "audio/wav" });
        chunksRef.current = [];
        setAudioBlob(blob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setSeconds(0);
      onStartRecording();
    } catch (err) {
      console.error("Erro ao acessar o microfone: ", err);
    }
  };

  const pauseRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
    }
  };

  const resumeRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "paused"
    ) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      onStopRecording();
      setIsRecording(false);
      setIsPaused(false);
    }
  };

  return (
    <S.PlayerControls isRecording={isRecording}>
      {isRecording && (
        <S.StateAndCounter>
          <S.DisableRecordButton onClick={stopRecording} isPaused={isPaused}>
            <FaCircleStop size={28} />
          </S.DisableRecordButton>
          <S.Timer isRecording={isRecording} isPaused={isPaused}>
            {`${String(Math.floor(seconds / 60)).padStart(2, "0")}:${String(
              seconds % 60
            ).padStart(2, "0")}`}
          </S.Timer>
        </S.StateAndCounter>
      )}

      {isRecording ? (
        isPaused ? (
          <S.PauseAndResumeRecordButton onClick={resumeRecording}>
            <PlayArrow />
          </S.PauseAndResumeRecordButton>
        ) : (
          <S.PauseAndResumeRecordButton onClick={pauseRecording}>
            <Pause />
          </S.PauseAndResumeRecordButton>
        )
      ) : (
        <S.EnableRecordButton onClick={startRecording}>
          <Mic />
        </S.EnableRecordButton>
      )}
    </S.PlayerControls>
  );
};

export default AudioRecorder;
