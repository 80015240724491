import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { validateUserPasswordConfirmReset } from "validators.js";
import CustomInput from "../../components/CustomInput/CustomInput";
import style from "./styles.module.css";
import { useNotification } from "utils/hooks";
import handleError from "../../utils/handleError";

const PasswordResetConfirm = ({ onConfirm, token }) => {
  const [loading, setLoading] = useState(false);
  const { setNotificationSuccess, setNotificationError } = useNotification();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      password: "",
      passwordconfirm: ""
    },
    onSubmit: async ({ password, passwordconfirm }) => {
      try {
        setLoading(true);

        await validateUserPasswordConfirmReset({
          password,
          passwordconfirm,
          token
        });
        await onConfirm(password, token);

        setLoading(false);
        setNotificationSuccess("Informações atualizadas com sucesso!");
        setTimeout(() => {
          history.push("/auth/login");
        }, 1500);
      } catch (err) {
        setLoading(false);
        handleError(err, setNotificationError);
      }
    },
    enableReinitialize: true
  });

  return (
    <form onSubmit={formik.handleSubmit} className={style.form}>
      <CustomInput
        labelText="Nova Senha"
        id="password"
        name="password"
        color="primary"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: formik.handleChange,
          type: "password"
        }}
      />
      <CustomInput
        labelText="Confirmar Nova Senha"
        id="passwordconfirm"
        name="passwordconfirm"
        color="primary"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: formik.handleChange,
          type: "password"
        }}
      />

      <Button type="submit">
        {!loading ? "Redefinir" : <CircularProgress color="primary" />}
      </Button>
    </form>
  );
};

PasswordResetConfirm.propTypes = {
  onConfirm: PropTypes.func.isRequired
};
export default PasswordResetConfirm;
