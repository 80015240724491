import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  gap: 20px;

  display: flex;
  flex-wrap: wrap;

  align-items: center;
  justify-content: center;
`;

export const OptionButton = styled.button`
  box-sizing: border-box;

  padding-top: 20px;
  padding-bottom: 10px;

  display: flex;

  justify-content: center;
  align-items: center;

  flex-direction: column;

  cursor: pointer;

  width: 12rem;

  background: white;

  border: 0;
  border-radius: 10px;

  color: gray;

  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2);

  h4 {
    width: 90%;

    padding-top: 5px;

    border-top: 2px solid black;

    margin: 0;
    margin-top: 20px;
  }
  svg {
    color: #00acc1;

    transition: color 0.4s;
  }

  &:hover {
    svg {
      color: #3c4858;
    }
  }
`;
