import React from "react";
import * as S from "./styles";
import moment from "moment";
import DATES from "../../utils/bannerDates";

export default function CreditsBanner() {
  const NewDate = moment(new Date());
  const startDateLote1 = moment(DATES.START_DATE_LOTE1);
  const endDateLote1 = moment(DATES.END_DATE_LOTE1);
  const startDateLote2 = moment(DATES.START_DATE_LOTE2);
  const endDateLote2 = moment(DATES.END_DATE_LOTE2);
  let hotLink = "";

  const onBannerClick = () => {
    // TODO - change to the correct URL
    window.open(hotLink, "_blank");
  };

  if (NewDate.isBetween(startDateLote1, endDateLote1)) {
    hotLink =
      "https://pay.hotmart.com/X73056678F?checkoutMode=10&offDiscount=RENOVACAO&split=12&bid=1681930251669";
    return <S.Banner1 onClick={onBannerClick} />;
  } else if (NewDate.isBetween(startDateLote2, endDateLote2)) {
    console.log(NewDate.isBetween(startDateLote2, endDateLote2));
    hotLink =
      "https://pay.hotmart.com/X73056678F?checkoutMode=10&offDiscount=RENOVACAO2&split=12&bid=1681930265563";
    return <S.Banner2 onClick={onBannerClick} />;
  }

  return null;
}
