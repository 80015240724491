import React from "react";
import { createPortal } from "react-dom";

import { FaWindowMinimize } from "react-icons/fa";
import { FiInfo } from "react-icons/fi";

import * as S from "./styles";

export default function StudentInfoModal({ isOpen, onClose }) {
  return createPortal(
    <S.Container isOpen={isOpen}>
      <S.Background isOpen={isOpen} onClick={() => onClose()} />
      <S.Content isOpen={isOpen}>
        <S.Header>
          {isOpen ? (
            <>
              <h4>Aviso</h4> <FaWindowMinimize onClick={() => onClose()} />
            </>
          ) : (
            <FiInfo size={30} onClick={() => onClose()} />
          )}
        </S.Header>
        <S.TextArea isOpen={isOpen}>
          <p>Caros alunos,</p>
          <p>
            o acesso ao CONTEÚDO TEÓRICO do seu curso é feito por meio da
            plataforma Hotmart.
          </p>
          <p>
            A plataforma Minha Discursiva é EXCLUSIVA para envio e recebimento
            de correções de redação.
          </p>
          <p>
            Verifique no seu e-mail o título COMPRA APROVADA. É nesse e-mail que
            se encontra disponível o acesso ao seu curso teórico (videoaulas,
            PDFs etc).
          </p>
          <br />
          <br />
          <p>Atenciosamente,</p>
          <p>
            <strong>Prof. Ari</strong>
          </p>
        </S.TextArea>
      </S.Content>
    </S.Container>,
    document.body
  );
}
