import { useDispatch, useSelector } from "react-redux";
import * as authActions from "redux/actions/auth";
import * as authSelector from "redux/selectors/auth";
import { useFetchOnce } from "utils/hooks/useFetchMachine";

export function useLogout() {
  const dispatch = useDispatch();

  const onLogout = () => dispatch(authActions.onLogout());

  return {
    onLogout,
  };
}

export function useAuthenticatedUser() {
  const dispatch = useDispatch();

  const authenticatedUser = useSelector(authSelector.authenticatedUser);

  const onFetchSession = () => dispatch(authActions.onSession());

  useFetchOnce(onFetchSession, []);

  return {
    user: authenticatedUser,
  };
}
