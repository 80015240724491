import * as api from "lib/api";
import * as act from "./types";

export const onFetchTopics = () => async (dispatch) => {
  try {
    const response = await api.topics();
    return dispatch(act.topics(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onTopicNew = (
  name,
  number,
  supportText,
  type,
  commentedTopicPDF,
  essayModelPDF,
  tagsIds,
  commentText
) => async (dispatch) => {
  try {
    const response = await api.topicNew(
      name,
      number,
      supportText,
      type,
      commentedTopicPDF,
      essayModelPDF,
      tagsIds,
      commentText
    );
    return dispatch(act.topicNew(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onTopicUpdate = (
  topicid,
  name,
  number,
  supportText,
  type,
  commentedTopicPDF,
  essayModelPDF,
  tagsIds,
  commentText
) => async (dispatch) => {
  try {
    const response = await api.topicUpdate(
      topicid,
      name,
      number,
      supportText,
      type,
      commentedTopicPDF,
      essayModelPDF,
      tagsIds,
      commentText
    );
    return dispatch(act.topicUpdate(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onTopicDelete = (topicid) => async (dispatch) => {
  try {
    const response = await api.topicDelete(topicid);
    return dispatch(act.topicDelete(response.data));
  } catch (err) {
    throw err.response;
  }
};
