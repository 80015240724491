import React, { useMemo, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ModalConfirm from "components/Modal/ModalConfirm";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import Button from "components/CustomButtons/Button";
import DateFilter from "components/DatePicker";
import { JsonToCsvDownloader } from "components/CustomDownload";
import { useEssaysActions } from "./hooks";
import stylesTasks from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import useNotification from "utils/hooks/useNotification";
import {
  REVIEWS_STATUS_LABELS,
  REVIEWS_STATUS_LABELS_STUDENT,
  REVIEW_STATUS_AVAILABLE,
  REVIEW_STATUS_DONE,
  REVIEW_STATUS_INELIGIBLE,
  REVIEW_STATUS_PARTIAL_CORRECTION,
  REVIEW_STATUS_IN_PROGRESS,
  TEACHER_MODE,
  ADMIN_MODE,
  JURIDICAL_MODE,
  STUDENT_MODE,
  POLICIA_CIVIL_CEARA,
  PROJETO_CAVEIRA_PC_CEARA,
  POLICIA_CIVIL_AMAZONAS,
  POLICIA_CIVIL_BAHIA,
  TOPIC_TYPE,
  TOPIC_TYPE_GRAMMAR_LESSONS
} from "constants.js";
import { dateFormat, getIconButtonStyle, getStatusIcon } from "./helpers.js";
import style from "./styles.module.css";
import * as S from "./styles";
import { http } from "lib/api";
import { CircularProgress } from "@material-ui/core";
import { useEssaysContext } from "hooks/essays";
import CreditsBanner from "components/CreditsBanner";
import { CESPE_MAX_GRADE } from "../../constants";

const tableColumns = (userType) => {
  const userColumns = [
    { Header: "ID", accessor: "id" },
    { Header: "Aluno", accessor: "student" },

    {
      Header: "Tema",
      accessor: "topic"
    },
    { Header: "Envio", accessor: "date" },
    { Header: "Correção", accessor: "datereview" },
    { Header: "Corretor", accessor: "reviewer" },
    { Header: "Nota", accessor: "grades" },
    { Header: "Status", accessor: "status" },
    { Header: "Ações", accessor: "actions" }
  ];
  if (userType === ADMIN_MODE)
    userColumns.splice(2, 0, {
      Header: userType === ADMIN_MODE && "Email",
      accessor: "contest"
    });

  return userColumns;
};

const studentTableColumns = () => [
  { Header: "ID", accessor: "id" },
  { Header: "Aluno", accessor: "student" },
  {
    Header: "Tema",
    accessor: "topic"
  },
  { Header: "Envio", accessor: "date" },
  { Header: "Correção", accessor: "datereview" },
  { Header: "Nota", accessor: "grades" },
  { Header: "Status", accessor: "status" },
  { Header: "Ações", accessor: "actions" }
];

const sheetHeaders = [
  {
    label: "ID",
    key: "id"
  },
  {
    label: "Nome",
    key: "name"
  },
  {
    label: "E-mail",
    key: "email"
  },
  {
    label: "Data de Envio",
    key: "submissionDate"
  },
  {
    label: "Nota",
    key: "grade"
  },
  {
    label: "Link para Correção",
    key: "link"
  },
  {
    label: "Corretor",
    key: "reviewer"
  },
  {
    label: "Corretor Jurídico",
    key: "juridical_reviewer"
  },
  {
    label: "Data da correção",
    key: "review_date"
  }
];

const styles = {
  ...stylesTasks,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const AdminActions = ({
  onReview,
  status,
  essayId,
  currentUserId,
  reviewer
}) => {
  const isReviewer = currentUserId === (reviewer && reviewer._id);
  return status === REVIEW_STATUS_AVAILABLE ? (
    <Button
      size="sm"
      round
      style={{ backgroundColor: "#F49913" }}
      onClick={() => onReview(essayId)}>
      Corrigir
    </Button>
  ) : status === REVIEW_STATUS_DONE ? (
    <Link to={`/admin/redacoes/detalhes/${essayId}`}>
      <Button size="sm" round style={{ backgroundColor: "#4ABDD1" }}>
        Ver Correção
      </Button>
    </Link>
  ) : status === REVIEW_STATUS_INELIGIBLE ? (
    <Link to={`/admin/redacoes/detalhes/${essayId}`}>
      <Button size="sm" round>
        Ilegível
      </Button>
    </Link>
  ) : isReviewer ? (
    <Link to={`/admin/redacoes/corrigir/${essayId}`}>
      <Button size="sm" round color="primary">
        Continuar
      </Button>
    </Link>
  ) : null;
};

const TeacherActions = ({
  onReview,
  status,
  essayId,
  currentUserId,
  reviewer
}) => {
  const isReviewer = currentUserId === (reviewer && reviewer._id);
  return status === REVIEW_STATUS_AVAILABLE ? (
    <Button size="sm" round color="warning" onClick={() => onReview(essayId)}>
      Corrigir
    </Button>
  ) : status === REVIEW_STATUS_DONE && isReviewer ? (
    <Link to={`/professor/redacoes/detalhes/${essayId}`}>
      <Button size="sm" round color="info">
        Ver Correção
      </Button>
    </Link>
  ) : status === REVIEW_STATUS_INELIGIBLE && isReviewer ? (
    <Link to={`/professor/redacoes/detalhes/${essayId}`}>
      <Button size="sm" round>
        Ilegível
      </Button>
    </Link>
  ) : isReviewer && status === REVIEW_STATUS_IN_PROGRESS ? (
    <Link to={`/professor/redacoes/corrigir/${essayId}`}>
      <Button size="sm" round color="primary">
        Continuar
      </Button>
    </Link>
  ) : null;
};

const JuridicalActions = ({
  onReview,
  status,
  essayId,
  currentUserId,
  juridical_reviewer
}) => {
  const isReviewer = currentUserId === juridical_reviewer;

  return status === REVIEW_STATUS_PARTIAL_CORRECTION ? (
    <Button size="sm" round color="warning" onClick={() => onReview(essayId)}>
      Corrigir
    </Button>
  ) : status === REVIEW_STATUS_DONE && isReviewer ? (
    <Link to={`/professor/redacoes/detalhes/${essayId}`}>
      <Button size="sm" round color="info">
        Ver Correção
      </Button>
    </Link>
  ) : status === REVIEW_STATUS_INELIGIBLE && isReviewer ? (
    <Link to={`/professor/redacoes/detalhes/${essayId}`}>
      <Button size="sm" round>
        Ilegível
      </Button>
    </Link>
  ) : isReviewer ? (
    <Link to={`/professor/redacoes/corrigir/${essayId}`}>
      <Button size="sm" round color="primary">
        Continuar
      </Button>
    </Link>
  ) : null;
};

const StudentActions = ({ status, essayId, userid, currentUserId }) => {
  return status === REVIEW_STATUS_DONE && userid === currentUserId ? (
    <Link to={`/aluno/redacoes/detalhes/${essayId}`}>
      <Button size="sm" round color="info">
        Ver Correção
      </Button>
    </Link>
  ) : status === REVIEW_STATUS_INELIGIBLE && userid === currentUserId ? (
    <Link to={`/aluno/redacoes/detalhes/${essayId}`}>
      <Button size="sm" round>
        Ilegível
      </Button>
    </Link>
  ) : null;
};
// d.contest.name
const formatDataToTable = (classes, data, userType, onReview, currentUserId) =>
  data.map((d) => {
    let name_teacher_juridical = "",
      name_teacher = "";

    if (d.review?.reviewer?.name) name_teacher = `${d.review?.reviewer?.name}`;
    if (
      (d.contest?.name === POLICIA_CIVIL_CEARA ||
        d.contest?.name === PROJETO_CAVEIRA_PC_CEARA ||
        d.contest?.name === POLICIA_CIVIL_AMAZONAS ||
        d.contest?.name === POLICIA_CIVIL_BAHIA) &&
      d.review?.juridical_reviewer?.name
    )
      name_teacher_juridical = `,${d.review?.juridical_reviewer?.name}`;

    const teacherActionsEnabled = d.topic?.technical
      ? currentUserId === "60438cd1d54d45fd6ec9512d" || // eduardakelly.1@hotmail.com
        currentUserId === "60438ac8d54d453686c95112" // julianarss.advogada@gmail.com
        ? true
        : false
      : true;
    return {
      id: d.topic?.technical
        ? `${d.id} (técnica)`
        : (d.contest?.name === POLICIA_CIVIL_CEARA ||
            d.contest?.name === PROJETO_CAVEIRA_PC_CEARA ||
            d.contest?.name === POLICIA_CIVIL_AMAZONAS ||
            d.contest?.name === POLICIA_CIVIL_BAHIA) &&
          (userType === TEACHER_MODE || userType === ADMIN_MODE)
        ? `${d.id} (gramatical)`
        : d.id,
      student: d.user?.name,
      topic: (
        <S.TopicData>
          <strong>
            Tema {d.topic?.number}
            {d.topic?.type ? ` - ${TOPIC_TYPE[d.topic?.type]}` : ""}
          </strong>
          : {d.topic?.name}
        </S.TopicData>
      ),
      contest: userType === ADMIN_MODE && d.user?.email,
      date: dateFormat(new Date(d.createdAt)),

      datereview: d.review
        ? d.status === REVIEW_STATUS_DONE
          ? dateFormat(new Date(d.review.updatedAt))
          : dateFormat(new Date(d.review.createdAt))
        : "",
      ...(userType !== STUDENT_MODE && {
        reviewer: `${name_teacher}${name_teacher_juridical}`
      }),
      grades: d.status === REVIEW_STATUS_DONE &&
        d.review?.grades?.final !== undefined && (
          <div className={style.grades}>
            {d.review.grades.final > CESPE_MAX_GRADE ? (
              "Nota incorreta"
            ) : d?.topic.type !== TOPIC_TYPE_GRAMMAR_LESSONS ? (
              <b>
                {parseFloat(d.review.grades.final).toFixed(2)} /{" "}
                {CESPE_MAX_GRADE}{" "}
              </b>
            ) : null}
          </div>
        ),
      status: (
        <Tooltip
          title={
            userType === STUDENT_MODE
              ? REVIEWS_STATUS_LABELS_STUDENT[d.status]
              : REVIEWS_STATUS_LABELS[d.status]
          }
          aria-label="review">
          <IconButton style={getIconButtonStyle(d.status)} variant="outlined">
            {getStatusIcon(d.status)}
          </IconButton>
        </Tooltip>
      ),
      actions: (
        <TableCell className={classes.tableActions + " actions-right"}>
          {userType === ADMIN_MODE ? (
            <AdminActions
              onReview={onReview}
              status={d.status}
              essayId={d._id}
              currentUserId={currentUserId}
              reviewer={d.review && d.review.reviewer}
            />
          ) : userType === STUDENT_MODE ? (
            <StudentActions
              currentUserId={currentUserId}
              userid={d.user._id}
              status={d.status}
              essayId={d._id}
            />
          ) : userType === TEACHER_MODE ? (
            teacherActionsEnabled && (
              <TeacherActions
                onReview={onReview}
                status={d.status}
                essayId={d._id}
                currentUserId={currentUserId}
                reviewer={d.review && d.review.reviewer}
              />
            )
          ) : userType === JURIDICAL_MODE ? (
            teacherActionsEnabled && (
              <JuridicalActions
                onReview={onReview}
                status={d.status}
                essayId={d._id}
                currentUserId={currentUserId}
                reviewer={d.review && d.review.reviewer}
                juridical_reviewer={d.juridical_reviewerid}
              />
            )
          ) : null}
        </TableCell>
      )
    };
  });

function sortByStatus(essays) {
  return essays.sort((a, b) => {
    if (a.status < b.status) return -1;
    if (a.status > b.status) return 1;
    return 0;
  });
}
const EssaysList = ({ essays, headerText, headerColor }) => {
  const classes = useStyles();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [selectedEssay, setSelectedEssay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const { onStartEssayReview, user } = useEssaysActions();
  const { setNotificationError } = useNotification();
  const [dateFilter, setDateFilter] = useState([null, null]);

  const [filteredEssays, setFilteredEssays] = useState([]);
  const [essaysListToDownload, setEssaysListToDownload] = useState([]);

  const { setQueryFilters, setCurrentPage } = useEssaysContext();

  const handleDateFilter = (start, end) => {
    setDateFilter([start, end]);
    setLoadingDownload(true);
    if (start && end) {
      setQueryFilters((prev) => {
        return { ...prev, start, end };
      });
      let newEssays;
      http
        .get(`/essays/csv`, {
          params: {
            start,
            end
          }
        })
        .then((response) => {
          newEssays = response.data;
          setEssaysListToDownload(newEssays);
          setLoadingDownload(false);
          setCurrentPage(1);
        });
    } else {
      setLoadingDownload(false);
      setQueryFilters((prev) => {
        return { ...prev, start: undefined, end: undefined };
      });
    }
  };

  const handleReview = (key) => {
    setShow(true);
    setSelectedEssay(key);
  };
  const platform = useMemo(() => history.location.pathname.split("/")[1], [
    history.location.pathname
  ]);
  const handleTakeReview = async () => {
    try {
      setLoading(true);
      await onStartEssayReview(selectedEssay);
      setLoading(false);
      history.push(`/${platform}/redacoes/corrigir/${selectedEssay}`);
    } catch (err) {
      setNotificationError(err.data.ErrorMessage);
      setLoading(false);
      setShow(false);
      throw err;
    }
  };

  useEffect(() => {
    setFilteredEssays(sortByStatus(essays));
  }, [essays]);

  useEffect(() => {
    setQueryFilters({});
    setCurrentPage(1);
  }, []);

  return (
    <>
      <ModalConfirm
        show={show}
        onConfirm={handleTakeReview}
        body="Você tem certeza que quer corrigir essa redação?"
        onClose={() => setShow(false)}
        loading={loading}
      />
      <GridContainer>
        <GridItem xs={12}>
          <CreditsBanner />
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color={headerColor ? headerColor : "info"} icon>
              <CardIcon color={headerColor ? headerColor : "info"}>
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {headerText ? headerText : "Controle de Redações"}
              </h4>
            </CardHeader>
            <CardBody>
              {user.type === ADMIN_MODE && (
                <>
                  {loadingDownload ? (
                    <>
                      <CircularProgress size={20} color="primary" />{" "}
                      <span>Preparando arquivo...</span>
                    </>
                  ) : (
                    <JsonToCsvDownloader
                      data={essaysListToDownload}
                      headers={sheetHeaders}
                      filename={`Redacoes - ${dateFormat(Date.now())}`}
                    />
                  )}
                  <DateFilter value={dateFilter} setValue={handleDateFilter} />
                </>
              )}
              <ReactTable
                columns={
                  user.type === STUDENT_MODE
                    ? studentTableColumns()
                    : tableColumns(user.type)
                }
                userType={user.type}
                dateRange={dateFilter}
                data={formatDataToTable(
                  classes,
                  filteredEssays,
                  user.type,
                  handleReview,
                  user._id,
                  platform
                )}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default EssaysList;
