import * as api from "lib/api";
import * as act from "./types";

export const onFetchEssays = (essayid, reviewerid) => async (dispatch) => {
  try {
    const response = await api.essays(essayid, reviewerid);
    return dispatch(act.essays(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onFetchEssaysInfo = () => async (dispatch) => {
  try {
    const response = await api.essaysInfo();
    return dispatch(
      act.essaysInfo({ ...response.data, timestamp: Date.now() / 1000 })
    );
  } catch (err) {
    throw err.response;
  }
};

export const onFetchEssayById = (essayid) => async (dispatch) => {
  try {
    const response = await api.essay(essayid);
    return dispatch(act.essay(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onFetchEssaysByUserId = (userid) => async (dispatch) => {
  try {
    const response = await api.essaysByUserId(userid);
    return dispatch(
      act.essaysByUserId({
        userid,
        essays: response.data.essays
      })
    );
  } catch (err) {
    throw err.response;
  }
};

export const onSubmitEssay = (essay, userid, contestid, topic) => async (
  dispatch
) => {
  try {
    const response = await api.essayNew(essay, userid, contestid, topic);
    return dispatch(act.essayNew(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onFetchSimulationRanking = (contestid) => async (dispatch) => {
  try {
    const response = await api.essaysSimulations(contestid);
    dispatch(act.essaysSimulationRanking(response.data));
  } catch (error) {
    throw error;
  }
};
