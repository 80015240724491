import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
// TODO: use charts data formatter + api calls
import Icon from "@material-ui/core/Icon";
import ReviewStatsCard from "./ReviewStatsCard";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { essaysHistory } from "../../variables/charts";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SendIcon from "@material-ui/icons/Send";
import { useAdminStats } from "./hooks";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

import ReviewChartCard from "./ReviewChartCard";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles(styles);

const AdminDashboard = () => {
  const classes = useStyles();
  const { stats, dataChart, loading } = useAdminStats();

  return (
    <>
      {loading ? (
        <GridContainer justify="center" style={{ marginTop: "10%" }}>
          <CircularProgress size={24} />
        </GridContainer>
      ) : (
        <div>
          <h3>Visão Geral</h3>
          <br />
          <GridContainer>
            <ReviewStatsCard
              color="primary"
              deadlineStatus="Redações enviadas"
              amount={stats.essays || 0}
              itemWidth={3}
              icon={<SendIcon />}
            />
            <ReviewStatsCard
              color="success"
              deadlineStatus="Redações corrigidas"
              amount={stats.reviews || 0}
              itemWidth={3}
              icon={<Icon>content_copy</Icon>}
            />
            <ReviewStatsCard
              color="rose"
              deadlineStatus="Alunos"
              amount={stats.students || 0}
              itemWidth={3}
              icon={<Icon>person</Icon>}
            />
            <ReviewStatsCard
              color="info"
              deadlineStatus="Corretores"
              amount={stats.teachers || 0}
              itemWidth={3}
              icon={<Icon>book</Icon>}
            />
          </GridContainer>
          <br />
          <h3>Estatísticas</h3>
          <br />
          {stats.essays !== 0 ? (
            <GridContainer>
              <GridItem xs={false} sm={false} md={false} lg={3} />
              <GridItem xs={12} sm={12} md={12} lg={6}>
                <ReviewChartCard
                  classes={classes}
                  chart={{
                    ...essaysHistory,
                    data: dataChart,
                    options: {
                      height: "300px"
                    }
                  }}
                  color="none"
                  chartType="Pie"
                  title="Redações Enviadas/Corrigidas"
                  lastOccurence={false}
                  underText={
                    <div className={classes.cardFooter}>
                      <h6 className={classes.legendTitle}>Legenda</h6>
                      <div className={classes.text}>
                        <FiberManualRecordIcon className={classes.info} />
                        <p className={classes.p}>Redações Enviadas</p>
                        <FiberManualRecordIcon className={classes.danger} />
                        <p className={classes.p}>Redações Corrigidas</p>
                      </div>
                    </div>
                  }
                />
              </GridItem>
            </GridContainer>
          ) : (
            <GridContainer justify="center">
              <h5>
                As estatísticas estarão disponíveis quando a primeira redação
                for enviada.
              </h5>
            </GridContainer>
          )}
        </div>
      )}
    </>
  );
};

export default AdminDashboard;
