import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};
const useStyles = makeStyles(styles);

const CardTitle = ({ title }) => {
  const classes = useStyles();
  return (
    <h4 style={{ color: "#555" }} className={classes.cardIconTitle}>
      {title}
    </h4>
  );
};
export default CardTitle;
