import styled from "styled-components";

export const ConfirmButton = styled.button`
  cursor: pointer;

  border: 0;
  border-radius: 3px;

  background: #00acc1;

  color: white;

  padding: 11px;

  transition: background-color 0.4s;

  font-weight: bold;
  font-size: 15px;

  box-shadow: 0 6px 10px -10px rgba(0, 172, 193, 0.28),
    0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 172, 193, 0.2);

  :hover {
    background: #006b78;
  }

  span {
    color: white;
  }
`;

export const CancelButton = styled.button`
  cursor: pointer;

  border: 0;
  border-radius: 3px;

  background: #fa5546;

  color: white;

  padding: 11px;

  transition: background-color 0.4s;

  font-weight: bold;
  font-size: 15px;

  :hover {
    background: #ff4636;
  }

  span {
    color: white;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  padding: 1.5rem 0;
`;
