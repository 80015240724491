import { useDispatch, useSelector } from "react-redux";
import * as reviewsActions from "redux/actions/reviews";
import * as essaysActions from "redux/actions/essays";
import { useFetchOnce } from "utils/hooks/useFetchMachine";
import * as reviewsSelector from "redux/selectors/reviews";
import * as authSelector from "redux/selectors/auth";
import * as essaysSelector from "redux/selectors/essays";

export default function useEssay(essayId) {
  const dispatch = useDispatch();
  const reviews = useSelector(reviewsSelector.reviewsByEssayId(essayId));
  const essay = useSelector(essaysSelector.essayById(essayId));
  const user = useSelector(authSelector.authenticatedUser);

  const onReviewEssay = (review, comment, grades, user, audioBlob) =>
    dispatch(
      reviewsActions.onSaveEssayReview(
        review,
        essayId,
        comment,
        grades,
        user,
        audioBlob
      )
    );

  const onDeleteAudio = () => dispatch(reviewsActions.onDeleteAudio(essayId));

  const onSubmitReview = (review, comment, grades, status, user, audioBlob) =>
    dispatch(
      reviewsActions.onSubmitEssayReview(
        review,
        essayId,
        comment,
        grades,
        status,
        user,
        audioBlob
      )
    );

  const onFetchReviews = () =>
    dispatch(reviewsActions.onFetchReviewsByEssay(essayId));

  const onFetchEssay = () => dispatch(essaysActions.onFetchEssayById(essayId));

  const { loading, error } = useFetchOnce(onFetchReviews, []);
  const { loadingEssay, errorEssay } = useFetchOnce(onFetchEssay, []);

  return {
    essay,
    onSaveReview: onReviewEssay,
    onSubmitReview,
    onDeleteAudio,
    reviews: reviews ? reviews.reviews : [],
    comment: reviews ? reviews.comment : "",
    audioPath: reviews ? reviews.audioPath : null,
    loading: loading || loadingEssay,
    error: error || errorEssay,
    onFetchReviews,
    user
  };
}

export function useEssaySubmit() {
  const dispatch = useDispatch();

  const user = useSelector(authSelector.authenticatedUser);

  const onSubmitEssay = (essay, userid, contestid, topic) =>
    dispatch(essaysActions.onSubmitEssay(essay, userid, contestid, topic));

  return { onSubmitEssay, user };
}
