import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

import { FiRotateCcw, FiRotateCw } from "react-icons/fi";

import * as S from "./styles";

const EssayModal = ({
  body,
  onConfirm,
  onClose,
  title,
  show,
  loading,
  rotate
}) => (
  <Dialog
    open={show}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
    maxWidth="sm"
  >
    <DialogTitle
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      {title}
    </DialogTitle>
    <DialogContent
      container
      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <DialogContentText>{body}</DialogContentText>
    </DialogContent>
    <S.Wrapper
      style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 10 }}
    >
      <Button onClick={() => rotate(8)} color="primary">
        <FiRotateCcw />
      </Button>
      <Button onClick={() => rotate(6)} color="primary">
        <FiRotateCw />
      </Button>
      <S.CancelButton>
        <Button onClick={onClose} style={{ height: 30 }}>
          Cancelar
        </Button>
      </S.CancelButton>
      <S.ConfirmButton>
        <Button
          disabled={loading}
          onClick={!loading && onConfirm}
          color="primary"
          style={{ height: 30 }}>
          Confirmar
        </Button>
      </S.ConfirmButton>
    </S.Wrapper>
  </Dialog>
);

EssayModal.defaultProps = {
  body: "Tem certeza que deseja continuar?",
  title: "Atenção",
  loading: false
};

EssayModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  body: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string
};

export default EssayModal;
