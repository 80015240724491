import React, { useEffect, useState } from "react";
import EssaysList from "views/Essays/List";
import { useEssaysByUser } from "views/Essays/hooks";
import useAuthUser from "utils/hooks/useAuthUser";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer";
import { http } from "lib/api";
import { useSelector } from "react-redux";
import { useEssaysContext } from "hooks/essays";

export default function ReviewedEssaysPage() {
  const { user } = useAuthUser();
  const { loading } = useEssaysByUser(user._id);
  const userId = useSelector((state) => state.auth.session.user._id);

  const [filteredEssays, setFilteredEssays] = useState([]);
  const {
    currentPage,
    pageParams,
    setPageParams,
    queryFilters,
    setEssaysLoading,
    setQueryFilters,
    setCurrentPage
  } = useEssaysContext();

  async function getEssayList() {
    setEssaysLoading(true);
    const { data } = await http.get(`essay/user/${userId}`, {
      params: {
        currentPage,
        ...pageParams,
        ...queryFilters
      }
    });

    setFilteredEssays(data.essays);
    setPageParams({
      pageSize: pageParams.pageSize,
      totalPages: data.totalPages
    });
    setEssaysLoading(false);
  }

  useEffect(() => {
    setQueryFilters({});
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    getEssayList();
  }, [currentPage, pageParams.pageSize, queryFilters]);

  return !loading ? (
    <EssaysList essays={filteredEssays} />
  ) : (
    <GridContainer justify="center" style={{ marginTop: "10%" }}>
      <CircularProgress size={24} />
    </GridContainer>
  );
}
