import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as authActions from "redux/actions/auth";
import * as authSelector from "redux/selectors/auth";
import { useFetchOnce } from "utils/hooks/useFetchMachine";
import { useLocation } from "react-router-dom";
import { USER_MODE_LABELS } from "../constants";

export const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const authenticatedUser = useSelector(authSelector.authenticatedUser);

  const location = useLocation();

  const type = location.pathname.split("/")[1];
  const onFetchSession = () => dispatch(authActions.onSession());

  const { loading } = useFetchOnce(onFetchSession, []);
  const userType = authenticatedUser && authenticatedUser.type;

  const isType = USER_MODE_LABELS[userType] === type;

  return !loading ? (
    <AuthContext.Provider>
      {children({ type: isType ? userType : null })}
    </AuthContext.Provider>
  ) : (
    <div
      style={{
        textAlign: "center",
        marginTop: "10rem"
      }}>
      <CircularProgress />
    </div>
  );
};

export default AuthProvider;
