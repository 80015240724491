import React, { useCallback, useEffect } from "react";

import { FiBook, FiUpload } from "react-icons/fi";
import { FaFileVideo } from "react-icons/fa";
import { IoIosPaper } from "react-icons/io";

import { useMaterialsContext } from "hooks/materials";

import AddMaterial from "components/AddMaterial";

import * as S from "./styles";
import TopicTypeModal from "components/TopicTypeModal";
import EbookList from "components/EbookList";
import VideoList from "components/VideoList";
import VideoPage from "components/VideoPage";
import EditMaterial from "components/EditMaterial";

function Materials() {
  const {
    currentPage,
    setCurrentPage,
    selectedTopicType,
    setSelectedTopicType,
    materialToEdit
  } = useMaterialsContext();

  const loadCurrentPage = useCallback(() => {
    switch (currentPage) {
      case "add-material":
        return <AddMaterial />;
      case "edit-material":
        return <EditMaterial material={materialToEdit} />;
      case "show-ebooks":
        return <EbookList />;
      case "show-essays-paper":
        return <EbookList />;
      case "show-videos":
        return <VideoList />;
      case "video-player":
        return <VideoPage />;
      default:
        return (
          <S.Container>
            <S.OptionButton onClick={() => setCurrentPage("show-ebooks")}>
              <FiBook size={120} />
              <h4>Listar Ebooks</h4>
            </S.OptionButton>
            <S.OptionButton onClick={() => setCurrentPage("show-essays-paper")}>
              <IoIosPaper size={120} />
              <h4>Folhas de redação</h4>
            </S.OptionButton>
            <S.OptionButton onClick={() => setCurrentPage("show-videos")}>
              <FaFileVideo size={120} />
              <h4>Listar VideoAulas</h4>
            </S.OptionButton>

            <S.OptionButton onClick={() => setCurrentPage("add-material")}>
              <FiUpload size={120} />
              <h4>Cadastrar Material</h4>
            </S.OptionButton>
          </S.Container>
        );
    }
  }, [currentPage]);

  useEffect(() => {
    setSelectedTopicType(0);
    setCurrentPage("");
  }, []);

  return (
    <>
      <TopicTypeModal
        isEnabled={
          selectedTopicType === 0 &&
          ["show-ebooks", "show-videos"].includes(currentPage)
        }
      />
      {loadCurrentPage()}
    </>
  );
}

export default Materials;
