import React, { useState, useEffect } from "react";
import * as api from "lib/api";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import { essaysHistory, months, optionsEssaysHistory } from "variables/charts";
import ReviewChartCard from "./ReviewChartCard";
import ReviewStatsCard from "./ReviewStatsCard";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Muted from "components/Typography/Muted.js";
import Button from "components/CustomButtons/Button.js";
import UserEssaysList from "components/UserEssaysList/UserEssaysList";
import Icon from "@material-ui/core/Icon";
import ModalConfirm from "components/Modal/ModalConfirm";
import { useEssaysByAuthUser } from "./hooks";
import {
  getDataChartTeacher,
  getDataTableTeacher,
  getDaysCurrentMonth
} from "./helpers";
import style from "./style.module.css";

const useStyles = makeStyles(styles);

const TeacherDashboard = () => {
  const classes = useStyles();
  const essaysLabels = getDaysCurrentMonth();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const userId = useSelector((state) => state.auth.session.user._id);
  const [totalEssays, setTotalEssays] = useState({
    inProgressCount: 0,
    doneCount: 0
  });
  const [essay_start, setessay_start] = useState("");

  const reviewActions = (essayId) => (
    <div className={style.buttons}>
      <Button
        round
        color="primary"
        size="sm"
        onClick={() => {
          history.push(`/professor/redacoes/corrigir/${essayId}`);
        }}>
        Continuar
      </Button>
      {/* <Button size="sm" round color="danger">
        Abandonar
      </Button> */}
    </div>
  );

  const { essays, user } = useEssaysByAuthUser();
  const { dataTable } = getDataTableTeacher(essays, reviewActions, user);
  // debugger;
  let show_start_essay = "";

  if (dataTable.length > 0 && !essay_start) {
    let start_essay = dataTable[0][4];

    show_start_essay = `${new Date(start_essay).toLocaleDateString(
      "pt-br"
    )} ${new Date(start_essay).toLocaleTimeString("pt-br")}`;
    // date elapsed
    // let dayToday = new Date();
    // let dateStart = new Date(start_essay);
    // let dateElapsed = Math.floor(
    //   (dayToday - dateStart) / (60 * 60 * 24 * 1000)
    // );
    dataTable[0][4] = show_start_essay;

    setessay_start(show_start_essay);

    setOpenModal(true);
  }
  if (essay_start) {
    dataTable[0][4] = essay_start;
  }

  const handleConfirm = () => {
    setOpenModal(false);
  };

  const essaysData = getDataChartTeacher(essays, essaysLabels);

  const noDataText = (
    <div className={style.noDataText}>
      <div>
        <Muted>Nenhuma correção em andamento</Muted>
      </div>
      <div>
        <Link to="/professor/redacoes">Acessar página de redações</Link>
      </div>
    </div>
  );

  useEffect(() => {
    api.essaysByUserId(userId).then((res) =>
      setTotalEssays({
        inProgressCount: res.data.inProgressCount,
        doneCount: res.data.doneCount,
        rateCorrectionAvg: res.data.rateCorrectionAvg
      })
    );
  }, [userId]);

  return (
    <div>
      <h3>Atividade</h3>
      <br />
      <GridContainer>
        {openModal && (
          <ModalConfirm
            show={openModal}
            onConfirm={handleConfirm}
            body={`Olá. Você possui uma correção em andamento. O prazo para conclusão é de até 24h. Por favor, conclua o quanto antes. Início da Correção ${essay_start}`}
            onClose={() => setOpenModal(false)}
          />
        )}
        <ReviewStatsCard
          color="success"
          deadlineStatus="Redações corrigidas"
          amount={totalEssays.doneCount}
          icon={<Icon>check</Icon>}
        />
        <ReviewStatsCard
          color="warning"
          deadlineStatus="Correções em andamento"
          amount={totalEssays.inProgressCount}
          icon={<Icon>book</Icon>}
        />
        <ReviewStatsCard
          color="rose"
          deadlineStatus="Sua media de avaliação geral"
          rightText="/ 5.00"
          amount={totalEssays.rateCorrectionAvg}
          icon={<Icon>star</Icon>}
        />
        {/* Iremos colocar redações faltantes para a meta assim que
        tivermos na plataforma o controle mensal de cada professor */}
        {/* <ReviewStatsCard
          color="danger"
          deadlineStatus="Redações faltantes para a meta"
          amount={0}
          icon={<EqualizerIcon />}
        /> */}
      </GridContainer>
      <br />
      <h3>Desempenho no mês</h3>
      <br />
      <div className={style.chart}>
        <ReviewChartCard
          classes={classes}
          chart={{
            ...essaysHistory,
            data: {
              series: essaysData,
              labels: essaysLabels
            },
            options: {
              ...optionsEssaysHistory,
              high: 20
            }
          }}
          color="rose"
          title="Redações Corrigidas"
          lastOccurence={false}
          underText={`Mês de ${months[new Date().getMonth()]}`}
        />
      </div>
      <br />
      <h3>Correções em andamento</h3>
      <br />
      <div className={style.history}>
        <UserEssaysList
          data={dataTable}
          columns={["Título", "Tema", "Aluno", " ", "Início"]}
          noDataText={noDataText}
        />
      </div>
    </div>
  );
};

export default TeacherDashboard;
