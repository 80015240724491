import { useDispatch, useSelector } from "react-redux";
import * as essaysActions from "redux/actions/essays";
import * as reviewsActions from "redux/actions/reviews";
import { useFetchOnce } from "utils/hooks/useFetchMachine";
import * as essaysSelector from "redux/selectors/essays";
import * as authSelector from "redux/selectors/auth";

export function useEssays() {
  const dispatch = useDispatch();
  const user = useSelector(authSelector.authenticatedUser);
  const essays = useSelector(essaysSelector.essays);
  const essaysInfo = useSelector(essaysSelector.essaysInfo);
  const onFetchEssays = () => dispatch(essaysActions.onFetchEssays());
  const onFetchEssaysInfo = () => dispatch(essaysActions.onFetchEssaysInfo());
  const { loading: loadingInfo, error: errorInfo } = useFetchOnce(
    onFetchEssaysInfo,
    []
  );
  const isEssaysListComplete = essaysInfo.length === Object.keys(essays).length;
  const { loading, error } = useFetchOnce(
    onFetchEssays,
    [],
    !isEssaysListComplete
  );

  return {
    user,
    essays: essays && Object.values(essays),
    loading: loading || loadingInfo,
    error: error || errorInfo
  };
}

export function useEssaysActions() {
  const dispatch = useDispatch();
  const user = useSelector(authSelector.authenticatedUser);
  const onStartEssayReview = (id) =>
    dispatch(reviewsActions.onReviewEssay(id, user._id));

  return {
    user,
    onStartEssayReview
  };
}

export function useEssaysByUser(reviewerId) {
  const dispatch = useDispatch();
  const essays = useSelector(essaysSelector.essaysByUserId(reviewerId));
  const onFetchEssaysByUserId = () =>
    dispatch(essaysActions.onFetchEssaysByUserId(reviewerId));
  const { loading, error } = useFetchOnce(onFetchEssaysByUserId, []);

  return {
    essays,
    loading,
    error
  };
}

export function useEssaysSimulation(simulationid) {
  const dispatch = useDispatch();
  const user = useSelector(authSelector.authenticatedUser);
  const ranking = useSelector(
    essaysSelector.essaysSimulationRanking(simulationid)
  );

  const onFetchSimulationRanking = (simulationid) =>
    dispatch(essaysActions.onFetchSimulationRanking(simulationid));

  const { loading, error } = useFetchOnce(onFetchSimulationRanking, [
    simulationid
  ]);
  return {
    loading,
    error,
    user,
    ranking
  };
}
