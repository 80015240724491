import React, { useCallback, useState, useEffect } from "react";
import TopicsIcon from "@material-ui/icons/Spellcheck";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "components/Card";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Accordion from "components/Accordion/Accordion.js";
import TextEditor from "components/TextEditor";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { useTopics } from "../Admin/hooks";
import { FiDownload, FiUpload } from "react-icons/fi";
import { useEssaySubmit } from "views/Essay/hooks";
import useNotification from "utils/hooks/useNotification";
import { TOPIC_TYPE } from "constants.js";
import TopicTypeModal from "components/TopicTypeModal";
import { useHistory } from "react-router";
import { TOPIC_TYPE_GRAMMAR_LESSONS } from "../../constants";

import * as S from "./styles";
import { useMaterialsContext } from "hooks/materials";
import EssayModal from "components/EssayModal";
import hasPermission from "utils/hasPermissions";
import { useSelector } from "react-redux";
import CreditsBanner from "components/CreditsBanner";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const Topics = () => {
  const classes = useStyles();
  const { loading, topics } = useTopics();
  const { permissions } = useSelector(({ auth }) => auth.session.user);

  const {
    setSelectedTopicType,
    selectedTopicType,
    setCurrentPage,
    currentPage
  } = useMaterialsContext();

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState("");

  const {
    setNotificationSuccess,
    setNotificationError,
    setNotificationWarning
  } = useNotification();
  const { onSubmitEssay, user } = useEssaySubmit();

  const [essayFile, setEssayFile] = useState(null);
  const [readyToUpload, setReadyToUpload] = useState(false);

  const [imageUrl, setImageUrl] = useState("");

  const [openEssayModal, setOpenEssayModal] = useState(false);

  function resetOrientation(srcBase64, srcOrientation, callback) {
    const img = new Image();

    img.onload = function () {
      var width = img.width,
        height = img.height,
        canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      callback(canvas.toDataURL("image/jpeg", 1.0));
    };
    img.src = srcBase64;
  }

  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);

    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const history = useHistory();
  const submitEssay = useCallback(async () => {
    try {
      setLoadingUpload(true);

      await onSubmitEssay(essayFile, user._id, "", selectedTopic);

      setLoadingUpload(false);

      setNotificationSuccess("Redação enviada com sucesso");
      setTimeout(() => {
        setNotificationWarning(
          "Sua correção estará pronta em até 04 (quatro) dias úteis, o que não inclui finais de semanas e feriados."
        );
      }, 1000);

      if (selectedTopicType == TOPIC_TYPE_GRAMMAR_LESSONS) {
        history.push("/aluno/redacoes");
      }
    } catch (err) {
      setLoadingUpload(false);
      const message = err.data.ErrorMessage;
      setNotificationError(message);
      throw err;
    }
  }, [user._id, selectedTopic, essayFile]);

  useEffect(() => {
    if (essayFile) {
      setOpenEssayModal(true);
    }
  }, [essayFile]);

  useEffect(() => {
    if (readyToUpload) {
      submitEssay();

      setOpenEssayModal(false);
      setReadyToUpload(false);

      setEssayFile(null);
    }
  }, [readyToUpload]);

  useEffect(() => {
    if (currentPage !== "topics-redirect") {
      setCurrentPage("topics");
      setSelectedTopicType(0);
    } else if (currentPage === "topics-redirect") {
      setSelectedTopicType(0);
    }

    if (document.getElementById("send-essay-button"))
      document.getElementById("send-essay-button").style.visibility = "hidden";

    return () => {
      if (document.getElementById("send-essay-button"))
        document.getElementById("send-essay-button").style.visibility =
          "visible";
    };
  }, []);

  return selectedTopicType === 0 ? (
    <TopicTypeModal isEnabled={selectedTopicType === 0} />
  ) : !loading && topics ? (
    <>
      {!readyToUpload ? (
        <EssayModal
          show={openEssayModal}
          onConfirm={() => setReadyToUpload(true)}
          rotate={(direction) =>
            resetOrientation(imageUrl, direction, (newBlob) => {
              setImageUrl(newBlob);
              setEssayFile(dataURItoBlob(newBlob));
            })
          }
          body={
            <>
              <p>Essa é a redação que deseja enviar?</p>
              <img
                src={imageUrl}
                style={{
                  maxHeight: "75vh",
                  maxWidth: "50vh",
                  objectFit: "contain"
                }}
              />
            </>
          }
          onClose={() => setOpenEssayModal(false)}
        />
      ) : null}
      {
        <GridContainer>
          <GridItem xs={12} md={12}>
            <CreditsBanner />
          </GridItem>
          <GridItem xs={12} md={12}>
            <Card>
              {selectedTopicType == TOPIC_TYPE_GRAMMAR_LESSONS ? (
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <TopicsIcon />
                  </CardIcon>
                  <S.TopicContainer>
                    <S.TitleCorrectionContainer>
                      <h4 className={classes.cardIconTitle}>
                        Correção Gramatical
                      </h4>
                    </S.TitleCorrectionContainer>

                    <S.ButtonsContainer>
                      {user.type === "student" && (
                        <S.UploadButton
                          onClick={() => {
                            document
                              .getElementById(`essay-topic-upload-gramatical`)
                              .click();
                          }}>
                          <FiUpload
                            style={{ height: 18, width: 18, marginTop: 1 }}
                          />{" "}
                          <input
                            id={`essay-topic-upload-gramatical`}
                            type="file"
                            onChange={(e) => {
                              let data = e.target.files[0];

                              setEssayFile(data);

                              let blob = new Blob([data], {
                                type: data?.mimeType
                              });

                              let url = window.URL.createObjectURL(blob);
                              setImageUrl(url);
                              setSelectedTopic(
                                topics.find(
                                  (topic) => topic?.type === selectedTopicType
                                )._id
                              );

                              e.target.value = "";
                            }}
                            accept="image/png, image/jpeg"
                          />
                          Envie sua redação
                        </S.UploadButton>
                      )}
                      {loadingUpload && (
                        <CircularProgress size={20} color="primary" />
                      )}
                    </S.ButtonsContainer>
                  </S.TopicContainer>
                </CardHeader>
              ) : (
                <CardHeader color="primary" icon>
                  <CardIcon color="primary">
                    <TopicsIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>
                    Temas e Textos Motivacionais
                  </h4>

                  <CardBody>
                    <br />
                    <Accordion
                      collapses={topics
                        .filter((topic) => topic?.type === selectedTopicType)
                        .map((topic, index) => ({
                          title: `Tema ${topic.number} ${
                            topic?.type ? ` - ${TOPIC_TYPE[topic?.type]}` : ""
                          }: ${topic.name}`,
                          content: (
                            <S.AccordionContainer>
                              <div styles={{ margin: "500px" }}>
                                <TextEditor
                                  initialText={topic.supportText}
                                  style={{ border: "none" }}
                                  readOnly={true}
                                />
                                {topic?.tags?.length > 0 && (
                                  <S.TopicsTagsContainer>
                                    <span>Tags: </span>
                                    {topic?.tags?.map((tag) => (
                                      <div key={tag?.description}>
                                        {tag.name}
                                      </div>
                                    ))}
                                  </S.TopicsTagsContainer>
                                )}

                                <S.ButtonContainer>
                                  {user.type === "student" && (
                                    <S.UploadButton
                                      onClick={() => {
                                        document
                                          .getElementById(
                                            `essay-topic-upload-${index}`
                                          )
                                          .click();
                                      }}>
                                      <FiUpload />{" "}
                                      <input
                                        id={`essay-topic-upload-${index}`}
                                        type="file"
                                        onChange={(e) => {
                                          let data = e.target.files[0];

                                          setEssayFile(data);

                                          let blob = new Blob([data], {
                                            type: data?.mimeType
                                          });

                                          let url = window.URL.createObjectURL(
                                            blob
                                          );
                                          setImageUrl(url);
                                          setSelectedTopic(topic._id);

                                          e.target.value = "";
                                        }}
                                        accept="image/png, image/jpeg"
                                      />{" "}
                                      Envie sua redação
                                    </S.UploadButton>
                                  )}

                                  {loadingUpload && (
                                    <CircularProgress
                                      size={20}
                                      color="primary"
                                    />
                                  )}
                                  {hasPermission(
                                    permissions,
                                    "Material",
                                    "read"
                                  ) && (
                                    <>
                                      <S.DownloadAnchor
                                        href={topic.essayModelPath}
                                        download="modelo_redacao.pdf"
                                        isEnabled={!!topic.essayModelPath}
                                        target="_blank">
                                        <FiDownload /> Modelo de redação
                                      </S.DownloadAnchor>
                                      <S.DownloadAnchor
                                        href={topic.commentedTopicPath}
                                        download="tema_comentado.pdf"
                                        isEnabled={!!topic.commentedTopicPath}
                                        target="_blank">
                                        <FiDownload />
                                        Tema Comentado
                                      </S.DownloadAnchor>
                                    </>
                                  )}
                                </S.ButtonContainer>
                              </div>
                            </S.AccordionContainer>
                          )
                        }))}
                    />
                  </CardBody>
                </CardHeader>
              )}
            </Card>
          </GridItem>
        </GridContainer>
      }
    </>
  ) : (
    <GridContainer justify="center" style={{ marginTop: "10%" }}>
      <CircularProgress size={24} />
    </GridContainer>
  );
};

export default Topics;
