import styled from "styled-components";
import background from "../../assets/img/login/background.png";

const commonStyles = `
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${background});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const SocialMediaContainer = styled.div`
  ${commonStyles}
  flex-direction: row;
  width: 15rem;
  margin-left: auto;
  padding-top: 2rem;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const SocialMediaIcons = styled.div`
  ${commonStyles}
  flex-direction: row;
  display: flex;
  padding-left: 4.5rem;
  gap: 0.5rem;
`;

export const SocialMediaText = styled.div`
  ${commonStyles}
  text-align: center;
  size: 5rem;
  color: #FFFFFF;

  @media (min-width: 1280px) {
    padding-left: 4.2rem;
  }

  @media (min-width: 935px) and (max-width: 1280px) {
    padding-left: 4.2rem;
  }

  @media (max-width: 935px) {
    padding-left: 4.2rem;
  }
`;

export const MainContent = styled.div`
  ${commonStyles}
  display: flex;
  flex-direction: column;

  @media (min-width: 680px) {
    flex-direction: row;
  }
`;

export const FormContainer = styled.form`
  ${commonStyles}
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  @media (min-width: 1280px) {
    padding-top: 7rem;
    z-index: 1;
  }

  @media (min-width: 935px) and (max-width: 1280px) {
    align-items: center;
    padding-top: 5rem;
  }

  @media (max-width: 935px) {
    flex-direction: column;
    padding-top: 5rem;
    gap: 1rem;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    padding-top: 10rem;
    gap: 1rem;
  }
`;

export const LogosContainer = styled.div`
  ${commonStyles}
  flex-direction: row;
  display: flex;
  gap: 0.5rem;

  @media (min-width: 1280px) {
    padding-right: 20rem;
  }

  @media (min-width: 935px) and (max-width: 1280px) {
    padding-right: 0;
  }

  @media (max-width: 935px) {
    padding-right: 0;
  }

  @media (max-width: 480px) {
    padding-right: 0;
  }
`;

export const Logo = styled.img`
  height: 9rem;
  width: 20rem;

  @media (min-width: 1280px) and (max-width: 1440px) {
    height: 8rem;
    width: 15rem;
  }

  @media (min-width: 700px) and (max-width: 1280px) {
    height: 8rem;
    width: 15rem;
  }

  @media (min-width: 481px) and (max-width: 700px) {
    height: 6.5rem;
    width: 13rem;
  }

  @media (max-width: 480px) {
    height: 5rem;
    width: 11rem;
  }
`;

export const InputsContainer = styled.div`
  ${commonStyles}
  display: flex;
  flex-direction: column;
  padding-top: 2.5rem;
  gap: 0.8rem;
  z-index: 1;

  @media (min-width: 1280px) {
    display: flex;
    flex-direction: column;
    padding-right: 20rem;
    z-index: 1;
  }

  @media (min-width: 935px) and (max-width: 1280px) {
    flex-direction: column;
    padding-top: 1.5rem;
    padding-right: 0;
    gap: 0.2rem;
  }

  @media (max-width: 935px) {
    flex-direction: column;
    padding-top: 1.5rem;
    padding-right: 0;
    gap: 0.2rem;
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
    gap: 0.1rem;
  }
`;

export const ButtonContainer = styled.div`
  ${commonStyles}
  flex-direction: column;
  display: flex;
  padding-top: 2rem;
  gap: 0.5rem;
  z-index: 1;

  @media (min-width: 1280px)  {
    display: flex;
    flex-direction: column;
    margin-right: 20rem;
    z-index: 1;
  }

  @media (min-width: 935px) and (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    gap: 0.5rem;
  }

  @media (max-width: 935px) {
    display: flex;
    flex-direction: column;
    padding-right: 0;
    gap: 0.5rem;
  }
`;

export const Button = styled.button`
  font-size: 1.1rem;
  height: 3rem;
  width: 15rem;
  color: #FFFFFF;
  background-color: #FF8000;
  border: 0.15rem solid #FF8000;
  border-radius: 1rem;
  cursor: pointer;

  @media (min-width: 700px) and (max-width: 935px) {
    width: 13.5rem;
    margin-right: 0;
  }

  @media (min-width: 481px) and (max-width: 700px) {
    width: 13rem;
  }

  @media (max-width: 480px) {
    width: 12.5rem;
  }
`;

export const ImageContainer = styled.div`
  ${commonStyles}
  flex-direction: row;
  display: flex;
  gap: 0.5rem;
`;

export const AriContainer = styled.div`
  ${commonStyles}

  @media (min-width: 1280px) {
    display: flex;
    padding-top: 15rem;
    padding-right: 30rem;
  }

  @media (max-width: 1280px) {
    display: none;
  }
`;
