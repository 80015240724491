import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const Img = styled.img`
  height: 45rem;
  width: 55rem;
  position: absolute;
`;
