import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Muted from "components/Typography/Muted.js";
import useNotification from "utils/hooks/useNotification";
import ModalConfirm from "components/Modal/ModalConfirm";
import EditTopicModal from "views/Admin/Modals/EditTopicModal";
import localStyles from "./styles.module.css";
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import { TOPIC_TYPE } from "constants.js";

const useStyles = makeStyles(styles);

const TableTopics = ({ topics, onTopicUpdate, onTopicDelete, tags }) => {
  const classes = useStyles();

  const { setNotificationSuccess, setNotificationError } = useNotification();

  const [manageTopic, setManageTopic] = useState(null);

  const [loading, setLoading] = useState(false);

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteSubmit = async () => {
    try {
      setLoading(true);
      await onTopicDelete(manageTopic._id);
      setLoading(false);
      setOpenDeleteModal(false);
      setManageTopic(null);
      setNotificationSuccess("Tema deletada com sucesso");
    } catch (err) {
      setOpenDeleteModal(false);
      setManageTopic(null);
      setLoading(false);
      setNotificationError(err.data.ErrorMessage);
      throw err;
    }
  };

  return (
    <>
      <EditTopicModal
        open={openUpdateModal}
        handleClose={() => setOpenUpdateModal(false)}
        topic={manageTopic}
        onTopicUpdate={onTopicUpdate}
        tags={tags}
      />
      <ModalConfirm
        show={openDeleteModal}
        onConfirm={handleDeleteSubmit}
        body="Você tem certeza que quer deletar esse tema?"
        onClose={() => setOpenDeleteModal(false)}
      />

      <Table className={classes.table}>
        <TableBody>
          {topics.length !== 0 ? (
            topics.map((topic) => {
              return (
                <TableRow key={topic._id} className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    <span className={classes.topicNameStyle}>
                      Tema {topic.number}
                      {topic?.type ? ` - ${TOPIC_TYPE[topic?.type]}` : ""}
                    </span>
                    : {topic.name}
                  </TableCell>

                  <TableCell className={classes.tableActions}>
                    {loading ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <>
                        <Tooltip
                          id="tooltip-top"
                          title="Editar"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}>
                          <IconButton
                            aria-label="Edit"
                            className={classes.tableActionButton}
                            onClick={() => {
                              setManageTopic(topic);
                              setOpenUpdateModal(true);
                            }}>
                            <Edit
                              className={
                                classes.tableActionButtonIcon +
                                " " +
                                classes.edit
                              }
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          id="tooltip-top-start"
                          title="Deletar"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}>
                          <IconButton
                            aria-label="Close"
                            className={classes.tableActionButton}
                            onClick={() => {
                              setManageTopic(topic);
                              setOpenDeleteModal(true);
                            }}>
                            <Close
                              className={
                                classes.tableActionButtonIcon +
                                " " +
                                classes.close
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell>
                <div className={localStyles.noThemeMsg}>
                  <Muted>
                    Cadastre os temas de redação e seus textos motivacionais
                  </Muted>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default TableTopics;
