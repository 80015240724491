import { createReducer } from "@reduxjs/toolkit";
import set from "lodash/fp/set";
import update from "lodash/fp/update";
import * as act from "../actions/types";

const DEFAULT_STATE = {
  byId: {}
};

const topics = createReducer(DEFAULT_STATE, {
  [act.topics.type]: (state, action) => {
    const topicsById = action.payload.reduce(
      (acc, curr) =>
        !curr.inactive
          ? {
              ...acc,
              [curr._id]: curr
            }
          : acc,
      {}
    );
    return set("byId", topicsById)(state);
  },
  [act.topicNew.type]: (state, action) =>
    set(["byId", action.payload._id], action.payload)(state),
  [act.topicUpdate.type]: (state, action) => {
    const { _id } = action.payload;
    return update(["byId", _id], (topic) => ({
      ...topic,
      ...action.payload
    }))(state);
  },
  [act.topicDelete.type]: (state, action) => {
    const { _id } = action.payload;
    delete state.byId[_id];
    return state;
  }
});

export default topics;
