import * as api from "lib/api";
import * as act from "./types";

export const onFetchContests = () => async (dispatch) => {
  try {
    const response = await api.contests();
    return dispatch(act.contests(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onContestNew = (name, institution, topics, date) => async (
  dispatch
) => {
  try {
    const response = await api.contestNew(name, institution, topics, date);
    return dispatch(act.contestNew(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onContestUpdate = (
  contestid,
  name,
  institutionid,
  topics,
  date
) => async (dispatch) => {
  try {
    const response = await api.contestUpdate(
      contestid,
      name,
      institutionid,
      topics,
      date
    );
    return dispatch(act.contestUpdate(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onContestDelete = (contestid) => async (dispatch) => {
  try {
    const response = await api.contestDelete(contestid);
    return dispatch(act.contestDelete(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onFetchContestsSimulations = () => async (dispatch) => {
  try {
    const response = await api.contestsSimulations();
    return dispatch(act.contests(response.data));
  } catch (err) {
    throw err.response;
  }
};
