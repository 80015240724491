import * as Yup from "yup";

const registerSchema = Yup.object().shape({
  name: Yup.string().required("Nome obrigatório"),
  email: Yup.string()
    .required("E-mail obrigatório.")
    .email("Digite um e-mail válido."),
  password: Yup.string().min(6, "A senha deve ter no mínimo 6 dígitos."),
  passwordconfirm: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "As senhas precisam ser iguais."
  )
});

export function validateRegister(registerProps) {
  return registerSchema.validate(registerProps, {
    abortEarly: true
  });
}

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .required("E-mail obrigatório.")
    .email("Digite um e-mail válido."),
  password: Yup.string().required("Senha obrigatória.")
});

export function validateLogin(loginProps) {
  return loginSchema.validate(loginProps, {
    abortEarly: true
  });
}

export function validateUserUpdate(userProps) {
  return registerSchema.validate(userProps, {
    abortEarly: false
  });
}

const passwordSchema = Yup.object().shape({
  password: Yup.string().min(6, "A senha deve ter no mínimo 6 dígitos."),
  newpassword: Yup.string().min(6, "A senha deve ter no mínimo 6 dígitos."),
  newpasswordconfirm: Yup.string().oneOf(
    [Yup.ref("newpassword"), null],
    "A senha de confirmação deve ser igual à nova senha."
  )
});

export function validateUserPasswordChange(userProps) {
  return passwordSchema.validate(userProps, {
    abortEarly: false
  });
}

export function validateUserPasswordConfirmReset(userProps) {
  return Yup.object()
    .shape({
      password: Yup.string().min(6, "A senha deve ter no mínimo 6 dígitos."),
      passwordconfirm: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "A senha de confirmação deve ser igual à nova senha."
      )
    })
    .validate(userProps, {
      abortEarly: false
    });
}

export function validateUserEmail(userProps) {
  return Yup.object()
    .shape({
      email: Yup.string()
        .required("E-mail obrigatório.")
        .email("Digite um e-mail válido.")
    })
    .validate(userProps, {
      abortEarly: false
    });
}
