import React from "react";
import { createPortal } from "react-dom";
import { FaWindowMinimize } from "react-icons/fa";
import moment from "moment";
import DATES from "../../utils/bannerDates";

import * as S from "./styles";

export default function CreditsModalBanner({ isOpen, onClose }) {
  const NewDate = moment(new Date());
  const startDateLote1 = moment(DATES.START_DATE_LOTE1);
  const endDateLote1 = moment(DATES.END_DATE_LOTE1);
  const startDateLote2 = moment(DATES.START_DATE_LOTE2);
  const endDateLote2 = moment(DATES.END_DATE_LOTE2);

  let modalImage = "";
  let modalLink = "";

  if (NewDate.isBetween(startDateLote1, endDateLote1)) {
    modalImage =
      "https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote1/POP UP.png";
    modalLink =
      "https://pay.hotmart.com/X73056678F?checkoutMode=10&offDiscount=RENOVACAO&split=12&bid=1681930251669";
  } else if (NewDate.isBetween(startDateLote2, endDateLote2)) {
    modalImage =
      "https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote2/pop up LOTE 2.png";
    modalLink =
      "https://pay.hotmart.com/X73056678F?checkoutMode=10&offDiscount=RENOVACAO2&split=12&bid=1681930265563";
  } else {
    return null;
  }

  return createPortal(
    <S.Container isOpen={isOpen}>
      <S.Background isOpen={isOpen} onClick={() => onClose()} />
      <S.Content isOpen={isOpen}>
        <S.Header>
          <FaWindowMinimize onClick={() => onClose()} />
        </S.Header>
        <a target="_blank" href={modalLink}>
          <img src={modalImage} />
        </a>
      </S.Content>
    </S.Container>,
    document.body
  );
}
