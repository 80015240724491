import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router";

import { FiBook } from "react-icons/fi";
import { FaFileVideo } from "react-icons/fa";
import { IoIosPaper } from "react-icons/io";
import {
  BsFillBookmarksFill,
  BsReverseLayoutTextSidebarReverse
} from "react-icons/bs";

import { useMaterialsContext } from "hooks/materials";

import * as S from "./styles";
import TopicTypeModal from "components/TopicTypeModal";
import EbookList from "components/EbookList";
import VideoList from "components/VideoList";
import VideoPage from "components/VideoPage";
import { useSelector } from "react-redux";
import hasPermission from "utils/hasPermissions";
import CreditsBanner from "components/CreditsBanner";

function Materials() {
  const {
    currentPage,
    setCurrentPage,
    selectedTopicType,
    setSelectedTopicType
  } = useMaterialsContext();
  const history = useHistory();

  const { permissions } = useSelector(({ auth }) => auth.session.user);

  const loadCurrentPage = useCallback(() => {
    switch (currentPage) {
      case "show-ebooks":
        return <EbookList />;
      case "show-videos":
        return <VideoList />;
      case "video-player":
        return <VideoPage />;
      case "show-essays-paper":
        return <EbookList />;
      default:
        return (
          <S.Container>
            <CreditsBanner />
            <S.OptionButton onClick={() => setCurrentPage("show-ebooks")}>
              <FiBook size={120} />
              <h4>Listar Ebooks</h4>
            </S.OptionButton>
            <S.OptionButton onClick={() => setCurrentPage("show-essays-paper")}>
              <IoIosPaper size={120} />
              <h4>Folhas de redação</h4>
            </S.OptionButton>
            <S.OptionButton onClick={() => setCurrentPage("show-videos")}>
              <FaFileVideo size={120} />
              <h4>Listar VideoAulas</h4>
            </S.OptionButton>
            <S.OptionButton
              onClick={() => {
                history.push("/aluno/temas", { returnTo: "/aluno/materiais" });
              }}>
              <BsFillBookmarksFill size={120} />
              <h4>Temas Comentados</h4>
            </S.OptionButton>
            <S.OptionButton
              onClick={() => {
                history.push("/aluno/temas", { returnTo: "/aluno/materiais" });
              }}>
              <BsReverseLayoutTextSidebarReverse size={120} />
              <h4>Modelos de redação</h4>
            </S.OptionButton>
          </S.Container>
        );
    }
  }, [currentPage]);

  useEffect(() => {
    if (!hasPermission(permissions, "Material", "read"))
      history.push("/aluno/dashboard");

    setSelectedTopicType(0);
    setCurrentPage("");
  }, []);

  return (
    <>
      <TopicTypeModal
        isEnabled={
          selectedTopicType === 0 &&
          ["show-ebooks", "show-videos"].includes(currentPage)
        }
      />
      {loadCurrentPage()}
    </>
  );
}

export default Materials;
