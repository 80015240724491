import styled from "styled-components";

export const Container = styled.button`
  cursor: pointer;

  width: 200px;
  height: 50px;

  gap: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  bottom: 20px;
  right: 50px;

  background-color: #00acc1;

  color: white;

  font-weight: bold;
  font-size: 15px;

  border-radius: 10px;
  border: 0;

  box-shadow: 0px 10px 10px #044e5955;

  transition: ease 0.4s;

  z-index: 10;
  :hover {
    transform: translateY(-5px);
  }

  @media screen and (max-width: 960px) {
    width: 50px;

    bottom: 10px;
    right: 10px;

    span {
      display: none;
    }
  }
`;
