import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const ModalConfirm = ({ body, onConfirm, onClose, title, show, loading }) => (
  <Dialog
    open={show}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    <DialogTitle>{title}</DialogTitle>
    <DialogContent dividers>
      <DialogContentText>{body}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <Button onClick={!loading && onConfirm} color="primary">
        {!loading ? "Confirmar" : <CircularProgress color="secondary" />}
      </Button>
    </DialogActions>
  </Dialog>
);

ModalConfirm.defaultProps = {
  body: "Tem certeza que deseja continuar?",
  title: "Atenção",
  loading: false
};

ModalConfirm.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  body: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.string
};

export default ModalConfirm;
