import React, { createContext, useContext, useState } from "react";

export const EssaysContext = createContext({});

export const EssaysProvider = ({ children }) => {
  const [essaysLoading, setEssaysLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [essaysToDownload, setEssaysToDownload] = useState([]);

  const [queryFilters, setQueryFilters] = useState({
    id: "",
    name: "",
    contestName: "",
    createdAt: "",
    updateAt: "",
    reviewer: "",
    grade: ""
  });
  const [pageParams, setPageParams] = useState({
    totalPages: 1,
    pageSize: 10
  });

  return (
    <EssaysContext.Provider
      value={{
        essaysLoading,
        setEssaysLoading,
        currentPage,
        setCurrentPage,
        queryFilters,
        setQueryFilters,
        pageParams,
        setPageParams,
        essaysToDownload,
        setEssaysToDownload
      }}>
      {children}
    </EssaysContext.Provider>
  );
};

export function useEssaysContext() {
  const context = useContext(EssaysContext);

  if (!context) {
    throw new Error("useAuth must be used within an EssaysProvider");
  }
  return context;
}
