import { createReducer } from "@reduxjs/toolkit";
import set from "lodash/fp/set";
import update from "lodash/fp/update";
import * as act from "../actions/types";

const DEFAULT_STATE = {
  byId: {}
};

const institutions = createReducer(DEFAULT_STATE, {
  [act.institutions.type]: (state, action) => {
    const institutionsById = action.payload.reduce(
      (acc, curr) => ({
        ...acc,
        [curr._id]: curr
      }),
      {}
    );
    return set("byId", institutionsById)(state);
  },
  [act.institutionNew.type]: (state, action) =>
    set(["byId", action.payload._id], action.payload)(state),
  [act.institutionUpdate.type]: (state, action) => {
    const { _id } = action.payload;
    return update(["byId", _id], (institution) => ({
      ...institution,
      ...action.payload
    }))(state);
  },
  [act.institutionDelete.type]: (state, action) => {
    const { _id } = action.payload;
    delete state.byId[_id];
    return state;
  }
});

export default institutions;
