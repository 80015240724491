import React from "react";
import { useLogout } from "./hooks";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const LogoutPage = () => {
  const history = useHistory();
  const { onLogout } = useLogout();

  const handleLogout = async () => {
    await onLogout();
    history.push("/auth/login");
  };

  handleLogout();

  return <CircularProgress />;
};
export default LogoutPage;
