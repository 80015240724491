import React from "react";
import User from "views/Admin/User";
import { useHistory } from "react-router-dom";

const UserDetails = () => {
  const history = useHistory();
  const userid = history.location?.pathname?.split("/")[3];
  return <User userid={userid} />;
};

export default UserDetails;
