import styled, { css } from "styled-components";

export const ButtonsContainer = styled.div`
  margin: 10px 0;

  display: flex;

  flex: 1;

  gap: 20px;

  justify-content: center;
  align-items: center;
`;

export const DownloadAnchor = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;

  gap: 10px;

  border: 0;
  border-radius: 3px;

  background: #00acc1;
  opacity: 0.3;

  color: white;

  padding: 10px;

  transition: background-color 0.4s;

  font-weight: bold;

  box-shadow: 0 6px 10px -10px rgba(0, 172, 193, 0.28),
    0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 172, 193, 0.2);

  :hover {
    color: white;
  }
  ${({ isEnabled }) =>
    isEnabled &&
    css`
      opacity: 1;
      cursor: pointer;
      :hover {
        color: white;
        background: #006b78;
      }
    `}
`;

export const UploadButton = styled.button`
  cursor: pointer;

  border: 0;
  border-radius: 3px;

  background: #00acc1;

  color: white;

  padding: 11px;

  transition: background-color 0.4s;

  font-weight: bold;
  font-size: 15px;

  box-shadow: 0 6px 10px -10px rgba(0, 172, 193, 0.28),
    0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 172, 193, 0.2);

  :hover {
    background: #006b78;
  }

  input {
    display: none;
  }
`;

export const TopicTypeModalContainer = styled.div`
  position: fixed;

  left: 0;
  top: 0;

  background-color: rgba(0, 0, 0, 0.2);

  z-index: 2000;

  display: flex;
  flex: 1;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const TopicTypeModalContent = styled.div`
  width: 100%;
  max-width: 600px;

  position: relative;

  margin: 0 20px;

  background: white;

  border-radius: 10px;

  display: flex;

  justify-content: center;
  align-items: center;

  flex-direction: column;

  padding: 40px 20px;

  gap: 20px;

  box-shadow: 0 12px 10px 0px #00000055;
`;

export const ModalTitle = styled.h2`
  line-height: 0.8rem;
`;

export const ModalButton = styled.button`
  color: white;

  cursor: pointer;

  background: #00acc1;

  font-weight: bold;
  font-size: 1.05rem;

  box-shadow: 0 6px 10px -10px rgba(0, 172, 193, 0.28),
    0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 172, 193, 0.2);

  border: 0;
  border-radius: 10px;

  padding: 10px;

  height: 2.8rem;
  width: 100%;
  max-width: 32rem;

  transition: background-color 0.4s;

  :hover {
    background: #006b78;
  }
`;

export const AccordionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TopicsTagsContainer = styled.div`
  margin-top: 10px;

  display: flex;
  align-items: center;

  gap: 10px;

  div {
    border-radius: 5px;

    padding: 5px 10px;

    background: orange;
    color: white;

    font-weight: bold;
  }

  span {
    font-weight: bold;
  }
`;

export const CloseModalContainer = styled.div`
  cursor: pointer;

  position: absolute;

  top: 20px;
  right: 20px;
`;

export const SelectCategoryButton = styled.div`
  cursor: pointer;

  position: absolute;

  padding: 10px;

  border-radius: 10px;

  background: #8e24aa;

  color: white;

  font-weight: bold;

  right: 10px;

  transition: background-color 0.4s;

  :hover {
    background: #42114f;
  }
`;
