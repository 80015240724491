import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { useUsers } from "./hooks";
import { USER_MODE_LABELS } from "constants.js";
import AddUserModal from "./Modals/AddUserModal";
import EditUserModal from "./Modals/EditUserModal";
import stylesTasks from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import CloseIcon from "@material-ui/icons/Close";
import ReactTable from "components/ReactTable/ReactTable.js";
import Divider from "@material-ui/core/Divider";
import TableCell from "@material-ui/core/TableCell";
import ModalConfirm from "components/Modal/ModalConfirm";
import useNotification from "utils/hooks/useNotification";
import { http } from "lib/api";
import { useEssaysContext } from "hooks/essays";
import { BsEye } from "react-icons/bs";
import { Link } from "@material-ui/core";

const styles = {
  ...stylesTasks,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const tableColumns = [
  {
    Header: "Nome",
    accessor: "name"
  },
  {
    Header: "Email",
    accessor: "email"
  },
  {
    Header: "Tipo",
    accessor: "type"
  },
  {
    Header: "Data de cadastro",
    accessor: "createdAt"
  },
  {
    Header: "Nota de Avaliação",
    accessor: "rating"
  },
  {
    Header: "Ações",
    accessor: "actions"
  }
];

function VerifierTypeUserJuridical(type, label_user) {
  if (type === "juridical") {
    return "Professor de Direito";
  }
  return label_user;
}

const formatUsersToTable = (classes, users, onEditClick, onDeleteClick) =>
  users.map(
    ({
      _id,
      name,
      email,
      password,
      type,
      createdAt,
      specialClass,
      ignoreWeeklyCreditsExpiration,
      creditsExpiresAt,
      credits,
      rating
    }) => ({
      id: _id,
      name: name,
      email: email,
      type: VerifierTypeUserJuridical(
        type,
        USER_MODE_LABELS[type]
      ).toUpperCase(),
      createdAt: new Date(createdAt).toLocaleDateString(),
      rating: rating.toFixed(2),
      actions: (
        <TableCell className={classes.tableActions + " actions-right"}>
          <Link href={`/admin/usuarios/${_id}`}>
            <Tooltip
              id="see"
              title="Ver"
              placement="top"
              classes={{ tooltip: classes.tooltip }}>
              <IconButton
                aria-label="ver"
                className={classes.tableActionButton}>
                <BsEye className={classes.tableActionButtonIcon} />
              </IconButton>
            </Tooltip>
          </Link>

          <Tooltip
            id="tooltip-top"
            title="Editar"
            placement="top"
            classes={{ tooltip: classes.tooltip }}>
            <IconButton
              aria-label="edit"
              className={classes.tableActionButton}
              onClick={() =>
                onEditClick({
                  _id,
                  name,
                  email,
                  password,
                  type,
                  specialClass,
                  ignoreWeeklyCreditsExpiration,
                  creditsExpiresAt,
                  credits
                })
              }>
              <EditIcon
                className={classes.tableActionButtonIcon + " " + classes.edit}
              />
            </IconButton>
          </Tooltip>

          <Tooltip
            id="tooltip-top-start"
            title="Deletar"
            placement="top"
            className={classes.tableActionButton}
            classes={{ tooltip: classes.tooltip }}>
            <IconButton
              color="warning"
              aria-label="delete"
              className={classes.tableActionButton}
              onClick={() => onDeleteClick({ _id, email })}>
              <CloseIcon
                className={classes.tableActionButtonIcon + " " + classes.close}
              />
            </IconButton>
          </Tooltip>
        </TableCell>
      )
    })
  );

const AdminUsers = () => {
  const classes = useStyles();
  const { setNotificationSuccess, setNotificationError } = useNotification();
  const { loading, onUserDelete, onUserUpdate, stats } = useUsers();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [localLoading, setLocalLoading] = useState(false); // for deleting action

  const [userManage, setUserManage] = useState({});

  const handleEditClick = (user) => {
    setUserManage(user);
    setOpenEditModal(true);
  };

  const handleDeleteClick = (user) => {
    setUserManage(user);
    setOpenDeleteModal(true);
  };

  const handleDeleteSubmit = async () => {
    try {
      setLocalLoading(true);
      await onUserDelete({ _id: userManage._id });
      setOpenDeleteModal(false);
      setLocalLoading(false);
      setNotificationSuccess("Usuário deletado com sucesso");
    } catch (err) {
      setLocalLoading(false);
      setNotificationError(err.data.ErrorMessage);
      throw err;
    }
  };

  const [filteredUsers, setFilteredUsers] = useState([]);

  const {
    currentPage,
    pageParams,
    setPageParams,
    queryFilters,
    setEssaysLoading,
    setQueryFilters,
    setCurrentPage
  } = useEssaysContext();

  async function getUsersList() {
    setEssaysLoading(true);
    const { data } = await http.get(`users`, {
      params: {
        currentPage,
        ...pageParams,
        ...queryFilters
      }
    });
    setEssaysLoading(false);
    setFilteredUsers(data.users);
    setPageParams({
      ...pageParams,
      totalPages: data.totalPages
    });
  }

  useEffect(() => {
    setQueryFilters({});
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    getUsersList();
  }, [currentPage, pageParams.pageSize, queryFilters]);

  return (
    <GridContainer justify="center">
      <ModalConfirm
        show={openDeleteModal}
        onConfirm={handleDeleteSubmit}
        body="Você tem certeza que quer deletar esse usuário?"
        onClose={() => setOpenDeleteModal(false)}
        loading={localLoading}
      />
      <EditUserModal
        open={openEditModal}
        user={userManage}
        onUserUpdate={onUserUpdate}
        handleClose={() => setOpenEditModal(false)}
      />

      <GridItem xs={12}>
        <Card>
          <CardHeader color="warning" icon>
            <CardIcon color="warning">
              <PersonIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Controle de Usuários</h4>
          </CardHeader>
          <CardBody>
            {loading ? (
              <GridContainer justify="center">
                <CircularProgress size={24} />
              </GridContainer>
            ) : (
              <ReactTable
                columns={tableColumns}
                data={formatUsersToTable(
                  classes,
                  filteredUsers,
                  handleEditClick,
                  handleDeleteClick
                )}
              />
            )}
            <div style={{ padding: "20px", marginTop: "20px" }}>
              <Divider variant="middle" />
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} md={2}>
                <Card>
                  <CardHeader style={{ textAlign: "center" }}>
                    <h6>Alunos</h6>
                  </CardHeader>
                  <CardBody style={{ textAlign: "center" }}>
                    {stats.students || "Dados não disponíveis."}
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} md={2}>
                <Card>
                  <CardHeader style={{ textAlign: "center" }}>
                    <h6>Corretores</h6>
                  </CardHeader>
                  <CardBody style={{ textAlign: "center" }}>
                    {stats.teachers || "Dados não disponíveis."}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} md={2}>
                <GridContainer justify="center">
                  <AddUserModal />
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default AdminUsers;
