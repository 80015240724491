import React, { createContext, useContext, useState } from "react";

export const MaterialsContext = createContext({});

export const MaterialsProvider = ({ children }) => {
  const [MaterialsLoading, setMaterialsLoading] = useState(false);
  const [selectedTopicType, setSelectedTopicType] = useState(0);
  const [currentPage, setCurrentPage] = useState("");
  const [videoId, setVideoId] = useState("");
  const [videosArray, setVideosArray] = useState([]);
  const [materialToEdit, setMaterialToEdit] = useState(null);

  return (
    <MaterialsContext.Provider
      value={{
        MaterialsLoading,
        setMaterialsLoading,
        setCurrentPage,
        currentPage,
        selectedTopicType,
        setSelectedTopicType,
        videoId,
        setVideoId,
        setVideosArray,
        videosArray,
        materialToEdit,
        setMaterialToEdit
      }}>
      {children}
    </MaterialsContext.Provider>
  );
};

export function useMaterialsContext() {
  const context = useContext(MaterialsContext);

  if (!context) {
    throw new Error(
      "useMaterialsContext must be used within an MaterialsProvider"
    );
  }
  return context;
}
