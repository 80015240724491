import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;

  svg {
    cursor: pointer;
  }
`;

export const MaterialElement = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  gap: 10px;

  width: 300px;
  height: 320px;

  justify-content: space-between;
  align-items: center;

  background: white;

  border-radius: 10px;

  padding: 10px 20px 70px 20px;

  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);

  p {
    overflow-y: scroll;
    word-break: break-all;

    height: 50px;
    width: 100%;
  }

  img {
    margin: auto 0;
    max-width: 280px;

    position: absolute;

    border-radius: 10px;
  }

  h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 250px;
    height: 1.2em;
    white-space: nowrap;
  }

  transition: ease 0.4s;

  :hover {
    transform: scale(1.03);
  }
`;

export const DownloadButton = styled.a`
  padding: 10px;

  max-height: 40px;

  border-radius: 10px;

  text-decoration: none;

  justify-content: center;
  align-items: center;

  display: flex;

  gap: 10px;

  cursor: pointer;

  background: orange;

  color: white;

  font-weight: bold;

  transition: background 0.4s;

  &:hover {
    color: white;
    background: #a66b00;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;

  flex-wrap: wrap;

  gap: 20px;
`;

export const SelectCategoryButton = styled.button`
  cursor: pointer;

  position: absolute;

  padding: 10px;

  border-radius: 10px;

  background: #00acc1;

  color: white;

  font-weight: bold;

  right: 10px;

  transition: background-color 0.4s;

  border: 0;

  box-shadow: 0 12px 20px -10px rgba(0, 172, 193, 0.28),
    0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 172, 193, 0.2);

  :hover {
    background: #024a60;
  }
`;

export const GoBackButton = styled.div`
  cursor: pointer;

  display: flex;

  align-items: center;

  gap: 15px;

  span {
    font-size: 17px;

    font-weight: bold;
  }
  @media screen and (max-width: 600px) {
    span {
      display: none;
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;

  display: flex;

  justify-content: center;
  align-items: center;

  overflow-y: hidden;

  width: 280px;
  height: 180px;

  border-radius: 10px;
`;

export const DeleteVideo = styled.div`
  width: 20px;
  height: 20px;

  position: absolute;

  bottom: 30px;
  left: 20px;
`;

export const EditVideo = styled.div`
  width: 20px;
  height: 20px;

  position: absolute;

  bottom: 30px;
  right: 20px;
`;

export const PlayIcon = styled.div`
  position: absolute;

  opacity: 0.5;

  right: 120px;
  top: 145px;

  transition: opacity 0.4s;
  :hover {
    opacity: 1;
  }
`;

export const VideoStatus = styled.span`
  position: absolute;

  top: 15px;
  right: 20px;

  font-weight: bold;

  color: ${(prop) => prop.color};
`;
