import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
// import getValidationErrors from "utils/getValidationErrors";
import useNotification from "utils/hooks/useNotification";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesAdmin from "./styles.module.css";
import PersonIcon from "@material-ui/icons/Person";
import CustomInput from "components/CustomInput/CustomInput.js";
import {
  STUDENT_MODE,
  TEACHER_MODE,
  ADMIN_MODE,
  JURIDICAL_MODE
} from "constants.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Radio from "@material-ui/core/Radio";
import { useFormik } from "formik";
import { validateUserUpdate } from "validators.js";
import handleError from "utils/handleError";

const styles2 = {
  ...styles,
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles2);

const EditUserModal = ({ open, user, handleClose, onUserUpdate }) => {
  const classes = useStyles();

  const { setNotificationSuccess, setNotificationError } = useNotification();
  const [loading, setLoading] = useState(false);
  let {
    name,
    email,
    type,
    credits,
    specialClass,
    creditsExpiresAt,
    ignoreWeeklyCreditsExpiration
  } = user;
  const [dateValue, setDateValue] = useState(null);

  if (!specialClass) specialClass = false;

  const formik = useFormik({
    initialValues: {
      name,
      email,
      type,
      credits,
      specialClass,
      creditsExpiresAt,
      ignoreWeeklyCreditsExpiration,
      isManualExpiration: false
    },
    enableReinitialize: true,
    onSubmit: async ({
      name,
      email,
      type,
      credits,
      ignoreWeeklyCreditsExpiration,
      creditsExpiresAt,
      specialClass,
      isManualExpiration
    }) => {
      try {
        setLoading(true);
        await validateUserUpdate({ name, email });
        await onUserUpdate(
          user._id,
          name,
          email,
          type,
          credits,
          specialClass,
          ignoreWeeklyCreditsExpiration,
          isManualExpiration ? creditsExpiresAt : null
        );
        setLoading(false);
        setNotificationSuccess("Usuário editado com sucesso");
        handleClose();
      } catch (err) {
        setLoading(false);
        handleClose();
        handleError(err, setNotificationError);
      }
    }
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogContent>
        <Card>
          <CardHeader color="warning" icon>
            <CardIcon color="warning">
              <PersonIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Editando Usuário</h4>
          </CardHeader>
          <CardBody className={stylesAdmin.registerDialog}>
            <form onSubmit={formik.handleSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Nome"
                    id="name"
                    name="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: formik.values.name,
                      onChange: (e) =>
                        formik.setFieldValue("name", e.target.value)
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    name="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: formik.values.email,
                      onChange: (e) =>
                        formik.setFieldValue("email", e.target.value)
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Créditos de Redação"
                    id="credits"
                    name="credits"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: formik.values.credits,
                      type: "number",
                      onChange: (e) =>
                        formik.setFieldValue("credits", e.target.value)
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h5 className={classes.cardIconTitle}>
                    Cálculo da expiração de créditos
                  </h5>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={formik.values.isManualExpiration === false}
                        onChange={() =>
                          formik.setFieldValue("isManualExpiration", false)
                        }
                        value={false}
                        name="isManualExpiration"
                        id="isManualExpiration"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Automático"
                  />

                  <FormControlLabel
                    control={
                      <Radio
                        checked={formik.values.isManualExpiration === true}
                        onChange={() =>
                          formik.setFieldValue("isManualExpiration", true)
                        }
                        value={true}
                        name="isManualExpiration"
                        id="isManualExpiration"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Manual"
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h5 className={classes.cardIconTitle}>
                    Data de expiração dos créditos
                  </h5>
                  <KeyboardDatePicker
                    disabled={!formik.values.isManualExpiration}
                    value={dateValue || formik.values.creditsExpiresAt}
                    onChange={(date) => {
                      formik.setFieldValue("creditsExpiresAt", date);
                      setDateValue(date);
                    }}
                    format="DD/MM/yyyy"
                    locale="pt-br"
                    cancelLabel="Cancelar"
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h5 className={classes.cardIconTitle}>
                    Ignorar expiração de créditos
                  </h5>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          formik.values.ignoreWeeklyCreditsExpiration === false
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "ignoreWeeklyCreditsExpiration",
                            false
                          )
                        }
                        value={false}
                        name="ignoreWeeklyCreditsExpiration"
                        id="ignoreWeeklyCreditsExpiration"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Não"
                  />

                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          formik.values.ignoreWeeklyCreditsExpiration === true
                        }
                        onChange={() =>
                          formik.setFieldValue(
                            "ignoreWeeklyCreditsExpiration",
                            true
                          )
                        }
                        value={true}
                        name="ignoreWeeklyCreditsExpiration"
                        id="ignoreWeeklyCreditsExpiration"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Sim"
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={formik.values.type === STUDENT_MODE}
                        onChange={(e) =>
                          formik.setFieldValue("type", e.target.value)
                        }
                        value={STUDENT_MODE}
                        name="type"
                        id="type"
                        // aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Aluno"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={formik.values.type === TEACHER_MODE}
                        onChange={(e) =>
                          formik.setFieldValue("type", e.target.value)
                        }
                        value={TEACHER_MODE}
                        name="type"
                        id="type"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Professor"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={formik.values.type === ADMIN_MODE}
                        onChange={(e) =>
                          formik.setFieldValue("type", e.target.value)
                        }
                        value={ADMIN_MODE}
                        name="type"
                        id="type"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Admin"
                  />

                  <FormControlLabel
                    control={
                      <Radio
                        checked={formik.values.type === JURIDICAL_MODE}
                        onChange={(e) =>
                          formik.setFieldValue("type", e.target.value)
                        }
                        value={JURIDICAL_MODE}
                        name="type"
                        id="type"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Professor de Direito"
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h5 className={classes.cardIconTitle}>
                    Permissão Turma especial
                  </h5>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={formik.values.specialClass === false}
                        onChange={() =>
                          formik.setFieldValue("specialClass", false)
                        }
                        value={false}
                        name="specialClass"
                        id="specialClass"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Não"
                  />

                  <FormControlLabel
                    control={
                      <Radio
                        checked={formik.values.specialClass === true}
                        onChange={() =>
                          formik.setFieldValue("specialClass", true)
                        }
                        value={true}
                        name="specialClass"
                        id="specialClass"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot
                    }}
                    label="Sim"
                  />
                </GridItem>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={formik.handleSubmit} color="primary">
          {!loading ? "Editar" : <CircularProgress color="secondary" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserModal;
