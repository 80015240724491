import React from "react";
import {
  REVIEW_STATUS_DONE,
  REVIEW_STATUS_IN_PROGRESS,
  REVIEW_STATUS_INELIGIBLE,
  REVIEW_STATUS_IN_PROGRESS_JURIDICAL,
  REVIEW_STATUS_PARTIAL_CORRECTION,
  TOPIC_TYPE
} from "constants.js";
import * as S from "./styles";
/**
 * getTotalReviewed returns the number of total essays reviewed,
 * remaining essays and essays in progress.
 * @param { Array } essays list of essays
 * @return { Object } total reviewed, remaining and inProgress
 */
export function getTotalReviewed(essays) {
  let reviewed = 0;
  let inProgress = 0;
  essays.forEach((essay) => {
    if (essay.status === REVIEW_STATUS_DONE) reviewed += 1;
    else if (
      essay.status === REVIEW_STATUS_IN_PROGRESS ||
      essay.status === REVIEW_STATUS_IN_PROGRESS_JURIDICAL
    )
      inProgress += 1;
  });
  const remaining = essays.length - reviewed;

  return {
    reviewed,
    remaining,
    inProgress
  };
}

/**
 * getDataTableStudent returns data parsed to table data
 * @param { Array } essays list of essays
 * @param { Component } seeReview See Review button
 * @param { Component } correctionNotAvailable Correction not Available disabled button
 */
export function getDataTableStudent(
  essays,
  reviewDone,
  reviewInProgress,
  reviewNotAvailable,
  reviewIneligible
) {
  const dataTable = essays.map((essay) => {
    const parsedDate = new Date(essay.createdAt);
    const essayObj = essay.data || essay;

    return [
      <S.TopicData key={essay.id}>
        <strong>
          Tema {essay.topic.number}
          {essay.topic?.type ? ` - ${TOPIC_TYPE[essay.topic?.type]}` : ""}
        </strong>
        : {essay.topic?.name}
      </S.TopicData>,
      essayObj.contest?.institution?.name,
      parsedDate.toLocaleDateString("pt-BR"),
      essayObj.review
        ? (essayObj.review?.grades?.final || 0) + " / 1000"
        : "--/--",
      essayObj.status === REVIEW_STATUS_DONE
        ? reviewDone(essayObj._id)
        : essayObj.status === REVIEW_STATUS_IN_PROGRESS ||
          essayObj.status === REVIEW_STATUS_PARTIAL_CORRECTION ||
          essayObj.status === REVIEW_STATUS_IN_PROGRESS_JURIDICAL
        ? reviewInProgress
        : essayObj.status === REVIEW_STATUS_INELIGIBLE
        ? reviewIneligible
        : reviewNotAvailable
    ];
  });

  return {
    dataTable
  };
}

/**
 * getDataChartStudent returns data parsed to chart data
 * @param { Array } essays list of essays
 */
export function getDataChartStudent(essays) {
  const dataChart = [[]];
  essays.forEach((essay) => {
    if (essay.status === REVIEW_STATUS_DONE) dataChart[0].push(7); // TODO: CHANGE GRADE DYNAMICALLY
  });

  return {
    dataChart
  };
}

/**
 * getDaysCurrentMonth returns an array of labels, with every
 * day of the current month until today
 */
export function getDaysCurrentMonth() {
  const date = new Date();

  const monthDays = date.getDate();
  let essaysLabels = [];
  for (let i = 0; i < monthDays; i++) {
    essaysLabels.push(
      `${(i + 1).toLocaleString("pt-BR", { minimumIntegerDigits: 2 })}`
    );
  }

  return essaysLabels;
}

/**
 * getDataTableTeacher returns data parsed to table data
 * @param { Array } essays list of essays
 * @param { Component } reviewActions review possible Actions button
 */
export function getDataTableTeacher(essays, reviewActions, user) {
  const dataTable = essays
    .filter(
      (essay) =>
        essay.status === REVIEW_STATUS_IN_PROGRESS ||
        (essay.status === REVIEW_STATUS_IN_PROGRESS_JURIDICAL &&
          user.type === "juridical")
    )
    .map((essay) => {
      let start_essay = ClassifierTypeUserStartEssay(essay, user.type);
      return [
        essay.contest?.name,
        <S.TopicData key={essay.id}>
          <strong>
            Tema {essay.topic.number}
            {essay.topic?.type ? ` - ${TOPIC_TYPE[essay.topic?.type]}` : ""}
          </strong>
          : {essay.topic?.name}
        </S.TopicData>,
        essay.user?.name,
        reviewActions(essay._id),
        start_essay
      ];
    });

  return {
    dataTable
  };
}

function ClassifierTypeUserStartEssay(essay, user_type) {
  if (user_type === "juridical") {
    return essay.review?.juridical_start;
  } else if (user_type === "teacher" || user_type === "admin") {
    return essay.review?.start;
  }
}

/**
 * filterByDay checks if it's today's essay
 * @param { Object } essay
 * @param { number } day
 * @param { Date } today
 */
const filterByDay = (day, today) => (essay) => {
  const essayDate = new Date(essay.review?.updatedAt);
  return (
    essay.status === REVIEW_STATUS_DONE &&
    essayDate.getDate() === +day &&
    essayDate.getMonth() === today.getMonth() &&
    essayDate.getFullYear() === today.getFullYear()
  );
};

/**
 * getDataChartTeacher returns data parsed to chart data
 * @param { Array } essays list of essays
 * @param { Array } dates list of chart labels
 */
export function getDataChartTeacher(essays, dates) {
  const today = new Date();
  const essaysData = dates.map(
    (d) => essays.filter(filterByDay(d, today)).length
  );
  return [essaysData];
}

/**
 * getDataChartAdmin returns data parsed to chart data
 * if some of the percentages is zero, the label is empty and not shown
 * @param { number } totalEssays
 * @param { number } totalReviewed
 */
export function getDataChartAdmin(totalEssays, totalReviewed) {
  const reviewedPorcentage = (totalReviewed * 100) / totalEssays;
  const firstLabel =
    100 - reviewedPorcentage === 0
      ? " "
      : `${(100 - reviewedPorcentage).toFixed(2)}%`;

  const secondLabel =
    reviewedPorcentage === 0 ? " " : `${reviewedPorcentage.toFixed(2)}%`;

  return {
    labels: [firstLabel, secondLabel],
    series: [100 - reviewedPorcentage, reviewedPorcentage]
  };
}
