import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Assignment from "@material-ui/icons/Assignment";
import Comment from "@material-ui/icons/Comment";
import PersonIcon from "@material-ui/icons/Person";
import GradeIcon from "@material-ui/icons/Grade";

import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import ModalConfirm from "components/Modal/ModalConfirm";
import Table from "components/Table/Table.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import {
  Card,
  CardBody,
  CardIcon,
  CardHeader,
  CardTitle
} from "components/Card";
import Review from "components/EssayReview";
import TextEditor from "components/TextEditor";
import useNotification from "utils/hooks/useNotification";
// import GradeENEM from "./GradeENEM";
// import Timer from "components/Timer";
import useEssay from "./hooks";
import styles from "./styles.module.css";
import { TOPIC_TYPE, TOPIC_TYPE_JURIDICAL } from "constants.js";

import {
  REVIEW_STATUS_DONE,
  REVIEW_STATUS_INELIGIBLE,
  ADMIN_MODE,
  STUDENT_MODE,
  REVIEW_STATUS_AVAILABLE,
  POLICIA_CIVIL_CEARA,
  PROJETO_CAVEIRA_PC_CEARA,
  POLICIA_CIVIL_AMAZONAS,
  POLICIA_CIVIL_BAHIA,
  TOPIC_TYPE_GRAMMAR_LESSONS
} from "constants.js";

import * as S from "./styles";
import Muted from "components/Typography/Muted";
import AudioRecorder from "components/AudioRecorder/AudioRecorder";
import { Delete, Star } from "@material-ui/icons";
import { Button, Typography } from "@material-ui/core";
import RatingComponent from "./RatingComponent";
import AudioController from "components/audio/AudioController";
import getGradeType from "./gradeTypes/gradeType";

const EssayReview = ({ history, readOnly }) => {
  const essayId = history.location.pathname.split("/")[4];
  const platform = history.location.pathname.split("/")[1];
  const { setNotificationSuccess, setNotificationError } = useNotification();

  const [openModal, setOpenModal] = useState(false);
  const [openIneligibleModal, setOpenIneligibleModal] = useState(false);
  const [openAudioDeleteModal, setOpenAudioDeleteModal] = useState(false);

  const {
    essay,
    onSaveReview,
    onSubmitReview,
    onDeleteAudio,
    reviews,
    loading,
    comment,
    audioPath,
    user
  } = useEssay(essayId);

  const [essayStatus, setEssayStatus] = useState(essay?.status);

  const isAvailable = essay?.status === REVIEW_STATUS_AVAILABLE;
  const isAdmin = user?.type === ADMIN_MODE;
  const isReviewer = essay?.reviewerid === user?._id;
  // const isReviewerJuridical = essay?.juridical_reviewerid === user?._id;
  const isStudent = essay?.user?._id === user._id;

  const isEssayDoubleCorrection =
    essay?.contest?.name !== POLICIA_CIVIL_CEARA &&
    essay?.contest?.name !== PROJETO_CAVEIRA_PC_CEARA &&
    essay?.contest?.name !== POLICIA_CIVIL_AMAZONAS &&
    essay?.contest?.name !== POLICIA_CIVIL_BAHIA &&
    essay?.topic?.type !== TOPIC_TYPE_JURIDICAL;

  let gramatical_field_allow = false;
  let juridical_field_allow = false;

  if (
    essay &&
    !isAvailable &&
    !isAdmin &&
    !isReviewer &&
    !isStudent &&
    isEssayDoubleCorrection
  )
    history.push(`/${platform}/redacoes`);

  // if (isEssayDoubleCorrection && !isReviewerJuridical) {
  //   history.push(`/${platform}/redacoes`);
  // }

  // Request data
  const [isSendLoading, setIsSendLoading] = useState(false);
  const [reviewsProps, setReviewsProps] = useState();
  const [grades, setGrades] = useState({});
  const [generalComment, setGeneralComment] = useState(comment);
  const [audioBlob, setAudioBlob] = useState(null);
  const [isRecording, setIsRecording] = useState(false);

  // case in Gramaticalonly, so it will blocked fields: comments and notes
  if (
    (essay?.contest?.name === POLICIA_CIVIL_CEARA ||
      essay?.contest?.name === PROJETO_CAVEIRA_PC_CEARA ||
      essay?.contest?.name === POLICIA_CIVIL_AMAZONAS ||
      essay?.contest?.name === POLICIA_CIVIL_BAHIA ||
      essay?.topic?.type === TOPIC_TYPE_JURIDICAL) &&
    user?.type === "juridical"
  ) {
    juridical_field_allow = true;
  } else if (
    (essay?.contest?.name === POLICIA_CIVIL_CEARA ||
      essay?.contest?.name === PROJETO_CAVEIRA_PC_CEARA ||
      essay?.contest?.name === POLICIA_CIVIL_AMAZONAS ||
      essay?.contest?.name === POLICIA_CIVIL_BAHIA ||
      essay?.topic?.type === TOPIC_TYPE_JURIDICAL) &&
    user?.type === "teacher"
  ) {
    gramatical_field_allow = true;
  } else if (
    essay?.contest?.name !== POLICIA_CIVIL_CEARA ||
    essay?.contest?.name !== PROJETO_CAVEIRA_PC_CEARA ||
    essay?.contest?.name !== POLICIA_CIVIL_AMAZONAS ||
    essay?.contest?.name !== POLICIA_CIVIL_BAHIA ||
    essay?.topic?.type !== TOPIC_TYPE_JURIDICAL
  ) {
    gramatical_field_allow = true;
    juridical_field_allow = true;
  }

  const handleSaveReview = async (reviewsProps) => {
    if (isRecording) {
      return;
    }

    try {
      await onSaveReview(reviewsProps, generalComment, grades, user, audioBlob);
      setNotificationSuccess("Correção salva!");

      if (audioBlob) {
        setAudioBlob(null);
      }
    } catch (err) {
      setNotificationError(err.data.ErrorMessage);
      throw err;
    }
  };

  const handleDeleteAudio = async () => {
    try {
      if (audioPath) {
        await onDeleteAudio(essayId);
      }

      setAudioBlob(null);

      setOpenAudioDeleteModal(false);
      setNotificationSuccess("Áudio apagado com sucesso");
    } catch (err) {
      setNotificationError(err.data.ErrorMessage);
    }
  };

  const handleSubmitReview = async () => {
    setIsSendLoading(true);
    // collapse all review items before submitting definitely
    try {
      const newReviewProps = reviewsProps.map((el) => ({
        ...el,
        show: false
      }));

      await onSubmitReview(
        newReviewProps,
        generalComment,
        grades,
        REVIEW_STATUS_DONE,
        user,
        audioBlob
      );
      setNotificationSuccess("Correção enviada com sucesso");
      history.replace(`/${platform}/redacoes/detalhes/${essayId}`);
    } catch (err) {
      setNotificationError(err.data.ErrorMessage);
      throw err;
    }
  };

  const handleIneligibleReview = async () => {
    // collapse all review items before submitting definitely
    try {
      const newReviewProps = reviewsProps.map((el) => ({
        ...el,
        show: false
      }));

      await onSubmitReview(
        newReviewProps,
        generalComment,
        grades,
        REVIEW_STATUS_INELIGIBLE,
        user
      );

      setNotificationSuccess("Correção marcada como ilegível");

      history.replace(`/${platform}/redacoes/detalhes/${essayId}`);
    } catch (err) {
      setNotificationError(err.data.ErrorMessage);
      throw err;
    }
  };

  const handleOpenSubmitModal = useCallback(
    (reviewsProps) => {
      const { errors, lines } = grades;

      if (
        user?.type === "teacher" &&
        gramatical_field_allow &&
        !juridical_field_allow &&
        (!(lines >= 0 && lines !== null) || !(errors >= 0 && errors !== null))
      ) {
        setNotificationError(
          "Os campos Número de erros e Número de linhas são Obrigatórios para Enviar a redação."
        );
        return;
      }
      if (user?.type === "juridical" && !(lines >= 0 && lines !== null)) {
        setNotificationError("Número de linhas não pode estar vazio.");
        return;
      }
      if (
        gramatical_field_allow &&
        juridical_field_allow &&
        (errors === null || lines === null)
      ) {
        setNotificationError(
          "Os campos Número de erros e Número de linhas são Obrigatórios para Enviar a redação."
        );
        return;
      }
      setReviewsProps(reviewsProps);
      setOpenModal(true);
    },
    [grades, gramatical_field_allow, juridical_field_allow]
  );

  const handleTypeGeneralComment = (comment) => {
    setGeneralComment(comment);
  };

  const handleAudioBlob = (blob) => {
    setAudioBlob(blob);
  };

  useEffect(() => {
    setGeneralComment(comment);
  }, [comment]);

  useEffect(() => {
    setEssayStatus(essay?.status);
  }, [essay?.status]);

  return !loading && essay && essay.path ? (
    <GridContainer>
      <ModalConfirm
        show={openModal}
        onConfirm={handleSubmitReview}
        body="Você tem certeza que quer enviar a correção dessa redação?"
        onClose={() => setOpenModal(false)}
        loading={isSendLoading}
      />
      <ModalConfirm
        show={openIneligibleModal}
        onConfirm={handleIneligibleReview}
        body="Você tem certeza que quer marcar essa redação como ilegível?"
        onClose={() => setOpenIneligibleModal(false)}
      />
      <ModalConfirm
        show={openAudioDeleteModal}
        onConfirm={handleDeleteAudio}
        body="Você tem certeza que quer apagar esse áudio? (Essa ação não pode ser desfeita)"
        onClose={() => setOpenAudioDeleteModal(false)}
      />
      {/* CABEÇALHO */}
      <GridItem>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <PersonIcon />
            </CardIcon>
            <CardTitle title="Cabeçalho" />
          </CardHeader>
          <CardBody>
            {
              <div className={styles.gradeWrapper}>
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "ID",
                    "Aluno",
                    "Tema",
                    user.type !== STUDENT_MODE && `Corretor`
                  ]}
                  tableData={[
                    [
                      essay.id,
                      essay.user?.name,
                      <S.TopicData key={essay.id}>
                        <strong>
                          Tema {essay.topic?.number}
                          {essay.topic?.type
                            ? ` - ${TOPIC_TYPE[essay.topic?.type]}`
                            : ""}
                        </strong>
                        :{" "}
                        {essay.topic ? (
                          essay.topic?.name
                        ) : (
                          <span color="red">Indisponível</span>
                        )}
                      </S.TopicData>,

                      user.type !== STUDENT_MODE &&
                        `${essay.review?.reviewer?.name}${
                          essay.review?.juridical_reviewer?.name
                            ? "/" + essay.review?.juridical_reviewer?.name
                            : ""
                        }
                        `
                    ]
                  ]}
                  coloredColls={[3]}
                  colorsColls={["primary"]}
                />
              </div>
            }
          </CardBody>
        </Card>
      </GridItem>
      {/* CORREÇÃO DA REDAÇÃO */}
      <GridItem>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <CardTitle
              title={
                <>
                  <span>Corrigir Redação</span>
                  {user?.type === "student" && (
                    <span style={{ color: "#d14949" }}>
                      {" "}
                      - Clique nas caixas coloridas colocadas em sua redação
                      para ver a explicação do corretor.
                    </span>
                  )}
                </>
              }
            />
          </CardHeader>
          <CardBody>
            {
              <Review
                currentUserId={user?.id}
                image={essay?.path}
                essayStatus={essay?.status}
                onReview={handleSaveReview}
                onSubmit={handleOpenSubmitModal}
                onIneligible={(reviewProps) => {
                  setReviewsProps(reviewProps);
                  setOpenIneligibleModal(true);
                }}
                initialReviews={reviews}
                readOnly={readOnly}
                gramaticalOnly={!gramatical_field_allow}
              />
            }
          </CardBody>
        </Card>
      </GridItem>
      <GridItem>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <Comment />
            </CardIcon>
            <CardTitle title="Comentário Geral" />
          </CardHeader>
          {!readOnly && (
            <Muted
              style={{
                marginTop: "15px",
                padding: "0 24px",
                maxWidth: "950px"
              }}>
              Caro corretor, caso o aluno tenha solicitado a correção de acordo
              com um edital específico, por favor, atente-se ao pedido e atribua
              pontos com base nos critérios e na pontuação do edital (inclusive
              com a nota final). Caso não mencione nenhum edital específico,
              utilize a sequência de critérios recomendada pela coordenação. Uso
              o espaço a seguir para isso.
            </Muted>
          )}
          <CardBody className={styles.editorWrapper}>
            {
              <TextEditor
                className={styles.editor}
                initialText={comment}
                onChange={handleTypeGeneralComment}
                readOnly={readOnly || !juridical_field_allow}
              />
            }

            <S.AudioSection>
              {!readOnly && juridical_field_allow && (
                <>
                  {!audioPath && !audioBlob && (
                    <>
                      <Muted>Grave o áudio da correção aqui.</Muted>
                      <AudioRecorder
                        src={audioBlob}
                        setAudioBlob={handleAudioBlob}
                        onStartRecording={() => setIsRecording(true)}
                        onStopRecording={() => setIsRecording(false)}
                      />
                    </>
                  )}

                  {audioBlob && !audioPath && (
                    <>
                      <Typography
                        variant="body2"
                        style={{
                          marginBottom: "12px",
                          borderTop: "1px solid #c5c5c584",
                          paddingTop: "8px",
                          maxWidth: "980px",
                          alignSelf: "flex-start"
                        }}>
                        Caro corretor, antes de enviar, recomendamos que ouça o
                        áudio e verifique se não há ruídos ou se a qualidade
                        está abaixo do esperado. Nossos alunos agradecem.
                      </Typography>
                      <AudioController src={URL.createObjectURL(audioBlob)} />
                      <Button
                        onClick={() => setOpenAudioDeleteModal(true)}
                        style={{
                          color: "#f00",
                          padding: "10px",
                          width: "100%"
                        }}>
                        <Delete size={5} />
                      </Button>
                    </>
                  )}

                  {audioPath && (
                    <>
                      <Typography
                        variant="body2"
                        style={{
                          marginBottom: "12px",
                          borderTop: "1px solid #c5c5c584",
                          paddingTop: "8px",
                          maxWidth: "980px",
                          alignSelf: "flex-start"
                        }}>
                        Caro corretor, antes de enviar, recomendamos que ouça o
                        áudio e verifique se não há ruídos ou se a qualidade
                        está abaixo do esperado. Nossos alunos agradecem.
                      </Typography>
                      <AudioController src={audioPath} />
                      <Button
                        onClick={() => setOpenAudioDeleteModal(true)}
                        style={{
                          color: "#f00",
                          padding: "10px",
                          width: "100%"
                        }}>
                        <Delete size={5} />
                      </Button>
                    </>
                  )}
                </>
              )}

              {readOnly && audioPath && (
                <>
                  <Typography
                    variant="body2"
                    style={{
                      marginBottom: "12px",
                      borderTop: "1px solid #c5c5c584",
                      paddingTop: "8px",
                      maxWidth: "980px",
                      alignSelf: "flex-start"
                    }}>
                    Olá, estudante! Com vistas a manter a humanização do
                    processo de correção e ofertar-lhe maior clareza quanto às
                    explicações, fizemos a análise do seu texto em áudio. Por
                    favor, clique no botão abaixo para iniciar a reprodução.
                  </Typography>
                  <AudioController src={audioPath} />
                </>
              )}
            </S.AudioSection>
          </CardBody>
        </Card>
      </GridItem>
      {/* NOTA */}
      {essay?.topic.type !== TOPIC_TYPE_GRAMMAR_LESSONS && (
        <GridItem>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <GradeIcon />
              </CardIcon>
              {<CardTitle title="Nota" />}
            </CardHeader>

            <CardBody>
              {getGradeType(essay?.topic?.type, {
                initialGrades: essay.review?.grades,
                setGrades: setGrades,
                readOnly: readOnly,
                gramaticalOnly: !gramatical_field_allow,
                juridicalOnly: !juridical_field_allow
              })}
            </CardBody>
          </Card>
        </GridItem>
      )}
      {essayStatus === REVIEW_STATUS_DONE ? (
        <GridItem>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Star />
              </CardIcon>
              <CardTitle title="Avaliação da Correção" />
            </CardHeader>
            <CardBody>
              {essay.topic.type !== TOPIC_TYPE_JURIDICAL && (
                <Muted>
                  Caro aluno, considere avaliar a correção e o corretor. Sua
                  opinião é muito importante para nós. Obrigado!
                </Muted>
              )}
              {essay && user && <RatingComponent user={user} essay={essay} />}
            </CardBody>
          </Card>
        </GridItem>
      ) : null}
    </GridContainer>
  ) : (
    <GridContainer justify="center">
      <CircularProgress size={20} color="primary" />
    </GridContainer>
  );
};

export default withRouter(EssayReview);
