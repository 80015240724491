import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { useRegister } from "../hooks";
import { STUDENT_MODE, TEACHER_MODE, JURIDICAL_MODE } from "constants.js";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import PersonIcon from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import useNotification from 'utils/hooks/useNotification';
// TODO: remove this styles
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesAdmin from "./styles.module.css";

const styles2 = {
  ...styles,
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};
const useStyles = makeStyles(styles2);

// TODO (after release maybe): rewrite shitty form
const RegisterModal = () => {
  const classes = useStyles();
  const { onRegister } = useRegister();

  const { 
    setNotificationSuccess,
    setNotificationError
  } = useNotification();

  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [type, setType] = useState(STUDENT_MODE);
  const [email, setEmail] = useState("");
  const [emailState, setEmailState] = useState("");
  const [password, setPassword] = useState("");
  const [passwordState, setPasswordState] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordState, setConfirmPasswordState] = useState("");
  const [credits, setCredits] = useState(0);

  const verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearForm = () => {
    setName("");
    setEmail("");
    setEmailState("");
    setPassword("");
    setPasswordState("");
    setConfirmPassword("");
    setConfirmPasswordState("");
    setCredits(0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await onRegister(name, email, password, type, credits);
      setLoading(false);
      clearForm();
      handleClose();
      setNotificationSuccess("Usuário cadastrado com sucesso")
    } catch (err) {
      setLoading(false);
      setNotificationError(err.data.ErrorMessage);
      throw err;
    }
  };

  return (
    <div>
      <GridItem xs={6} sm={6} md={3}>
        <Card style={{width: "8rem"}}>
          <CardBody style={{ textAlign: "center" }}>
            <h6>Cadastrar Usuário</h6>

            <IconButton style={{ color: "#F49811" }}>
              <AddCircleIcon onClick={handleClickOpen} />
            </IconButton>
          </CardBody>
        </Card>
      </GridItem>

      <Dialog open={open} onClose={handleClose}>
        {/* <DialogTitle id="form-dialog-title">Cadastro de Usuário</DialogTitle> */}
        <DialogContent>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Cadastro de Usuário</h4>
            </CardHeader>
            <CardBody className={stylesAdmin.registerDialog}>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Nome"
                      id="name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          setName(event.target.value);
                        },
                      }}
                    />
                  </GridItem>              
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      success={emailState === "success"}
                      error={emailState === "error"}
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          if (verifyEmail(event.target.value)) {
                            setEmailState("success");
                          } 
                          else if (event.target.value === "") {
                            setEmailState("");
                          }
                          else {
                            setEmailState("error");
                          }
                          setEmail(event.target.value);
                        },
                        type: "email"
                      }}
                    />
                  </GridItem>
                </GridContainer>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}> 
                    <CustomInput
                      success={passwordState === "success"}
                      error={passwordState === "error"}
                      labelText="Senha"
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: event => {
                          if (verifyLength(event.target.value, 1)) {
                            setPasswordState("success");
                          } 
                          else if (event.target.value === "") {
                            setPasswordState("");
                          } 
                          else {
                            setPasswordState("error");
                          }
                          setPassword(event.target.value);
                        },
                        type: "password",
                        autoComplete: "off"
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      success={confirmPasswordState === "success"}
                      error={confirmPasswordState === "error"}
                      labelText="Confirme a Senha"
                      id="confirmPassword"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: confirmPassword,
                        onChange: event => {
                          if (password === event.target.value) {
                            setConfirmPasswordState("success");
                          } else {
                            setConfirmPasswordState("error");
                          }
                          setConfirmPassword(event.target.value);
                        },
                        type: "password",
                        autoComplete: "off"
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      // success={creditsState === "success"}
                      // error={creditsState === "error"}
                      labelText="Créditos de Redação"
                      id="credits"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: credits,
                        onChange: event => {
                          setCredits(parseInt(event.target.value), 10);
                        },
                        type: "number",
                        autoComplete: "off"
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={type === STUDENT_MODE}
                          onChange={() => setType(STUDENT_MODE)}
                          value={STUDENT_MODE}
                          name="student"
                          // aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Aluno"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={type === TEACHER_MODE}
                          onChange={() => setType(TEACHER_MODE)}
                          value={TEACHER_MODE}
                          name="teacher"
                          // aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Professor"
                    />

                    <FormControlLabel
                      control={
                        <Radio
                          checked={type === JURIDICAL_MODE}
                          onChange={() => setType(JURIDICAL_MODE)}
                          value={JURIDICAL_MODE}
                          name="juridical"
                          // aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot
                      }}
                      label="Professor de Direito"
                    />       
                  </GridItem>
              </GridContainer>
              </form>
            </CardBody>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={handleSubmit} style={{ color: "#F49913"}}>
            {!loading ? "Cadastrar" : <CircularProgress color="secondary" />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default RegisterModal;