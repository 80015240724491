import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import PermIdentity from "@material-ui/icons/PermIdentity";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
// import style from "./style.module.css";
// import { CardIcon } from "components/Card";
import { USER_MODE_LABELS } from "constants.js";
import { CardHeader } from "components/Card";
import { CardIcon } from "components/Card";
import UpdateUser from "components/User/UpdateUser";
import PasswordChange from "components/User/PasswordChange";
import style from "./style.module.css";
import { useUserProfile } from "./hooks";
import { PermIdentity, Lock } from "@material-ui/icons";
import CreditsBanner from "components/CreditsBanner";

const useStyles = makeStyles(styles);

const UserProfile = ({ mode }) => {
  const classes = useStyles();
  const { onUserUpdate, user, onUserPasswordChange } = useUserProfile();
  const createdAt = new Date(user.createdAt);
  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <CreditsBanner />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h3 className={style.title}>Atualizar Informações Pessoais</h3>
            </CardHeader>
            <CardBody>
              <UpdateUser onUserUpdate={onUserUpdate} user={user} />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card profile>
            <CardAvatar profile>
              <Link href="/" onClick={(e) => e.preventDefault()}>
                <img
                  src={`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${user.name}&size=256`}
                  alt="avatar"
                />
              </Link>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>{USER_MODE_LABELS[mode]}</h6>
              <h4 className={classes.cardTitle}>{user.name}</h4>
              <p className={classes.description}>{user.email}</p>
              <p className={classes.description}>
                Entrou em: {createdAt.getDay()}/{createdAt.getMonth() + 1}/
                {createdAt.getFullYear()}
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Lock />
              </CardIcon>
              <h3 className={style.title}>Mudar Senha</h3>
            </CardHeader>
            <CardBody>
              <PasswordChange onUserPasswordChange={onUserPasswordChange} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default UserProfile;
