import { useDispatch, useSelector } from "react-redux";
import * as usersActions from "redux/actions/users";
import * as authSelector from "redux/selectors/auth";

export function useUserProfile() {
  const dispatch = useDispatch();
  const authenticatedUser = useSelector(authSelector.authenticatedUser);

  const onUserUpdate = (name, email, password) =>
    dispatch(
      usersActions.onUserUpdate(
        authenticatedUser._id,
        name,
        email,
        password,
        authenticatedUser.type
      )
    );
  const onUserPasswordChange = (password, newpassword) =>
    dispatch(
      usersActions.onUserPasswordChange(
        authenticatedUser._id,
        password,
        newpassword
      )
    );

  return {
    onUserUpdate,
    user: authenticatedUser,
    onUserPasswordChange
  };
}
