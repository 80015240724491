import get from "lodash/fp/get";
import { createSelector } from "reselect";

const topicsSelector = get("topics");

export const topicsById = createSelector(topicsSelector, get("byId"));

export const topicById = (id) => createSelector(topicsById, get(id));

export const topics = createSelector(topicsById, (topics) =>
  Object.values(topics)
);
