import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    border: 0;
    border-radius: 10px;

    cursor: pointer;

    background: #00acc1;
    color: white;

    padding: 10px;

    transition: background 0.4s;

    :hover {
      background: #0d5a63;
    }
  }
`;

export const TopicData = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;

  white-space: nowrap;
`;
