import React, { useState } from "react";
import { useFormik } from "formik";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LockOpen from "@material-ui/icons/LockOpen";
import CustomInput from "components/CustomInput/CustomInput";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Button from "@material-ui/core/Button/Button";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardIcon
} from "components/Card";
import styles from "./styles.module.css";
import { validateUserPasswordChange } from "validators.js";
import { useNotification } from "utils/hooks";
import handleError from "utils/handleError";

const ResetPasswordModal = ({ open, handleClose, onReset }) => {
  const [loading, setLoading] = useState(false);
  const { setNotificationSuccess, setNotificationError } = useNotification();
  const formik = useFormik({
    initialValues: {
      password: "",
      passwordconfirm: ""
    },
    onSubmit: async ({ password, passwordconfirm }) => {
      try {
        setLoading(true);
        await validateUserPasswordChange({
          password,
          passwordconfirm
        });
        await onReset(password);

        setLoading(false);
        setNotificationSuccess("Informações atualizadas com sucesso!");
      } catch (err) {
        setLoading(false);
        handleError(err, setNotificationError);
      }
    },
    enableReinitialize: true
  });
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Card>
          <CardHeader color="danger" icon>
            <CardIcon color="danger">
              <LockOpen />
            </CardIcon>
            <CardTitle title="Redefinir Senha de Usuário" />
          </CardHeader>
          <CardBody className={styles.addContentDialog}>
            <form onSubmit={formik.handleSubmit}>
              <CustomInput
                labelText="Nova Senha"
                id="password"
                name="password"
                color="primary"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: formik.handleChange,
                  type: "password"
                }}
              />
              <CustomInput
                labelText="Confirmar Nova Senha"
                id="passwordconfirm"
                name="passwordconfirm"
                color="primary"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: formik.handleChange,
                  type: "password"
                }}
              />

              <Button type="submit">
                {!loading ? "Atualizar" : <CircularProgress color="primary" />}
              </Button>
            </form>
          </CardBody>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPasswordModal;
