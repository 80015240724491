import React, { useState } from "react";
import Snackbar from "components/Snackbar/Snackbar.js";

export const NotificationContext = React.createContext({
  open: false,
  setNotification: () => {}
});

export const NotificationProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);

  const setNotification = (color, icon, text) => {
    setOpen(true);
    setMessage({ color, icon, text });
    setTimeout(() => setOpen(false), 5000);
  };

  const initialContext = {
    open: false,
    setNotification: setNotification
  };

  return (
    <NotificationContext.Provider value={initialContext}>
      {message && (
        <Snackbar
          place="tr"
          color={message.color}
          icon={message.icon}
          message={message.text}
          open={open}
          closeNotification={() => setOpen(false)}
          close
        />
      )}

      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
