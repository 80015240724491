import get from "lodash/fp/get";
import { createSelector } from "reselect";

const contestsSelector = get("contests");

export const contestsById = createSelector(contestsSelector, get("byId"));

export const contestById = (id) => createSelector(contestsById, get(id));

export const contests = createSelector(contestsById, (contests) =>
  Object.values(contests)
);

export const simulations = createSelector(contests, (contests) =>
  contests.filter((c) => !!c.isSimulation)
);
