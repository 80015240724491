import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";

//? Components...
import EmailInput from "components/LoginInputs/EmailInput";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { validateUserEmail } from "validators.js";
import { useNotification } from "utils/hooks";
import handleError from "utils/handleError";

//? Stylesheets...
import style from "./styles.module.css";

const PasswordReset = ({ onUserPasswordReset }) => {
  const [loading, setLoading] = useState(false);
  const { setNotificationSuccess, setNotificationError } = useNotification();
  const formik = useFormik({
    initialValues: {
      email: ""
    },
    onSubmit: async ({ email }) => {
      try {
        setLoading(true);

        await validateUserEmail({ email });
        await onUserPasswordReset(email);

        setLoading(false);
        setNotificationSuccess("Email de redefinição de senha enviado com sucesso. Lembre-se de procurar o email no spam!");
      } catch (err) {
        setLoading(false);
        handleError(err, setNotificationError);
      }
    },
    enableReinitialize: true
  });

  return (
    <form onSubmit={formik.handleSubmit} className={style.form}>
      <p style={{ color: "#FFFFFF" }}>Enviaremos um link e instruções de redefinição de senha para o e-mail informado</p>

      <EmailInput
        onChange={(value) => formik.setFieldValue("email", value)}
        color="primary"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: formik.handleChange
        }}
        required
      />

      <Button type="submit">
        { !loading ? "Enviar" : <CircularProgress color="primary" /> }
      </Button>
    </form>
  );
}

PasswordReset.propTypes = {
  onUserPasswordReset: PropTypes.func.isRequired
};

export default PasswordReset;
