import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Muted from "components/Typography/Muted";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircleOutline";
import styles from "../styles.module.css";
import { Advise } from "../styles";
import { CESPE_MAX_GRADE } from "../../../constants";

const MIN_ASPECTS = 2;
const MAX_ASPECTS = 4;

const DEFAULT_INITIAL_GRADES = {
  presentation: null,
  aspects: [null, null], // sempre teremos no mínimo dois aspectos, então iniciar eles
  errors: null,
  lines: null,
  final: null
};

const GradeJuridical = ({
  initialGrades = DEFAULT_INITIAL_GRADES,
  setGrades,
  readOnly,
  gramaticalOnly,
  juridicalOnly
}) => {
  const MAX_GRADE = CESPE_MAX_GRADE;

  const PRESENTATION_PERCENTAGE = 0.1;
  const ASPECTS_PERCENTAGE = 0.9;

  const MAX_PRESENTATION_GRADE = MAX_GRADE * PRESENTATION_PERCENTAGE;
  const MAX_ASPECTS_GRADE = MAX_GRADE * ASPECTS_PERCENTAGE;

  // Notas de conteúdo
  const [presentation, setPresentation] = useState(initialGrades.presentation);
  const [aspects, setAspects] = useState(initialGrades.aspects);
  const [grade, setGrade] = useState(null);

  // Erros do aluno
  const [errors, setErrors] = useState(initialGrades.errors);

  // Linhas escritas na redação
  const [lines, setLines] = useState(initialGrades.lines);

  // Se ambos forem falso, redação recebe apenas uma correção, logo desbloqueia todos os campos
  if (!gramaticalOnly && !juridicalOnly) {
    gramaticalOnly = false;
    juridicalOnly = false;
  }

  // Nota final
  const [finalGrade, setFinalGrade] = useState(null);

  useEffect(() => {
    setPresentation(initialGrades.presentation);
    setAspects(initialGrades.aspects);
    setErrors(initialGrades.errors);
    setLines(initialGrades.lines);
  }, [initialGrades]);

  useEffect(() => {
    let g = 0;
    if (presentation) g += presentation;
    // para notas CESPE, teremos um máximo de 4 aspectos
    if (aspects[0]) g += aspects[0];
    if (aspects[1]) g += aspects[1];
    if (aspects[2]) g += aspects[2];
    if (aspects[3]) g += aspects[3];

    setGrade(parseFloat(g, 10).toFixed(2));

    // verificar se todos os aspects estão setados, se for zero conta como true
    // pois está setado, mas possui valor absoluto zero
    const allSet = aspects.reduce(
      (acc, el) => acc && (el === 0 ? true : el),
      true
    );

    // se todas as notas tiverem sido dadas, calcular nota final
    if (
      (presentation === 0 ? true : presentation) &&
      allSet &&
      (errors === 0 ? true : errors) &&
      lines
    ) {
      const fg = (grade - 200 * (errors / lines)).toFixed(2);

      const parsedGrade = parseFloat(fg, 10);

      setFinalGrade(parsedGrade < 0 ? 0 : parsedGrade);
    } else {
      setFinalGrade(0);
    }

    setGrades({ presentation, aspects, errors, lines, final: finalGrade });
  }, [presentation, aspects, errors, lines, grade, setGrades, finalGrade]);

  const onAddAspect = () => {
    const newAspects = [...aspects];

    newAspects.push(0);

    setAspects(newAspects);
  };

  const onRemoveAspect = () => {
    const newAspects = [...aspects];

    newAspects.pop();

    setAspects(newAspects);
  };

  //! AQUI!
  {
    return (
      <div className={styles.gradeWrapper}>
        <Muted>
          <b>Nota dos conteúdos</b>
        </Muted>

        <br />

        <GridContainer>
          <GridItem style={{ display: "flex" }}>
            <TextField
              disabled={readOnly || gramaticalOnly}
              label={<Muted>Apresentação</Muted>}
              variant="outlined"
              size="small"
              type="number"
              value={presentation}
              error={presentation > MAX_PRESENTATION_GRADE}
              helperText={
                presentation > MAX_PRESENTATION_GRADE
                  ? `Máximo: ${MAX_PRESENTATION_GRADE}`
                  : ""
              }
              onChange={(event) => {
                event.preventDefault();

                if (event.target.value && !isNaN(event.target.value)) {
                  const parsedGrade = parseFloat(event.target.value, 10);

                  if (parsedGrade >= 0) return setPresentation(parsedGrade);
                }

                return setPresentation(0);
              }}
              inputLabel={{
                fontSize: 12,
                color: "#F49913",
                borderColor: "#F49913",
                fontAlign: "right"
              }}
              inputProps={{ type: "number", max: 2 }}
              style={{
                width: "110px",
                fontSize: 12,
                borderColor: "#F49913",
                fontAlign: "right"
              }}
            />

            <Muted style={{ paddingTop: "10px", paddingLeft: "5px" }}>
              / {MAX_PRESENTATION_GRADE}
            </Muted>
          </GridItem>

          {aspects.map((el, index) => (
            <GridItem key={index} style={{ display: "flex" }}>
              <TextField
                disabled={readOnly || juridicalOnly}
                label={<Muted>Aspecto 2.{index + 1}</Muted>}
                variant="outlined"
                size="small"
                type="number"
                value={aspects[index]}
                error={aspects[index] > MAX_ASPECTS_GRADE / aspects.length}
                helperText={
                  aspects[index] > MAX_ASPECTS_GRADE / aspects.length
                    ? `Máximo: ${MAX_ASPECTS_GRADE / aspects.length}`
                    : ""
                }
                onChange={(e) => {
                  e.preventDefault();
                  let newAspects = [...aspects];
                  // check input conditions
                  if (e.target.value && !isNaN(e.target.value)) {
                    newAspects[index] = parseFloat(e.target.value, 10);
                  } else {
                    newAspects[index] = 0;
                  }
                  setAspects(newAspects);
                }}
                style={{ width: "100px", fontSize: 12, borderColor: "#F49913" }}
                inputProps={{ type: "number", max: 9 }}
              />

              <Muted style={{ paddingTop: "10px", paddingLeft: "5px" }}>
                / {MAX_ASPECTS_GRADE / aspects.length}
              </Muted>
            </GridItem>
          ))}

          {!readOnly && (
            <GridItem style={{ display: "flex" }}>
              {aspects.length === MAX_ASPECTS ? (
                <IconButton
                  color="warning"
                  component="span"
                  size="small"
                  onClick={onRemoveAspect}>
                  <RemoveCircleIcon />
                </IconButton>
              ) : aspects.length === MIN_ASPECTS ? (
                <div>
                  <IconButton
                    color="warning"
                    component="span"
                    size="small"
                    onClick={onAddAspect}>
                    <AddCircleIcon />
                  </IconButton>
                </div>
              ) : (
                <div>
                  <IconButton
                    color="warning"
                    component="span"
                    size="small"
                    onClick={onRemoveAspect}>
                    <RemoveCircleIcon />
                  </IconButton>
                  <IconButton
                    color="warning"
                    component="span"
                    size="small"
                    onClick={onAddAspect}>
                    <AddCircleIcon />
                  </IconButton>
                </div>
              )}
            </GridItem>
          )}

          <GridItem style={{ display: "flex", position: "relative" }}>
            <Muted style={{ paddingTop: "10px", paddingLeft: "5px" }}>
              {" "}
              ={" "}
            </Muted>

            <Muted
              style={{
                paddingTop: "5px",
                paddingLeft: "25px",
                color: "#f49913",
                fontSize: 20
              }}>
              {grade}
            </Muted>

            <Muted style={{ paddingTop: "10px", paddingLeft: "10px" }}>
              / {MAX_GRADE}
            </Muted>
          </GridItem>
        </GridContainer>

        <br />

        <Muted>
          <b>Outros</b>
        </Muted>

        <br />

        <GridContainer>
          <GridItem style={{ display: "flex" }}>
            <TextField
              disabled={readOnly || gramaticalOnly}
              label={<Muted>nº de erros</Muted>}
              variant="outlined"
              size="small"
              type="number"
              value={errors}
              onChange={(event) => {
                event.preventDefault();
                const { target } = event;
                let ers = 0;

                if (target.value && !isNaN(target.value)) {
                  ers = parseFloat(event.target.value, 10);
                }
                if (ers >= 0) setErrors(ers);
              }}
              style={{ width: "95px", fontSize: 12, borderColor: "#F49913" }}
            />
          </GridItem>

          <GridItem style={{ display: "flex", marginLeft: "55px" }}>
            <TextField
              disabled={readOnly || gramaticalOnly}
              label={<Muted>nº de linhas</Muted>}
              variant="outlined"
              size="small"
              type="number"
              value={lines}
              onChange={(e) => {
                e.preventDefault();

                let ls = 0;

                if (e.target.value && !isNaN(e.target.value)) {
                  ls = parseFloat(e.target.value, 10);
                }

                if (ls >= 0) setLines(ls);
              }}
              style={{ width: "95px", fontSize: 12, borderColor: "#F49913" }}
            />
          </GridItem>
        </GridContainer>

        <br />

        <Muted>
          <b>Nota final</b>
        </Muted>

        <br />

        <GridContainer>
          <GridItem style={{ display: "flex" }}>
            <h5>NF = Nota - 200 x Erros/Linhas = </h5>

            <Muted
              style={{ paddingLeft: "25px", color: "#f49913", fontSize: 25 }}>
              <b>{finalGrade || 0}</b>
            </Muted>
          </GridItem>

          <GridItem>
            <Advise>
              1. IMPORTANTE: por padrão, as notas estão unificadas entre 0 a
              1000 pontos. Caso o seu concurso tenha uma pontuação diferente,
              não se preocupe. Basta verificar a porcentagem de aproveitamento.
              EXEMPLO: se a nota foi 200 de 1000, o aproveitamento foi de apenas
              20%; se foi 800 de 1000, o aproveitamento foi de 80%.
            </Advise>
            <Advise>
              2. IMPORTANTE: por mais que os critérios de correção da sua banca
              tenham nomenclaturas distintas das aplicadas aqui, os conceitos
              usados nas nossas avaliações são idênticos aos que serão
              utilizados pelo avaliador do seu concurso. São estruturas
              universais, mas, por vezes, com nomes distintos.
            </Advise>
            <Advise>
              3. IMPORTANTE: mais importante do que a nota é a qualidade da sua
              correção e a visualização dos seus pontos fracos e fortes para
              posterior melhoria.
            </Advise>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
};

export default GradeJuridical;
