import React from "react";
import AdminDashboard from "views/Dashboard/Admin";
import StudentDashboard from "views/Dashboard/Student";
import TeacherDashboard from "views/Dashboard/Teacher";
import Login from "views/Auth/Login";
import {
  STUDENT_MODE,
  TEACHER_MODE,
  ADMIN_MODE,
  JURIDICAL_MODE
} from "constants.js";

const DashboardPage = (mode) => () => {
  switch (mode) {
    case ADMIN_MODE:
      return <AdminDashboard />;

    case STUDENT_MODE:
      return <StudentDashboard />;

    case TEACHER_MODE:
      return <TeacherDashboard />;

    case JURIDICAL_MODE:
      return <TeacherDashboard />;

    default:
      return <Login />;
  }
};

export default DashboardPage;
