import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  position: fixed;

  width: 100vw;
  height: 100vh;

  z-index: 20;

  top: 0;
  left: 0;

  padding: 1.5rem;


  ${({isOpen}) => !isOpen && css`
    width: 50px;
    height: 50px;

    padding: 0;

    top: unset;
    left: 1rem;
    bottom: 0.6rem;
  `}
`;

export const Background = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  position: fixed;

  width: 100vw;
  height: 100vh;

  background: black;

  opacity: 0.3;

  ${({isOpen}) => !isOpen && css`
    pointer-events: none;
    opacity: 0;
  `}
`;

export const Content = styled.div`
  width: 100%;
  max-width: 600px;

  background: white;

  border-radius: 0.8rem;

  z-index: 21;

  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

  font-size: 1.2em;
  font-weight: 400;

  text-justify: justify;
  text-align: justify;

  position: relative;
  img {
    max-width: 100%;
  }

  ${({isOpen}) => !isOpen && css`
    display: none;
  `}

`;

export const TextArea = styled.div`
  padding: 20px;
  overflow-y: scroll;
  height: 42vh;

  transition: 0.3s ease;

  ${({isOpen}) => !isOpen && css`
    display: none;
  `};
`;

export const Header = styled.div`
  position: absolute;
  right: 25px;
  top: 20px;

  h4 {
    line-height: 13px;
    font-weight: bold;

    padding-bottom: 1rem;
  }

  svg {
    cursor: pointer;
  }
`;
