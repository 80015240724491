import React from "react";
import Resizer from "react-image-file-resizer";
import Rotate90DegreesCcwIcon from "@material-ui/icons/Rotate90DegreesCcw";
import IconButton from "@material-ui/core/IconButton";
import { imageProps } from "./helpers";
import GridContainer from "components/Grid/GridContainer";

export const editImage = (file, onChange, rotation = 0) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      imageProps.maxWidth,
      imageProps.maxHeight,
      "jpeg",
      imageProps.quality,
      rotation,
      (file) => {
        onChange(file);
        resolve(file);
      },
      imageProps.outputType
    );
  });
const ImageEditor = ({ file, onEdit }) => {
  function handleEditImage({ rotation }) {
    editImage(file, onEdit, rotation);
  }
  return (
    <GridContainer justify="center">
      <IconButton
        aria-label="Rotacionar 90°"
        onClick={() => handleEditImage({ rotation: 90 })}>
        <Rotate90DegreesCcwIcon />
        <span style={{ fontSize: 16 }}>
          Rotacionar imagem (se necessário)
        </span>
      </IconButton>
    </GridContainer>
  );
};

export default ImageEditor;
