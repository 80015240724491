import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  height: 60vh;
  width: 100%;
`;

export const Content = styled.div`
  font-size: 20px;
  line-height: 20px;
  text-align: center;

  display: flex;
  flex-direction: column;

  align-items: center;

  gap: 20px;

  padding: 50px;

  border-radius: 15px;
  border: 2px #00acc1 solid;

  position: relative;
`;

export const Link = styled.a`
  cursor: pointer;

  margin-left: 20px;

  background: white;

  border: 2px #00acc1 solid;
  border-radius: 5px;

  color: #00acc1;
  text-decoration: none;

  padding: 5px;

  transition: 0.4s background;

  :hover {
    color: white;
    background: #00acc1;
  }
`;

export const IconContainer = styled.div`
  position: absolute;

  left: 10px;
  top: 10px;

  svg {
    color: #00acc1;
  }
`
