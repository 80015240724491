import React from "react";

import Table from "components/Table/Table.js";
import { CardHeader } from "components/Card";
import GridItem from "components/Grid/GridItem";
import { Assignment } from "@material-ui/icons";
import { CardIcon } from "components/Card";
import { CardBody } from "components/Card";
import { Card } from "components/Card";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { makeStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";

const useStyles = makeStyles(styles);

function UserEssaysList({ data, columns, noDataText }) {
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Controle de Correções</h4>
          </CardHeader>
          <CardBody>
            {data.length > 0 ? (
              <Table
                tableHead={columns}
                tableData={data}
                customCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right
                ]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right
                ]}
                customHeadClassesForCells={[0, 4, 5]}
              />
            ) : (
              <div>{noDataText}</div>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default UserEssaysList;
