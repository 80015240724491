import * as api from "lib/api";
import * as act from "./types";

export const onUsers = () => async (dispatch) => {
  try {
    const response = await api.users();
    return dispatch(act.users(response.data.users));
  } catch (err) {
    throw err.response;
  }
};

export const onUserDelete = (id) => async (dispatch) => {
  try {
    const response = await api.userDelete(id);
    return dispatch(act.userDelete(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onUserUpdate = (id, name, email, password) => async (dispatch) => {
  try {
    const response = await api.userUpdate(id, name, email, password);
    return dispatch(act.userSelfUpdate(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onAdminUserUpdate = (
  id,
  name,
  email,
  password,
  type,
  credits,
  specialClass,
  ignoreWeeklyCreditsExpiration,
  creditsExpiresAt
) => async (dispatch) => {
  try {
    const response = await api.adminUserUpdate(
      id,
      name,
      email,
      password,
      type,
      credits,
      specialClass,
      ignoreWeeklyCreditsExpiration,
      creditsExpiresAt
    );
    return dispatch(act.userUpdate(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onUserPasswordChange = (userid, password, newpassword) => async (
  dispatch
) => {
  try {
    const response = await api.userPasswordChange(
      userid,
      password,
      newpassword
    );
    return dispatch(act.userUpdate(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onAdminUserPasswordChange = (userid, password) => async (
  dispatch
) => {
  try {
    const response = await api.adminUserPasswordChange(userid, password);
    return dispatch(act.userUpdate(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onFetchUserAdminStats = () => async (dispatch) => {
  try {
    const response = await api.userAdminStats();

    return dispatch(act.userAdminStats(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onFetchUserDetails = (userid) => async (dispatch) => {
  try {
    const response = await api.userDetails(userid);
    dispatch(act.essaysByUserId({ userid, essays: response.data.essays }));
    return dispatch(act.userUpdate(response.data.user));
  } catch (err) {
    throw err.response;
  }
};
