import {
  REVIEW_COMMENT,
  REVIEW_GRAM_ERROR,
  REVIEW_WRITING_CONVENTION,
  REVIEW_VOC_ACCURACY,
  REVIEW_REG_CHOICE,
  REVIEW_CONNECTIVES,
  REVIEW_SYNTAX
} from "constants.js";
import isEqual from "lodash/isEqual";
import get from "lodash/fp/get";

const DEFAULT_SHAPE = "rect";

// TODO: get title info from api, not from color scheme
// TODO: get colors from theme provider
export const mapKindToColorScheme = {
  [REVIEW_COMMENT]: {
    kind: REVIEW_COMMENT,
    color: "#2196f3",
    title: "Comentário",
    label: "Cm"
  },
  [REVIEW_GRAM_ERROR]: {
    kind: REVIEW_GRAM_ERROR,
    color: "#ff4081",
    title: "Erro Gramatical",
    label: "EG"
  },
  [REVIEW_WRITING_CONVENTION]: {
    kind: REVIEW_WRITING_CONVENTION,
    color: "#d50000",
    title: "Convenção de Escrita",
    label: "CE"
  },
  [REVIEW_VOC_ACCURACY]: {
    kind: REVIEW_VOC_ACCURACY,
    color: "#1de9b6",
    title: "Precisão Vocabular",
    label: "PV"
  },
  [REVIEW_REG_CHOICE]: {
    kind: REVIEW_REG_CHOICE,
    color: "#c4ba00",
    title: "Escolha de Registro",
    label: "ER"
  },
  [REVIEW_CONNECTIVES]: {
    kind: REVIEW_CONNECTIVES,
    color: "#9c27b0",
    title: "Conectivos",
    label: "Cn"
  },
  [REVIEW_SYNTAX]: {
    kind: REVIEW_SYNTAX,
    color: "#ff5722",
    title: "Sintaxe",
    label: "S"
  }
};

/**
 * getReviewColorFromKind returns the color scheme for given kind
 * @param {number} kind
 */
export function getReviewColorFromKind(kind) {
  return get(kind)(mapKindToColorScheme);
}

/**
 * getCoordinatesFromEvent returns the [x,y] coordinates from mouse event
 * @param { Event} event
 * @return { Array } The [x, y] coordinate
 */
export function getCoordinatesFromEvent(event) {
  return [event.nativeEvent.layerX, event.nativeEvent.layerY];
}

/**
 * getAreaFromCoords returns the area shape from given coords
 * @param {Array} coords
 * @param {number} kind
 * @return {Object} The area map object
 */
export function getAreaFromCoords(coords, kind = 0, id) {
  const review = getReviewColorFromKind(kind);
  if (!review) return {};

  return {
    _id: id,
    shape: DEFAULT_SHAPE,
    coords,
    lineWidth: 2,
    preFillColor: `${review.color}33`,
    strokeColor: review.color
  };
}

/**
 * getImageMap returns the map image based on area coords
 * @param {Array} markerCoords Current marker coords
 * @param {Array} areas markedAreas
 * @param {number} kind review kind
 */
export function getImageMap(markerCoords = [], reviews = [], kind = 0) {
  return markerCoords.length
    ? {
        name: "reviews",
        areas: [...reviews, getAreaFromCoords(markerCoords, kind, "marker")]
      }
    : {
        name: reviews.length ? "reviews" : "blank",
        areas: reviews
      };
}

/**
 * getCurrentReviewByCoords returns the current review props located
 *      on given coords
 * @param {Array} reviews
 * @param {Array} coords
 */
export function getCurrentReviewByCoords(reviews, coords) {
  return reviews.find((rp) => isEqual(rp.area.coords, coords));
}

/**
 * updateReviewPropById returns the updated reviewProps
 *      array with newProps for given id
 * @param {Array} reviewsProps
 * @param {number} id
 * @param {Object} newProps
 */
export function updateReviewPropById(reviewsProps, id, newProps) {
  return reviewsProps.map((props) =>
    props.reviewId === id ? { ...props, ...newProps } : props
  );
}

/**
 * getReviewAreas returns the areas for given reviews
 * @param {Array} reviews
 */
export function getReviewsAreas(reviews = []) {
  return reviews.map(({ area }) => area);
}

/**
 * getAliasFromString returns the capital letters of given string
 * @param {String} str
 */
export function getAliasFromString(str) {
  let newStr = str.match(/[A-Z]/g).join("");
  if (newStr.length === 1 && str.length > 1) {
    newStr = `${newStr}${str[1]}`;
  }
  return newStr;
}
