import React from "react";
import { useLocation } from "react-router-dom";
import { usePasswordReset } from "./hooks";

//? Components....
import EmailInput from "components/LoginInputs/EmailInput";
import ResetPassword from "components/User/PasswordReset";
import ConfirmResetPassword from "components/User/PasswordResetConfirm";

//? Stylesheets...
import * as S from "./reset-password-styles";
import styles from "./styles.module.css";

//? Images...
import ariLogo from "../../assets/img/minhadiscursiva.jpeg";

//? Icons...
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";  //! Usado nos logos das redes sociais!
import { faFacebook, faTwitter, faYoutube, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";

const PasswordReset = () => {
  const { onUserPasswordReset, onUserConfirmPasswordReset } = usePasswordReset();
  const location = useLocation();
  const token = location.search.split("=")[1];

  return (
    <S.Container>
      <S.SocialMediaContainer>
        <S.SocialMediaText>OBRABODAREDACAO</S.SocialMediaText>

        <S.SocialMediaIcons>
          <FontAwesomeIcon icon={faFacebook} size="lg" color={"#ff8000"} />
          <FontAwesomeIcon icon={faTwitter} size="lg" color={"#ff8000"} />
          <FontAwesomeIcon icon={faYoutube} size="lg" color={"#ff8000"} />
          <FontAwesomeIcon icon={faInstagram} size="lg" color={"#ff8000"} />
        </S.SocialMediaIcons>
      </S.SocialMediaContainer>

      <S.MainContent>
        <S.FormContainer>
          <S.LogosContainer>
            <S.Logo src={require("../../assets/img/login/logo.png")} />
          </S.LogosContainer>

          {token ? (
            <ConfirmResetPassword
              onConfirm={onUserConfirmPasswordReset}
              token={token}
            />
          ) : (
            <ResetPassword onUserPasswordReset={onUserPasswordReset} />
          )}

          <S.ButtonContainer>
          </S.ButtonContainer>
        </S.FormContainer>
      </S.MainContent>
    </S.Container>
  );
}

export default PasswordReset;
