import { createReducer } from "@reduxjs/toolkit";
import set from "lodash/fp/set";
import update from "lodash/fp/update";
import * as act from "../actions/types";

const DEFAULT_STATE = {
  usersById: {},
  userAdminStats: {}
};

const users = createReducer(DEFAULT_STATE, {
  [act.users.type]: (state, action) => {
    const users = action.payload;
    const usersById = users.reduce(
      (acc, u) => ({
        [u._id]: u,
        ...acc
      }),
      {}
    );
    return set("usersById", usersById)(state);
  },
  [act.register.type]: (state, action) =>
    set(["usersById", action.payload._id], action.payload)(state),
  [act.userUpdate.type]: (state, action) => {
    const { _id, name, email, type, updatedAt, avgRating } = action.payload;
    return update(["usersById", _id], (user) => ({
      ...user,
      name,
      email,
      type,
      updatedAt,
      avgRating
    }))(state);
  },
  [act.userSelfUpdate.type]: (state, action) => {
    const { _id, name, email, type, updatedAt, avgRating } = action.payload;
    return update(["usersById", _id], (user) => ({
      ...user,
      name,
      email,
      type,
      updatedAt,
      avgRating
    }))(state);
  },
  [act.userDelete.type]: (state, action) => {
    const { _id } = action.payload;
    delete state.usersById[_id];
    return state;
  },
  [act.userAdminStats.type]: (state, action) => {
    const userAdminStats = action.payload;
    return set("userAdminStats", userAdminStats)(state);
  }
});

export default users;
