import React, { useEffect, useState } from "react";
import EssaysList from "views/Essays/List";
import { useEssays } from "views/Essays/hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer";
import { http } from "lib/api";
import { useEssaysContext } from "hooks/essays";

// TODO: use custom hooks for teacher essays; pensar sobre isso.
// mostrar todas as redações pros corretores, ou só as q ele ta corrigindo
// ou disponiveis para correçao
export default function EssaysPage() {
  const [filteredEssays, setFilteredEssays] = useState([]);
  const {
    currentPage,
    pageParams,
    setPageParams,
    queryFilters,
    setEssaysLoading,
    setQueryFilters,
    setCurrentPage
  } = useEssaysContext();

  const { loading } = useEssays();

  async function getEssayList() {
    setEssaysLoading(true);
    const { data } = await http.get(`/essays/metadata`, {
      params: {
        currentPage,
        ...pageParams,
        ...queryFilters
      }
    });

    setFilteredEssays(data.essays);
    setPageParams({
      pageSize: pageParams.pageSize,
      totalPages: data.totalPages
    });
    setEssaysLoading(false);
  }

  useEffect(() => {
    setQueryFilters({});
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    getEssayList();
  }, [currentPage, pageParams.pageSize, queryFilters]);

  return !loading ? (
    <EssaysList essays={filteredEssays} />
  ) : (
    <GridContainer justify="center" style={{ marginTop: "10%" }}>
      <CircularProgress size={24} />
    </GridContainer>
  );
}
