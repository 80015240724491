import React, { useState } from "react";

//? Icons...
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

//? Stylesheets...
import * as S from "./password.style";

const PasswordInput = ({onChange}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <S.Container>
      <S.InputWrapper>
        <S.IconArea>
          {
            isPasswordVisible ? ( //* Se a senha estiver visível, queremos abrir o cadeado.
              <FontAwesomeIcon icon={faLockOpen} size="lg" />
            ) : ( //* Se a senha estiver invisível, queremos fechar o cadeado.
              <FontAwesomeIcon icon={faLock} size="lg" />
            )
          }
        </S.IconArea>

        <S.Input
          placeholder="Senha"
          name="password"
          type={isPasswordVisible ? "text" : "password"}
          onChange={(value)=> onChange(value.target.value)}
        />

        <S.ButtonContainer>
          <S.ButtonVisiblePwd type="button" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
            {
              isPasswordVisible ? ( //* Se a senha estiver visível, queremos escondê-la.
                <FontAwesomeIcon icon={faEyeSlash} size="lg" color={"#444444"} />
              ) : ( //* Se a senha estiver invisível, queremos mostrar-la.
                <FontAwesomeIcon icon={faEye} size="lg" color={"#444444"} />
              )
            }
          </S.ButtonVisiblePwd>
        </S.ButtonContainer>
      </S.InputWrapper>
    </S.Container>
  );
}

export default PasswordInput;