import React, { useState, useMemo } from "react";
import { useHistory, Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { http } from "lib/api";
import { useEssaysContext } from "hooks/essays";
import {
  CardHeader,
  CardIcon,
  Card,
  CardBody,
  CardTitle,
  CardAvatar
} from "components/Card";
import {
  PermIdentity,
  BorderColor,
  Assignment,
  Check,
  VisibilityOff,
  StarRate
} from "@material-ui/icons";
import { useUserDetails } from "./hooks";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactTable from "components/ReactTable/ReactTable.js";
import {
  dateFormat,
  getIconButtonStyle,
  getStatusIcon
} from "views/Essays/helpers.js";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button/Button";
import useNotification from "utils/hooks/useNotification";
import {
  REVIEWS_STATUS_LABELS,
  REVIEW_STATUS_DONE,
  REVIEW_STATUS_IN_PROGRESS,
  REVIEW_STATUS_AVAILABLE,
  REVIEW_STATUS_INELIGIBLE,
  REVIEW_STATUS_PARTIAL_CORRECTION,
  REVIEW_STATUS_IN_PROGRESS_JURIDICAL,
  USER_MODE_LABELS,
  STUDENT_MODE
} from "constants.js";
import ModalConfirm from "components/Modal/ModalConfirm";
import ResetPasswordModal from "./Modals/ResetPasswordModal";
import styles from "./styles.module.css";
import { Box, Typography } from "@material-ui/core";

const getTableColumns = (userType) => [
  { Header: "Envio", accessor: "date" },
  { Header: "Correção", accessor: "datereview" },
  userType !== STUDENT_MODE
    ? { Header: "Aluno", accessor: "student" }
    : { Header: "Corretor", accessor: "reviewer" },
  { Header: "Status", accessor: "status" },
  { Header: "Ações", accessor: "actions" }
];

const UserResetPassword = ({ onReset }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <>
      <ResetPasswordModal
        open={show}
        handleClose={handleClose}
        onReset={onReset}
      />
      <Button
        variant="contained"
        disableElevation
        onClick={() => setShow(true)}>
        Redefinir Senha
      </Button>
    </>
  );
};

function formatDataToTable(
  data,
  handleStatusChange,
  handleChangeToAvailable,
  loading,
  platform
) {
  return data.map((d) => ({
    contest: d.contest?.name,
    date: dateFormat(new Date(d.createdAt)),
    datereview: d.review ? dateFormat(new Date(d.review.updatedAt)) : "",
    reviewer: `${d.review?.reviewer?.name}${
      d.review?.juridical_reviewer?.name
        ? "/" + d.review?.juridical_reviewer?.name
        : ""
    }`,
    student: d.user?.name,
    status: (
      <div>
        <Tooltip title={REVIEWS_STATUS_LABELS[d.status]} aria-label="review">
          <IconButton style={getIconButtonStyle(d.status)} variant="outlined">
            {getStatusIcon(d.status)}
          </IconButton>
        </Tooltip>
        <Link to={`/${platform}/redacoes/detalhes/${d._id}`}>Ver</Link>
      </div>
    ),
    // only show actions if there is an active review for that essay
    actions: d.review ? (
      loading ? (
        <GridContainer justify="center">
          <CircularProgress />
        </GridContainer>
      ) : (
        <div className={styles.buttonGroup}>
          {/* TODO: marcar como disponivel precisa retirar a redacao do usuario */}
          <Tooltip title={`Marcar como "disponível"`} aria-label="review">
            <IconButton
              onClick={() =>
                handleChangeToAvailable(d._id, REVIEW_STATUS_AVAILABLE)
              }
              style={{ backgroundColor: "#F49913" }}>
              <Assignment />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Marcar como "em correção"`} aria-label="review">
            <IconButton
              onClick={() =>
                handleStatusChange(d._id, REVIEW_STATUS_IN_PROGRESS)
              }
              style={{ backgroundColor: "#9C27B0" }}>
              <BorderColor />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Marcar como "corrigida"`} aria-label="review">
            <IconButton
              onClick={() => handleStatusChange(d._id, REVIEW_STATUS_DONE)}
              style={{ backgroundColor: "#4ABDD1" }}>
              <Check />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Marcar como "ilegível"`} aria-label="review">
            <IconButton
              onClick={() =>
                handleStatusChange(d._id, REVIEW_STATUS_INELIGIBLE)
              }
              style={{ backgroundColor: "#676767" }}>
              <VisibilityOff />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={`Marcar como "Corrigida Parcialmente"`}
            aria-label="review">
            <IconButton
              onClick={() =>
                handleChangeToAvailable(d._id, REVIEW_STATUS_PARTIAL_CORRECTION)
              }
              style={{ backgroundColor: "#F49913" }}>
              <Assignment />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={`Marcar como "em correção Jurídica"`}
            aria-label="review">
            <IconButton
              onClick={() =>
                handleStatusChange(d._id, REVIEW_STATUS_IN_PROGRESS_JURIDICAL)
              }
              style={{ backgroundColor: "#9C27B0" }}>
              <BorderColor />
            </IconButton>
          </Tooltip>
        </div>
      )
    ) : null
  }));
}

const User = ({ userid }) => {
  const {
    user,
    loading,
    onStatusChange,
    onFetchUserDetails,
    onUpdateUserPassword
  } = useUserDetails(userid);

  const [filteredEssays, setFilteredEssays] = useState([]);
  const {
    currentPage,
    pageParams,
    setPageParams,
    queryFilters,
    setEssaysLoading,
    setQueryFilters,
    setCurrentPage,
    setEssaysToDownload
  } = useEssaysContext();

  async function getEssayList() {
    setEssaysLoading(true);
    const { data } = await http.get(`essay/user/${userid}`, {
      params: {
        currentPage,
        ...pageParams,
        ...queryFilters
      }
    });

    setFilteredEssays(data.essays);
    setEssaysToDownload(data.essaysToDownload);
    setPageParams({
      ...pageParams,
      totalPages: data.totalPages
    });
    setEssaysLoading(false);
  }
  React.useEffect(() => {
    setQueryFilters({});
    setCurrentPage(1);
  }, []);

  React.useEffect(() => {
    getEssayList();
  }, [currentPage, pageParams.pageSize, queryFilters]);

  const history = useHistory();
  const platform = useMemo(() => history.location.pathname.split("/")[1], [
    history.location.pathname
  ]);
  const [localLoading, setLocalLoading] = useState(false);
  const [requestData, setRequestData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const { setNotificationSuccess, setNotificationError } = useNotification();

  const handleStatusChange = async (id, status) => {
    try {
      setLocalLoading(true);
      await onStatusChange(id, status);
      setNotificationSuccess("Status modificado com sucesso");
      if (status === REVIEW_STATUS_AVAILABLE) {
        setOpenModal(false);
        onFetchUserDetails(userid);
      }
      setLocalLoading(false);
    } catch (err) {
      setNotificationError(err.data.ErrorMessage);
      setLocalLoading(false);
      throw err;
    }
  };

  const handleChangeToAvailable = async (id, status) => {
    setOpenModal(true);
    setRequestData({ id, status });
  };

  return !loading ? (
    <GridContainer>
      <ModalConfirm
        show={openModal}
        onConfirm={() => handleStatusChange(requestData.id, requestData.status)}
        body="Você tem certeza que quer deletar a correção dessa redação?"
        onClose={() => setOpenModal(false)}
      />
      <GridItem xs={12}>
        <Card profile>
          <CardAvatar profile>
            <Link to="/" onClick={(e) => e.preventDefault()}>
              <img
                src={`https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${user.name}&size=256`}
                alt="avatar"
              />
            </Link>
          </CardAvatar>
          <CardBody profile>
            <h6>{USER_MODE_LABELS[user.type]}</h6>
            <Box
              sx={{
                marginBottom: "10px"
              }}>
              <Typography variant="h6">{user.name}</Typography>
              {user.type === "teacher" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "center"
                  }}>
                  <Typography variant="body2">
                    {user.avgRating.toFixed(2)}
                  </Typography>
                  <StarRate />
                </Box>
              )}
            </Box>
            <p>{user.email}</p>
            <UserResetPassword onReset={onUpdateUserPassword} />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <PermIdentity />
            </CardIcon>
            <CardTitle title="Redações do Usuário" />
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={getTableColumns(user.type)}
              data={formatDataToTable(
                filteredEssays,
                handleStatusChange,
                handleChangeToAvailable,
                localLoading,
                platform
              )}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  ) : (
    <GridContainer justify="center">
      <CircularProgress />
    </GridContainer>
  );
};

export default User;
