import React from "react";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import ChartistGraph from "react-chartist";
import last from "lodash/last";

// TODO: Use own styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

const ReviewChartCard = ({
  chart,
  title,
  description,
  underText = "Hoje",
  chartType = "Line",
  color = "info",
  footerBool = true,
  lastOccurence = true,
}) => {
  const classes = useStyles();
  return (
    <Card chart className={classes.cardHover}>
      <CardHeader color={color} className={classes.cardHeaderHover}>
        <ChartistGraph
          className="ct-chart-white-colors ct-label"
          data={chart.data}
          type={chartType}
          options={chart.options}
          listener={chart.animation}
        />
      </CardHeader>
      <CardBody>
        <div className={classes.cardHoverUnder}></div>
        <h4 className={classes.cardTitle}>{title}</h4>
        <p className={classes.cardCategory}>{description}</p>
      </CardBody>
      {footerBool && (
        <CardFooter chart>
          {lastOccurence ? (
            <div className={classes.stats}>
              {last(chart.data.series[0])} {underText}
            </div>
          ) : (
            <div className={classes.stats}>{underText}</div>
          )}
        </CardFooter>
      )}
    </Card>
  );
};
export default ReviewChartCard;
