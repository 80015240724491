import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import Icon from "@material-ui/core/Icon";
import GridContainer from "components/Grid/GridContainer.js";
import { essaysHistory } from "variables/charts";
import { useHistory } from "react-router-dom";
import ReviewChartCard from "./ReviewChartCard";
import ReviewStatsCard from "./ReviewStatsCard";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card";
import Muted from "components/Typography/Muted.js";
import { useEssaysByAuthUser } from "./hooks";
import {
  getTotalReviewed,
  getDataTableStudent,
  getDataChartStudent
} from "./helpers";
import style from "./style.module.css";
import { CardHeader } from "components/Card";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import UserEssaysList from "components/UserEssaysList/UserEssaysList";
import { useAuthUser } from "utils/hooks";
import * as S from "./styles";
import GenericModal from "components/GenericModal";
import moment from "moment";
import { useCallback } from "react";
import hasPermission from "utils/hasPermissions";
import CreditsBanner from "components/CreditsBanner";

const useStyles = makeStyles(styles);

const StudentDashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuthUser();
  const { essays } = useEssaysByAuthUser();
  const { reviewed, remaining } = getTotalReviewed(essays);

  const [openModal, setOpenModal] = useState(false);

  const reviewDone = (essayId) => (
    <div className={style.buttons}>
      <Button
        round
        color="info"
        size="sm"
        onClick={() => {
          history.push(`/aluno/redacoes/detalhes/${essayId}`);
        }}>
        Ver correção
      </Button>
    </div>
  );
  const reviewInProgress = (
    <div className={style.buttons}>
      <Button size="sm" round color="primary" disabled>
        Em correção
      </Button>
    </div>
  );
  const reviewNotAvailable = (
    <div className={style.buttons}>
      <Button size="sm" round color="warning" disabled>
        Correção pendente
      </Button>
    </div>
  );

  const reviewIneligible = (
    <div className={style.buttons}>
      <Button size="sm" round color="grey" disabled>
        Ilegível
      </Button>
    </div>
  );
  // TODO: Adicionar estado de "em correçao" na tabela, com a cor
  // primary roxa.
  const { dataTable } = getDataTableStudent(
    essays,
    reviewDone,
    reviewInProgress,
    reviewNotAvailable,
    reviewIneligible
  );
  const { dataChart } = getDataChartStudent(essays);
  const noDataText = (
    <div className={style.noDataText}>
      <Muted>Nenhuma redação enviada</Muted>
    </div>
  );

  const calcLastCreditExpiration = useCallback(() => {
    const { credits, creditsExpiresAt } = user;
    const date = moment(creditsExpiresAt).add(1 - credits, "weeks");

    return date.format("DD/MM/yyyy");
  }, [user]);

  return (
    <>
      {/* {!hasPermission(user.permissions, "Material", "read") && ( */}
      {/* <GenericModal isOpen={openModal} onClose={() => setOpenModal(false)} /> */}
      {/* )} */}
      <div>
        <CreditsBanner />
        <h3>Minhas Redações</h3>
        <br />
        <GridContainer justify="space-between">
          <ReviewStatsCard
            color="warning"
            deadlineStatus="Redações enviadas"
            amount={remaining}
            icon={<SendIcon />}
          />

          <ReviewStatsCard
            color="success"
            deadlineStatus="Redações corrigidas"
            amount={reviewed}
            icon={<Icon>check</Icon>}
          />

          <GridItem xs={12} md={2}>
            {!user.legacy && (
              <>
                <Card>
                  <CardHeader className={style.correctionsLeft}>
                    Correções restantes
                    {/* This conditional render of user credits will be removed once
                we update our test db users collection to contain this field with
                a default value of 0;
              */}
                    <div className={style.correctionsLeftSpam}>
                      {user.credits ? user.credits : "0"}
                    </div>
                  </CardHeader>
                </Card>

                {!hasPermission(user.permissions, "Material", "read") &&
                  user.credits > 0 && (
                    <S.ExpirationCard>
                      <S.ArrowUp />
                      <div>SEU ACESSO EXPIRA EM:</div>
                      <p>
                        {moment(user.creditsExpiresAt).format("DD/MM/yyyy")}
                      </p>

                      {!user.ignoreWeeklyCreditsExpiration && (
                        <>
                          <div>VALIDADE DO PRÓXIMO CRÉDITO:</div>
                          <p>Até {calcLastCreditExpiration()}</p>
                        </>
                      )}

                      <S.ClickText onClick={() => setOpenModal(true)}>
                        (Clique aqui para saber mais)
                      </S.ClickText>
                    </S.ExpirationCard>
                  )}
              </>
            )}
          </GridItem>
        </GridContainer>
        <br />
        <h3>Desempenho</h3>
        <br />
        <div className={style.chart}>
          <ReviewChartCard
            classes={classes}
            chart={{
              ...essaysHistory,
              data: {
                series: dataChart
              }
            }}
            title="Notas"
            timeLabel="Na última redação"
          />
        </div>
        <br />
        <h3>Histórico</h3>
        <br />
        <div className={style.history}>
          <UserEssaysList
            data={dataTable}
            columns={["Tema", "", "Data de Envio", "Nota", ""]}
            noDataText={noDataText}
          />
        </div>
      </div>
    </>
  );
};

export default StudentDashboard;
