import React from "react";
import GradeArgumentative from "./GradeArgumentative";
import GradeExpositive from "./GradeExpositive";
import GradeJuridical from "./GradeJuridical";
import {
  TOPIC_TYPE_ARGUMENTATIVE,
  TOPIC_TYPE_EXPOSITIVE,
  TOPIC_TYPE_JURIDICAL
} from "constants.js";

const getGradeType = (type, props) => {
  switch (type) {
    case TOPIC_TYPE_ARGUMENTATIVE:
      return <GradeArgumentative {...props} />;
    case TOPIC_TYPE_EXPOSITIVE:
      return <GradeExpositive {...props} />;
    case TOPIC_TYPE_JURIDICAL:
      return <GradeJuridical {...props} />;
    default:
      return <p>Tipo de grade não encontrado</p>; // Fallback
  }
};

export default getGradeType;
