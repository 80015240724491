import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button/Button";
import { validateUserUpdate } from "validators.js";
import CustomInput from "../../components/CustomInput/CustomInput";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

import style from "./styles.module.css";
import { useFormik } from "formik";
import { useNotification } from "utils/hooks";
import handleError from "../../utils/handleError";

const UpdateUser = ({ onUserUpdate, user }) => {
  const [loading, setLoading] = useState(false);
  const { setNotificationSuccess, setNotificationError } = useNotification();
  const formik = useFormik({
    initialValues: {
      name: user.name,
      email: user.email,
      password: "",
      passwordconfirm: ""
    },
    onSubmit: async ({ name, email, password, passwordconfirm }) => {
      try {
        setLoading(true);

        await validateUserUpdate({
          name,
          email,
          password,
          passwordconfirm
        });
        await onUserUpdate(name, email, password);

        setLoading(false);
        setNotificationSuccess("Informações atualizadas com sucesso!");
      } catch (err) {
        setLoading(false);
        handleError(err, setNotificationError);
      }
    },
    enableReinitialize: true
  });

  return (
    <form onSubmit={formik.handleSubmit} className={style.form}>
      <CustomInput
        labelText="Nome"
        id="name"
        name="name"
        color="primary"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: formik.handleChange,
          value: formik.values.name
        }}
      />
      <CustomInput
        labelText="E-mail"
        id="email"
        name="email"
        color="primary"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: formik.handleChange,
          value: formik.values.email
        }}
      />
      <p className={style.passwordInstructions}>
        Para atualizar as informações, confirme a sua senha:
      </p>
      <CustomInput
        labelText="Senha"
        id="password"
        name="password"
        color="primary"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: formik.handleChange,
          type: "password",
          value: formik.values.password
        }}
      />
      <CustomInput
        labelText="Confirmar Senha"
        id="passwordconfirm"
        name="passwordconfirm"
        color="primary"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: formik.handleChange,
          type: "password",
          value: formik.values.passwordconfirm
        }}
      />

      <Button type="submit">
        {!loading ? "Atualizar" : <CircularProgress color="primary" />}
      </Button>
    </form>
  );
};

UpdateUser.propTypes = {
  onUserUpdate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default UpdateUser;
