import { createReducer } from "@reduxjs/toolkit";
import set from "lodash/fp/set";
import update from "lodash/fp/update";
import * as act from "../actions/types";

const DEFAULT_STATE = {
  byId: {}
};

const contests = createReducer(DEFAULT_STATE, {
  [act.contests.type]: (state, action) => {
    const contestsById = action.payload.reduce(
      (acc, curr) => ({
        ...acc,
        [curr._id]: {
          ...curr,
          topics: curr.topics?.filter((t) => !t.inactive)
        }
      }),
      {}
    );
    return update("byId", (all) => ({
      ...all,
      ...contestsById
    }))(state);
  },
  [act.contestNew.type]: (state, action) =>
    set(["byId", action.payload._id], action.payload)(state),
  [act.contestUpdate.type]: (state, action) =>
    set(["byId", action.payload._id], action.payload)(state),
  [act.contestDelete.type]: (state, action) => {
    const { _id } = action.payload;
    delete state.byId[_id];
    return state;
  }
});

export default contests;
