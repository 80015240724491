import React from "react";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";

// Converts JSON to CSV and downloads it
export const JsonToCsvDownloader = ({ data, headers, title, filename }) => (
  <CSVLink data={data} headers={headers} filename={filename}>
    {title}
  </CSVLink>
);

JsonToCsvDownloader.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  title: PropTypes.string
};

JsonToCsvDownloader.defaultProps = {
  title: "Download da Planilha",
  filename: "planilha.csv"
};
