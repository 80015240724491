import { createReducer } from "@reduxjs/toolkit";
import set from "lodash/fp/set";
import update from "lodash/fp/update";
import * as act from "../actions/types";

const DEFAULT_STATE = {
  session: {
    user: null,
    authenticated: false
  }
};

const auth = createReducer(DEFAULT_STATE, {
  [act.login.type]: (state, action) => {
    const { user, authenticated, session } = action.payload;
    const obj = {
      user,
      authenticated,
      ...session
    };
    return set("session", obj)(state);
  },
  [act.logout.type]: () => {
    return DEFAULT_STATE;
  },
  [act.session.type]: (state, action) => {
    const { user, authenticated, session } = action.payload;
    return set("session", {
      user,
      authenticated,
      ...session
    })(state);
  },
  [act.userSelfUpdate.type]: (state, action) => {
    const user = action.payload;
    return set(["session", "user"], user)(state);
  },
  [act.essayNew.type]: (state) => {
    return update(["session", "user"], (user) => ({
      ...user,
      credits: user.credits - 1
    }))(state);
  }
});

export default auth;
