import styled from "styled-components";

export const TopicData = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;

  white-space: nowrap;
`;

export const ExpirationCard = styled.div`
  font-weight: bold;
  font-size: 12px;

  background-color: white;

  border-radius: 1rem;

  padding: 1rem;

  position: relative;

  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

  p {
    margin: 0;
    color: gray;
  }
`;

export const ClickText = styled.div`
  cursor: pointer;

  color: #00acc1;
`;

export const ArrowUp = styled.div`
  position: absolute;
  top: -10px;
  right: 50%;
  transform: translateX(50%);

  width: 0;
  height: 0;

  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
`;
