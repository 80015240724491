import styled, { css } from "styled-components";

export const EditButtonsContainer = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  gap: 20px;

  padding: 10px 0;
`;

export const EditTopicContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RatioWrapper = styled.div``;

export const UploadButton = styled.div`
  padding: 5px;

  cursor: pointer;

  background: #00acc1;

  border: none;
  border-radius: 3px;

  display: flex;
  align-items: center;

  gap: 10px;

  width: 200px;

  input {
    visibility: hidden;
    display: none;
  }

  svg {
    color: white;
  }

  label {
    cursor: pointer;

    color: white;
  }

  transition: background 0.4s;

  :hover {
    background: #008091;
  }

  ${({ isUploaded }) =>
    isUploaded &&
    css`
      background: #18db94;
    `}
`;

export const TagsWrapper = styled.div`
  display: flex;
  height: 30px;

  margin: 10px 0;

  button {
    margin-left: 4px;

    cursor: pointer;

    position: relative;

    background: #ffa726;

    color: white;

    padding: 15px;

    border: 0;
    border-radius: 5px;

    display: flex;

    justify-content: center;
    align-items: center;

    font-weight: bold;
    span {
      top: 2px;
      right: 4px;

      position: absolute;

      color: white;
    }
  }
`;
