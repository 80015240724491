import React from "react";

//* Stylesheet...
import * as S from "./styles";

const AriImageLogin = () => {
  return (
    <S.Container>
      <S.Img src={require("../../assets/img/login/ari.png")} />
    </S.Container>
  );
};

export default AriImageLogin;
