import React, { useState } from "react";
import classNames from "classnames";
import { mapKindToColorScheme } from "./helpers";
import Button from "components/CustomButtons/Button";
import Save from "@material-ui/icons/Save";
import Send from "@material-ui/icons/Send";
import Visibility from "@material-ui/icons/Visibility";
import styles from "./styles.module.css";
import { REVIEW_DEFAULT_KIND } from "constants.js";
import { REVIEW_STATUS_INELIGIBLE } from "../../constants";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { v4 } from "uuid";

const ReviewButtons = ({
  onClick,
  onSave,
  onIneligible,
  onSubmit,
  readOnly,
  onReset,
  essayStatus,
  gramaticalOnly
}) => {
  const [activeKind, setActiveKind] = useState(REVIEW_DEFAULT_KIND);

  return (
    <div className={styles.reviewButtons}>
      <div className={styles.buttonsReview}>
        {!readOnly && (
          <Button
            round
            justIcon
            disabled={readOnly || gramaticalOnly}
            active
            onClick={() => {
              onClick(REVIEW_DEFAULT_KIND);
              onReset();
              setActiveKind(REVIEW_DEFAULT_KIND);
            }}>
            <Visibility />
          </Button>
        )}
        {Object.values(mapKindToColorScheme).map(
          ({ color, title, kind, label }, i) => (
            <Tooltip key={v4()} title={title}>
              <span>
                <Button
                  round
                  disabled={readOnly || gramaticalOnly}
                  onClick={() => {
                    onClick(kind);
                    setActiveKind(kind);
                  }}
                  key={i}
                  style={{ backgroundColor: color }}
                  className={classNames(
                    styles.reviewButton,
                    activeKind !== kind && styles.reviewButtonIsLabel
                  )}>
                  {activeKind === kind ? title : label}
                </Button>
              </span>
            </Tooltip>
          )
        )}
      </div>
      {!readOnly ? (
        <div className={styles.buttonsActions}>
          <span style={{ marginRight: "29px" }}>
            <Tooltip title="Marcar como ilegível">
              <Button round justIcon onClick={onIneligible}>
                <VisibilityOffIcon />
              </Button>
            </Tooltip>
          </span>

          <Tooltip
            title="Salvar correção para continuar depois"
            aria-label="review">
            <Button round justIcon color="info" onClick={onSave}>
              <Save />
            </Button>
          </Tooltip>
          {/* {!gramaticalOnly && <Tooltip
            title="Enviar Correção Gramatical definitiva"
            aria-label="review">
              <Button round justIcon color="purple" onClick={onSave}>
                <Save />
              </Button>
          </Tooltip>
          } */}
          <Tooltip title="Enviar correção definitiva" aria-label="review">
            <Button round justIcon color="rose" onClick={onSubmit}>
              <Send />
            </Button>
          </Tooltip>
        </div>
      ) : essayStatus === REVIEW_STATUS_INELIGIBLE ? (
        <div className={styles.buttonsActions}>
          <Tooltip title="Redação marcada como ilegível">
            <Button round justIcon>
              <VisibilityOffIcon />
            </Button>
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(ReviewButtons);
