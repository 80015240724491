// Platform modes
export const STUDENT_MODE = "student";
export const TEACHER_MODE = "teacher";
export const JURIDICAL_MODE = "juridical";
export const ADMIN_MODE = "admin";

export const POLICIA_CIVIL_CEARA = "Policia Civil do Ceará (PC-CE)";
export const PROJETO_CAVEIRA_PC_CEARA = "Projeto Caveira - PC-CE";
export const POLICIA_CIVIL_AMAZONAS = "PC - Amazonas";
export const POLICIA_CIVIL_BAHIA = "PC - Bahia";

// Review Kinds
export const REVIEW_DEFAULT_KIND = 0;
export const REVIEW_COMMENT = 1;
export const REVIEW_GRAM_ERROR = 2;
export const REVIEW_WRITING_CONVENTION = 3;
export const REVIEW_VOC_ACCURACY = 4;
export const REVIEW_REG_CHOICE = 5;
export const REVIEW_CONNECTIVES = 6;
export const REVIEW_SYNTAX = 7;

// Review Status
export const REVIEW_STATUS_AVAILABLE = 1;
export const REVIEW_STATUS_IN_PROGRESS = 2;
export const REVIEW_STATUS_DONE = 3;
export const REVIEW_STATUS_INELIGIBLE = 4;
export const REVIEW_STATUS_PARTIAL_CORRECTION = 5;
export const REVIEW_STATUS_IN_PROGRESS_JURIDICAL = 6;
export const REVIEWS_STATUS_LABELS = {
  [REVIEW_STATUS_AVAILABLE]: "Disponível",
  [REVIEW_STATUS_IN_PROGRESS]: "Em correção",
  [REVIEW_STATUS_DONE]: "Corrigida",
  [REVIEW_STATUS_INELIGIBLE]: "Ilegível",
  [REVIEW_STATUS_PARTIAL_CORRECTION]: "Em espera da Correção Jurídica",
  [REVIEW_STATUS_IN_PROGRESS_JURIDICAL]: "Em correção Jurídica"
};
export const REVIEWS_STATUS_LABELS_STUDENT = {
  [REVIEW_STATUS_AVAILABLE]: "Correção pendente",
  [REVIEW_STATUS_IN_PROGRESS]: "Em correção",
  [REVIEW_STATUS_IN_PROGRESS_JURIDICAL]: "Em correção",
  [REVIEW_STATUS_PARTIAL_CORRECTION]: "Em correção",
  [REVIEW_STATUS_DONE]: "Corrigida",
  [REVIEW_STATUS_INELIGIBLE]: "Ilegível"
};

// Review Grades
export const CESPE_MAX_GRADE = 1000;

// System type map
export const USER_MODE_LABELS = {
  [STUDENT_MODE]: "aluno",
  [TEACHER_MODE]: "professor",
  [JURIDICAL_MODE]: "professor",
  [ADMIN_MODE]: "admin"
};

// polling
export const POLL_REVIEW_SAVE_INTERVAL = 3 * 60 * 1000;

// Types of institutions that apply the contest
export const ENEM = "ENEM";
export const CESPE = "CESPE";

export const TOPIC_TYPE_ARGUMENTATIVE = 1;
export const TOPIC_TYPE_EXPOSITIVE = 2;
export const TOPIC_TYPE_JURIDICAL = 3;
export const TOPIC_TYPE_GRAMMAR_LESSONS = 4;
export const TOPIC_TYPE_COMPLEMENTARY_LESSONS = 5;

export const TOPIC_TYPE = {
  1: "Argumentativo",
  2: "Expositivo (Cebraspe)",
  3: "Jurídico",
  4: "Gramática"
};

export const BASIC_MATERIAL_CATEGORIES = [
  "Texto Dissertativo-argumentativo",
  "Texto Dissertativo-expositivo",
  "Texto Jurídico",
  "Correção Gramatical"
];

export const VIDEO_MATERIAL_CATEGORIES = [
  "Texto Dissertativo-argumentativo",
  "Texto Dissertativo-expositivo",
  "Texto Jurídico",
  "Correção Gramatical",
  "Aulas de gramática",
  "Aulas complementares"
];
