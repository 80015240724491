import { useContext } from "react";
import { NotificationContext } from "../../providers/Notification";
import CheckIcon from "@material-ui/icons/Check";
import WarningIcon from "@material-ui/icons/Warning";

function useNotification() {
  const { open, setNotification } = useContext(NotificationContext);

  const setNotificationSuccess = (message) =>
    setNotification("success", CheckIcon, message);

  const setNotificationError = (errMessage) =>
    setNotification("danger", WarningIcon, errMessage);

  const setNotificationWarning = (warning) =>
    setNotification("info", WarningIcon, warning);

  return {
    open,
    setNotification,
    setNotificationSuccess,
    setNotificationError,
    setNotificationWarning
  };
}

export default useNotification;
