import React from "react";
import moment from "moment";
import {
  REVIEW_STATUS_IN_PROGRESS,
  REVIEW_STATUS_IN_PROGRESS_JURIDICAL,
  REVIEW_STATUS_PARTIAL_CORRECTION,
  REVIEW_STATUS_DONE,
  REVIEW_STATUS_AVAILABLE,
  REVIEW_STATUS_INELIGIBLE
} from "constants.js";
import CheckIcon from "@material-ui/icons/Check";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import AssignmentIcon from "@material-ui/icons/Assignment";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

export const dateFormat = (date) => moment(date).format("DD/MM/YYYY HH:mm");

export const getIconButtonStyle = (status) =>
  status === REVIEW_STATUS_DONE
    ? { color: "#4ABDD1", borderColor: "#4ABDD1" }
    : status === REVIEW_STATUS_IN_PROGRESS
    ? { color: "#9C27B0", borderColor: "#9C27B0" }
    : status === REVIEW_STATUS_IN_PROGRESS_JURIDICAL
    ? { color: "#9C27B0", borderColor: "#9C27B0" }
    : status === REVIEW_STATUS_AVAILABLE
    ? { color: "#F49913", borderColor: "#F49913" }
    : status === REVIEW_STATUS_PARTIAL_CORRECTION
    ? { color: "#F49913", borderColor: "#F49913" }
    : status === REVIEW_STATUS_INELIGIBLE
    ? { color: "#676767", borderColor: "#676767" }
    : {};

export const getStatusIcon = (status) =>
  status === REVIEW_STATUS_DONE ? (
    <CheckIcon style={{ fontSize: 15, color: "#4ABDD1" }} />
  ) : status === REVIEW_STATUS_IN_PROGRESS ? (
    <BorderColorIcon style={{ fontSize: 13, color: "#9C27B0" }} />
  ) : status === REVIEW_STATUS_IN_PROGRESS_JURIDICAL ? (
    <BorderColorIcon style={{ fontSize: 13, color: "#9C27B0" }} />
  ) : status === REVIEW_STATUS_AVAILABLE ? (
    <AssignmentIcon style={{ fontSize: 14, color: "#F49913" }} />
  ) : status === REVIEW_STATUS_PARTIAL_CORRECTION ? (
    <AssignmentIcon style={{ fontSize: 14, color: "#F49913" }} />
  ) : status === REVIEW_STATUS_INELIGIBLE ? (
    <VisibilityOffIcon style={{ fontSize: 14, color: "#676767" }} />
  ) : null;
