/* eslint-disable no-unused-vars */
// Pages
import DashboardPage from "pages/Dashboard";
import ProfilePage from "pages/Profile";
import AdminUsersPage from "pages/Users/Admin";
import AdminUsersPageDetails from "pages/Users/Details";
import AdminMaterialsPage from "pages/Materials/Admin";
import StudentMaterialsPage from "pages/Materials/Student";
import ProfessorMaterialsPage from "pages/Materials/Professor";
import Contest from "pages/Contest/Admin";
import LoginPage from "pages/Auth/Login";
import LogoutPage from "pages/Logout";
import PasswordResetPage from "pages/Auth/PasswordReset";
import ReviewedEssaysPage from "pages/Teacher/ReviewedEssays";
import TeacherEssaysPage from "pages/Teacher/Essays";
import StudentEssaysPage from "pages/Student/Essays";
import TopicsPage from "pages/Student/Topics";
import { EssaysPage, DetailsPage, ReviewPage } from "pages/Essays";
import HelpPage from "pages/Help/Help";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import EbookList from "components/EbookList";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import PersonIcon from "@material-ui/icons/Person";
import BookIcon from "@material-ui/icons/Book";
import { IoIosPaper } from "react-icons/io";
import { FiPackage } from "react-icons/fi";
import TopicsIcon from "@material-ui/icons/Spellcheck";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import {
  STUDENT_MODE,
  TEACHER_MODE,
  ADMIN_MODE,
  JURIDICAL_MODE
} from "constants.js";
import ExtraEssayPackage from "pages/ExtraEssayPackage";

export const authRoutes = [
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    layout: "/auth",
    exact: true
  },
  // {
  //   path: "/cadastro",
  //   name: "Registre-se",
  //   component: RegisterPage,
  //   layout: "/auth",
  //   exact: true
  // },
  {
    path: "/redefinir-senha",
    name: "Redefinição de Senha",
    component: PasswordResetPage,
    layout: "/auth",
    exact: true
  }
];

export const adminRoutes = [
  // Admin routes
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    layout: "/admin",
    component: DashboardPage(ADMIN_MODE),
    exact: true
  },
  {
    name: "Redações",
    path: "/redacoes",
    icon: AssignmentIcon,
    layout: "/admin",
    component: EssaysPage,
    exact: true
  },
  // {
  //   name: "Simulado",
  //   path: "/simulado",
  //   exact: true,
  //   icon: EmojiEventsIcon,
  //   layout: "/admin",
  //   component: SimulationPage
  // },
  {
    name: "Cadastro de Temas",
    path: "/concursos",
    icon: AccountBalanceIcon,
    layout: "/admin",
    component: Contest,
    exact: true
  },
  {
    name: "Usuários",
    path: "/usuarios",
    icon: PersonIcon,
    layout: "/admin",
    component: AdminUsersPage,
    exact: true
  },
  {
    name: "Materiais",
    path: "/materiais",
    icon: BookIcon,
    layout: "/admin",
    component: AdminMaterialsPage,
    exact: true
  },
  {
    name: "Usuários",
    path: "/usuarios/:id",
    icon: PersonIcon,
    layout: "/admin",
    component: AdminUsersPageDetails,
    skip: true,
    exact: true
  },
  {
    path: "/perfil",
    name: "Perfil",
    icon: AssignmentIndIcon,
    layout: "/admin",
    component: ProfilePage(ADMIN_MODE),
    exact: true
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutPage,
    layout: "/admin",
    icon: ExitToAppIcon,
    exact: true
  },
  {
    path: "/redacoes/corrigir/:id",
    name: "Corrigir Redação",
    component: ReviewPage,
    layout: "/admin",
    skip: true,
    exact: true
  },
  {
    path: "/redacoes/detalhes/:id",
    name: "Redação",
    component: DetailsPage,
    layout: "/admin",
    skip: true,
    exact: true
  }
];

export const studentRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: DashboardPage(STUDENT_MODE),
    layout: "/aluno",
    exact: true
  },
  {
    name: "Materiais",
    path: "/materiais",
    icon: BookIcon,
    layout: "/aluno",
    component: StudentMaterialsPage,
    exact: false,
    permission: "Material"
  },
  {
    name: "Folhas de Redação",
    path: "/folhas-de-redacao",
    icon: IoIosPaper,
    layout: "/aluno",
    component: EbookList,
    exact: false
  },
  {
    name: "Temas",
    path: "/temas",
    icon: TopicsIcon,
    layout: "/aluno",
    component: TopicsPage,
    exact: false
  },
  // {
  //   name: "Simulado",
  //   path: "/simulado",
  //   exact: true,
  //   icon: EmojiEventsIcon,
  //   layout: "/aluno",
  //   component: SimulationPage
  // },
  /* {
    name: "Enviar Redação",
    path: "/enviar",
    exact: true,
    icon: SendIcon,
    layout: "/aluno",
    component: SubmitPage
  }, */
  {
    name: "Minhas Redações",
    path: "/redacoes",
    icon: AssignmentIcon,
    exact: true,
    layout: "/aluno",
    component: StudentEssaysPage
  },
  {
    path: "/perfil",
    name: "Perfil",
    icon: AssignmentIndIcon,
    component: ProfilePage(STUDENT_MODE),
    layout: "/aluno",
    exact: true
  },
  {
    path: "/pacote",
    name: "Comprar mais créditos",
    icon: FiPackage,
    component: ExtraEssayPackage,
    layout: "/aluno",
    exact: true
  },
  {
    path: "/contato",
    name: "Contato",
    icon: HelpOutlineIcon,
    component: HelpPage,
    layout: "/aluno",
    exact: true
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutPage,
    layout: "/aluno",
    icon: ExitToAppIcon,
    exact: true
  },
  {
    path: "/redacoes/detalhes/:id",
    name: "Redação",
    component: DetailsPage,
    layout: "/aluno",
    skip: true,
    exact: true
  }
];

export const teacherRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: DashboardPage(TEACHER_MODE),
    layout: "/professor",
    exact: true
  },
  {
    name: "Temas",
    path: "/temas",
    icon: TopicsIcon,
    layout: "/professor",
    component: TopicsPage,
    exact: false
  },
  {
    name: "Redações",
    path: "/redacoes",
    icon: AssignmentIcon,
    layout: "/professor",
    component: TeacherEssaysPage,
    exact: true
  },
  {
    name: "Correções",
    path: "/correcoes",
    icon: AssignmentTurnedInIcon,
    layout: "/professor",
    component: ReviewedEssaysPage,
    exact: true
  },
  {
    name: "Materiais",
    path: "/materiais",
    icon: BookIcon,
    layout: "/professor",
    component: ProfessorMaterialsPage,
    exact: false
  },
  // {
  //   name: "Simulado",
  //   path: "/simulado",
  //   exact: true,
  //   icon: EmojiEventsIcon,
  //   layout: "/professor",
  //   component: SimulationPage
  // },
  {
    path: "/perfil",
    name: "Perfil",
    icon: AssignmentIndIcon,
    component: ProfilePage(TEACHER_MODE),
    layout: "/professor",
    exact: true
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutPage,
    layout: "/professor",
    icon: ExitToAppIcon,
    exact: true
  },
  {
    path: "/redacoes/detalhes/:id",
    name: "Redação",
    component: DetailsPage,
    layout: "/professor",
    skip: true,
    exact: true
  },
  {
    path: "/redacoes/corrigir/:id",
    name: "Corrigir Redação",
    component: ReviewPage,
    layout: "/professor",
    skip: true,
    exact: true
  }
];

export const juridicalRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: DashboardPage(JURIDICAL_MODE),
    layout: "/professor",
    exact: true
  },
  {
    name: "Temas",
    path: "/temas",
    icon: TopicsIcon,
    layout: "/professor",
    component: TopicsPage,
    exact: false
  },
  {
    name: "Redações",
    path: "/redacoes",
    icon: AssignmentIcon,
    layout: "/professor",
    component: TeacherEssaysPage,
    exact: true
  },
  {
    name: "Correções",
    path: "/correcoes",
    icon: AssignmentTurnedInIcon,
    layout: "/professor",
    component: ReviewedEssaysPage,
    exact: true
  },
  {
    name: "Materiais",
    path: "/materiais",
    icon: BookIcon,
    layout: "/professor",
    component: ProfessorMaterialsPage,
    exact: false
  },
  // {
  //   name: "Simulado",
  //   path: "/simulado",
  //   exact: true,
  //   icon: EmojiEventsIcon,
  //   layout: "/professor",
  //   component: SimulationPage
  // },
  {
    path: "/perfil",
    name: "Perfil",
    icon: AssignmentIndIcon,
    component: ProfilePage(JURIDICAL_MODE),
    layout: "/professor",
    exact: true
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutPage,
    layout: "/professor",
    icon: ExitToAppIcon,
    exact: true
  },
  {
    path: "/redacoes/detalhes/:id",
    name: "Redação",
    component: DetailsPage,
    layout: "/professor",
    skip: true,
    exact: true
  },
  {
    path: "/redacoes/corrigir/:id",
    name: "Corrigir Redação",
    component: ReviewPage,
    layout: "/professor",
    skip: true,
    exact: true
  }
];

export default adminRoutes;
