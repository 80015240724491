import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import Muted from "components/Typography/Muted";
import styles from "../styles.module.css";
import { Advise } from "../styles";
import { CESPE_MAX_GRADE } from "../../../constants";

const DEFAULT_INITIAL_GRADES = {
  presentation: null,
  aspects: [null, null],
  errors: null,
  lines: null,
  final: null
};

const GradeArgumentative = ({
  initialGrades = DEFAULT_INITIAL_GRADES,
  setGrades,
  readOnly,
  gramaticalOnly,
  juridicalOnly
}) => {
  const MAX_GRADE = CESPE_MAX_GRADE;

  // Notas de conteúdo
  const [presentation, setPresentation] = useState(initialGrades.presentation);
  const [aspects, setAspects] = useState(initialGrades.aspects);

  // Erros do aluno
  const [errors, setErrors] = useState(initialGrades.errors);

  // Linhas escritas na redação
  const [lines, setLines] = useState(initialGrades.lines);

  // Se ambos forem falso, redação recebe apenas uma correção, logo desbloqueia todos os campos
  if (!gramaticalOnly && !juridicalOnly) {
    gramaticalOnly = false;
    juridicalOnly = false;
  }

  // Nota final
  const [finalGrade, setFinalGrade] = useState(null);

  useEffect(() => {
    setPresentation(
      initialGrades.presentation || DEFAULT_INITIAL_GRADES.presentation
    );
    setAspects(initialGrades.aspects || DEFAULT_INITIAL_GRADES.aspects);
    setErrors(initialGrades.errors || DEFAULT_INITIAL_GRADES.errors);
    setLines(initialGrades.lines || DEFAULT_INITIAL_GRADES.lines);
  }, [initialGrades]);

  useEffect(() => {
    let g = 0;
    if (presentation) g += presentation;

    // se todas as notas tiverem sido dadas, calcular nota final
    if (
      (presentation === 0 ? true : presentation) &&
      (errors === 0 ? true : errors) &&
      lines
    ) {
      const fg = g.toFixed(2);

      const parsedGrade = parseFloat(fg, 10);

      setFinalGrade(parsedGrade < 0 ? 0 : parsedGrade);
    } else {
      setFinalGrade(0);
    }

    setGrades({ presentation, aspects, errors, lines, final: finalGrade });
  }, [presentation, errors, lines, setGrades, finalGrade]);

  return (
    <div className={styles.gradeWrapper}>
      <Muted>
        <b>Notas</b>
      </Muted>

      <br />

      <GridContainer>
        <GridItem style={{ display: "flex" }}>
          <TextField
            disabled={readOnly || juridicalOnly}
            label={<Muted>Nota</Muted>}
            variant="outlined"
            size="small"
            type="number"
            value={presentation}
            error={presentation > 1000}
            onChange={(event) => {
              event.preventDefault();

              const parsedGrade = parseFloat(event.target.value, 10);

              if (parsedGrade >= 0) return setPresentation(parsedGrade);

              return setPresentation(0);
            }}
            style={{
              width: "110px",
              fontSize: 12,
              borderColor: "#F49913"
            }}
          />

          <Muted style={{ paddingTop: "10px", paddingLeft: "5px" }}>
            / {MAX_GRADE}
          </Muted>
        </GridItem>
      </GridContainer>

      <br />

      <Muted>
        <b>Outros</b>
      </Muted>

      <br />

      <GridContainer>
        <GridItem style={{ display: "flex" }}>
          <TextField
            disabled={readOnly || gramaticalOnly}
            label={<Muted>nº de erros</Muted>}
            variant="outlined"
            size="small"
            type="number"
            value={errors}
            onChange={(event) => {
              event.preventDefault();
              const { target } = event;
              let ers = 0;

              if (target.value && !isNaN(target.value)) {
                ers = parseFloat(event.target.value, 10);
              }
              if (ers >= 0) setErrors(ers);
            }}
            style={{ width: "95px", fontSize: 12, borderColor: "#F49913" }}
          />
        </GridItem>

        <GridItem style={{ display: "flex", marginLeft: "55px" }}>
          <TextField
            disabled={readOnly || gramaticalOnly}
            label={<Muted>nº de linhas</Muted>}
            variant="outlined"
            size="small"
            type="number"
            value={lines}
            onChange={(e) => {
              e.preventDefault();

              let ls = 0;

              if (e.target.value && !isNaN(e.target.value)) {
                ls = parseFloat(e.target.value, 10);
              }

              if (ls >= 0) setLines(ls);
            }}
            style={{ width: "95px", fontSize: 12, borderColor: "#F49913" }}
          />
        </GridItem>
      </GridContainer>

      <br />

      <GridContainer>
        <GridItem style={{ display: "flex" }}>
          <h5>NF = </h5>

          <Muted
            style={{ paddingLeft: "25px", color: "#f49913", fontSize: 25 }}>
            <b>{finalGrade || 0}</b>
          </Muted>
        </GridItem>

        <GridItem>
          <Advise>
            1. IMPORTANTE: por padrão, as notas estão unificadas entre 0 a 1000
            pontos. Caso o seu concurso tenha uma pontuação diferente, não se
            preocupe. Basta verificar a porcentagem de aproveitamento. EXEMPLO:
            se a nota foi 200 de 1000, o aproveitamento foi de apenas 20%; se
            foi 800 de 1000, o aproveitamento foi de 80%.
          </Advise>
          <Advise>
            2. IMPORTANTE: por mais que os critérios de correção da sua banca
            tenham nomenclaturas distintas das aplicadas aqui, os conceitos
            usados nas nossas avaliações são idênticos aos que serão utilizados
            pelo avaliador do seu concurso. São estruturas universais, mas, por
            vezes, com nomes distintos.
          </Advise>
          <Advise>
            3. IMPORTANTE: mais importante do que a nota é a qualidade da sua
            correção e a visualização dos seus pontos fracos e fortes para
            posterior melhoria.
          </Advise>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default GradeArgumentative;
