import get from "lodash/fp/get";
import { createSelector } from "reselect";

const usersSelector = get("users");

export const usersById = createSelector(usersSelector, get("usersById"));

export const userById = (userId) => createSelector(usersById, get(userId));

export const users = createSelector(usersById, (users) => Object.values(users));

export const userAdminStats = createSelector(
  usersSelector,
  get("userAdminStats")
);
