import get from "lodash/fp/get";
import { createSelector } from "reselect";

const institutionsSelector = get("institutions");

export const institutionsById = createSelector(
  institutionsSelector,
  get("byId")
);

export const institutionById = (id) =>
  createSelector(institutionsById, get(id));

export const institutions = createSelector(institutionsById, (institutions) =>
  Object.values(institutions)
);
