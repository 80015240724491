import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  IconButton,
  Box,
  Divider,
  TextField
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { rateByReviewId } from "lib/api";
import Muted from "components/Typography/Muted";
import { rateSave } from "lib/api";
import { useNotification } from "utils/hooks";
import { TOPIC_TYPE_JURIDICAL } from "constants.js";

const RatingComponent = ({ user, essay }) => {
  const { setNotificationError } = useNotification();

  const [initialValues, setInitialValues] = useState(null);
  const [rateInfos, setRateInfos] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const isJuridical = essay?.topic?.type === TOPIC_TYPE_JURIDICAL;

  const fetchReview = async () => {
    const { data } = await rateByReviewId(essay.review._id);

    setRateInfos(data);

    setInitialValues({
      correctionRating: data.correctionRating,
      correctorRating: data.correctorRating,
      comment: data.comment
    });

    setIsOwner(data.evaluatorId === user._id);
  };

  useEffect(() => {
    if (isJuridical) return;

    fetchReview();
  }, [essay.review.id, user]);

  useEffect(() => {
    if (rateInfos) {
      isOwner ? setDisabled(false) : setDisabled(true);

      rateInfos.status === 1 && setDisabled(true);
    }
  }, [rateInfos, isOwner]);

  const handleRateSubmit = async (values) => {
    try {
      const { data } = await rateSave(
        essay.review._id,
        user._id,
        values.correctionRating,
        values.correctorRating,
        values.comment
      );

      setRateInfos(data);
      setDisabled(true);
    } catch (error) {
      setNotificationError(error);
    }
  };

  const validationSchema = Yup.object({
    correctionRating: Yup.object({
      quality: Yup.number().min(1, "Obrigatório").required("Obrigatório"),
      communication: Yup.number().min(1, "Obrigatório").required("Obrigatório")
    }),
    correctorRating: Yup.object({
      professionalism: Yup.number()
        .min(1, "Obrigatório")
        .required("Obrigatório"),
      friendliness: Yup.number().min(1, "Obrigatório").required("Obrigatório")
    }),
    comment: Yup.string().optional()
  });

  const renderStars = (rating, category, field, setFieldValue) => {
    return Array.from({ length: 5 }).map((_, index) => (
      <IconButton
        key={index}
        onClick={() => setFieldValue(`${category}.${field}`, index + 1)}
        disabled={disabled}>
        {index < rating ? (
          <StarIcon style={{ color: "#FFD700" }} />
        ) : (
          <StarBorderIcon />
        )}
      </IconButton>
    ));
  };

  if (isJuridical) {
    return (
      <Box
        container
        spacing={3}
        sx={{
          width: "1000px",
          display: "flex",
          flexDirection: "column",
          padding: "10px"
        }}>
        <Muted>A avaliação não está disponivel para esse tópico.</Muted>
      </Box>
    );
  }

  if (!initialValues) return <div>Carregando...</div>;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleRateSubmit}>
      {({ values, setFieldValue, errors }) => (
        <Form>
          <Box
            container
            spacing={3}
            sx={{ width: "1000px", display: "flex", flexDirection: "column" }}>
            <Box sx={{ marginTop: "20px" }}>
              <Typography variant="body1">Avaliação da Correção</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                marginTop: "10px",
                gap: "50px"
              }}>
              <Box>
                <Typography variant="body2">Qualidade da Correção</Typography>
                {renderStars(
                  values.correctionRating.quality,
                  "correctionRating",
                  "quality",
                  setFieldValue
                )}
              </Box>

              <Box>
                <Typography variant="body2">Comunicação na Correção</Typography>
                {renderStars(
                  values.correctionRating.communication,
                  "correctionRating",
                  "communication",
                  setFieldValue
                )}
              </Box>
            </Box>

            <Divider />

            <Box sx={{ marginTop: "20px" }}>
              <Typography variant="body1">Avaliação do Corretor</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                marginTop: "10px",
                gap: "50px"
              }}>
              <Box>
                <Typography variant="body2">Profissionalismo</Typography>
                {renderStars(
                  values.correctorRating.professionalism,
                  "correctorRating",
                  "professionalism",
                  setFieldValue
                )}
              </Box>

              <Box>
                <Typography variant="body2">Cordialidade</Typography>
                {renderStars(
                  values.correctorRating.friendliness,
                  "correctorRating",
                  "friendliness",
                  setFieldValue
                )}
              </Box>
            </Box>

            <Divider />

            <Box sx={{ marginTop: "20px" }}>
              {rateInfos.status === 1 ? (
                rateInfos.comment != "" ? (
                  <Typography variant="body1">Comentário:</Typography>
                ) : null
              ) : !disabled ? (
                <Typography variant="body1">Comentário (opcional)</Typography>
              ) : null}
              <Typography variant="body1"></Typography>
              {disabled ? (
                <Typography variant="body2">{values.comment}</Typography>
              ) : (
                <TextField
                  multiline
                  rows={4}
                  value={values.comment}
                  onChange={(e) => setFieldValue("comment", e.target.value)}
                  style={{
                    width: "100%"
                  }}
                  disabled={disabled}
                />
              )}
            </Box>

            {!disabled ? (
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "row-reverse",
                  gap: "20px",
                  alignItems: "center"
                }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    !!errors.correctionRating || !!errors.correctorRating
                  }>
                  Avaliar
                </Button>
                <Muted variant="body2">
                  Preencha todos os campos para enviar
                </Muted>
              </Box>
            ) : rateInfos.status === 1 ? (
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "row-reverse"
                }}>
                <Muted variant="body2">
                  Correção avaliada, média final:{" "}
                  {rateInfos.overallRating.toFixed(2)}
                </Muted>
              </Box>
            ) : (
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "row-reverse"
                }}>
                <Muted variant="body2">Correção ainda não avaliada</Muted>
              </Box>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default RatingComponent;
