import React from "react";
import { FiAlertCircle } from "react-icons/fi";
import moment from "moment";
import DATES from "../../utils/bannerDates";

// Components
import CreditsBanner from "components/CreditsBanner";

// Styles
import * as S from "./styles";

export default function ExtraEssayPackage() {
  const NewDate = moment(new Date());
  const startDateLote1 = moment(DATES.START_DATE_LOTE1);
  const endDateLote1 = moment(DATES.END_DATE_LOTE1);
  const startDateLote2 = moment(DATES.START_DATE_LOTE2);
  const endDateLote2 = moment(DATES.END_DATE_LOTE2);
  let hotLink = "";

  if (NewDate.isBetween(startDateLote1, endDateLote1)) {
    hotLink =
      "https://pay.hotmart.com/X73056678F?checkoutMode=10&offDiscount=RENOVACAO&split=12&bid=1681930251669";
  } else if (NewDate.isBetween(startDateLote2, endDateLote2)){
    hotLink =
      "https://pay.hotmart.com/X73056678F?checkoutMode=10&offDiscount=RENOVACAO2&split=12&bid=1681930265563";
  } else {
    hotLink = "https://pay.hotmart.com/X73056678F?checkoutMode=10&offDiscount=RENOVACAO2&split=12";
  }

  return (
    <div>
      <CreditsBanner />
      <S.Container>
        <S.Content>
          <S.IconContainer>
            <FiAlertCircle size={40} />
          </S.IconContainer>
          <p>Adquira correções extras (exclusivo para alunos):</p>
          <S.Link target="_blank" href={hotLink}>
            https://pay.hotmart.com/X73056678F?checkoutMode=10&offDiscount=RENOVACAO2&split=12
          </S.Link>
          <p>
            <strong>Importante:</strong> na aquisição do pacote extra, utilize
            seu e-mail já cadastrado na plataforma.
          </p>
        </S.Content>
      </S.Container>
    </div>
  )
}
