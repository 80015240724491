import React, { useState, useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import useNotification from "utils/hooks/useNotification";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import stylesAdmin from "./styles.module.css";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "@material-ui/core/Button";
import ContestsIcon from "@material-ui/icons/CollectionsBookmark";
import { http } from "lib/api.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
const useStyles = makeStyles(styles);

const AddTagModal = ({ open, handleClose }) => {
  const classes = useStyles();

  const { setNotificationSuccess, setNotificationError } = useNotification();

  const [loading, setLoading] = useState(false);

  const handleAddTag = useCallback(async (name, description) => {
    await http.post("tags", {
      name,
      description
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: ""
    },
    onSubmit: async ({ name, description }) => {
      try {
        setLoading(true);
        await handleAddTag(name, description);
        setLoading(false);
        setNotificationSuccess("Tag criado com sucesso");
        handleClose();
      } catch (err) {
        setNotificationError(err.data.ErrorMessage);
        handleClose();

        throw err;
      }
    }
  });

  const isDisabled = formik.values.name === "";

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <form onSubmit={formik.handleSubmit} className={stylesAdmin.submitForm}>
          <DialogContent>
            <Card>
              <CardHeader color="warning" icon>
                <CardIcon color="warning">
                  <ContestsIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Cadastrar Tag</h4>
              </CardHeader>
              <CardBody className={stylesAdmin.addContentDialog}>
                <CustomInput
                  labelText="Nome da Tag"
                  id="name"
                  name="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: formik.handleChange
                  }}
                />
                <CustomInput
                  labelText="Descrição da Tag"
                  id="description"
                  name="description"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: formik.handleChange
                  }}
                />
              </CardBody>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="text" color="danger">
              Cancelar
            </Button>
            <Button
              onClick={formik.handleSubmit}
              disabled={isDisabled}
              style={{ color: "#F5A01D" }}>
              {!loading ? (
                "Cadastrar"
              ) : (
                <CircularProgress size={20} color="primary" />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddTagModal;
