import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddInstitutionModal from "./Modals/AddInstitutionModal";
import AddTagModal from "./Modals/AddTagModal";
import AddTopicModal from "./Modals/AddTopicModal";
import TableTags from "components/TableTags/TableTags";
import TableTopics from "components/TableTopics/TableTopics";
import { useInstitutions, useContests, useTopics } from "./hooks";
import stylesTasks from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import stylesTables from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import ContestsIcon from "@material-ui/icons/CollectionsBookmark";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import TopicsIcon from "@material-ui/icons/Spellcheck";
import { FiSearch } from "react-icons/fi";
import { http } from "../../lib/api.js";

import * as S from "./stylesTopicRegistry";

const styles = {
  ...stylesTasks,
  ...stylesTables,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  container: {
    maxWidth: "1500px",
    margin: "auto"
  }
};

const useStyles = makeStyles(styles);

const AdminContest = () => {
  const classes = useStyles();
  const [tags, setTags] = useState([
    { _id: "2131232132132", name: "nome-exemplo" }
  ]);

  const { contestsById, onContestUpdate, loading: contLoading } = useContests();

  const {
    institutions,
    onInstitutionNew,
    onInstitutionUpdate,
    onInstitutionDelete,
    loading: instLoading
  } = useInstitutions();

  const {
    topics,
    onTopicNew,
    onTopicUpdate,
    onTopicDelete,
    loading: topLoading
  } = useTopics();

  const [filteredTopics, setFilteredTopics] = useState(topics);

  const [topicTypeFilter, setTopicTypeFilter] = useState(0);

  const loading = contLoading || instLoading || topLoading;

  const [openAddInstitutionModal, setOpenAddInstitutionModal] = useState(false);
  const [openAddTagModal, setOpenAddTagModal] = useState(false);
  const [openAddTopicModal, setOpenAddTopicModal] = useState(false);

  const loadTags = useCallback(async () => {
    const response = await http.get("tags");

    setTags(response.data);
  }, [setTags]);

  useEffect(() => {
    loadTags();
  }, [openAddTagModal]);

  useEffect(() => {
    setFilteredTopics(topics);
  }, [topics]);

  return !loading ? (
    <GridContainer className={classes.container}>
      <AddInstitutionModal
        open={openAddInstitutionModal}
        handleClose={() => setOpenAddInstitutionModal(false)}
        onInstitutionNew={onInstitutionNew}
      />
      <AddTagModal
        open={openAddTagModal}
        handleClose={() => setOpenAddTagModal(false)}
      />
      <AddTopicModal
        open={openAddTopicModal}
        handleClose={() => setOpenAddTopicModal(false)}
        tags={tags}
        onTopicNew={onTopicNew}
      />

      <GridContainer>
        <GridItem xs={12} md={12}>
          <Card styles={{ width: "100%" }}>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <ContestsIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Tags</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <TableTags tags={tags} />
                </GridItem>
              </GridContainer>

              <GridContainer justify="center">
                <GridItem>
                  <Card style={{ width: "8rem" }}>
                    <CardBody style={{ textAlign: "center" }}>
                      <h6>Cadastrar Tags</h6>
                      <IconButton style={{ color: "#F5A01D" }}>
                        <AddCircleIcon
                          onClick={() => setOpenAddTagModal(true)}
                        />
                      </IconButton>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} md={12}>
          <Card styles={{ width: "100%" }}>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <TopicsIcon />
              </CardIcon>
              <div>
                <h4 className={classes.cardIconTitle}>Controle de Temas</h4>
              </div>
              <S.FilterContainer>
                <S.SearchInput>
                  <FiSearch size={20} color="#00acc1" />
                  <input
                    onChange={(e) => {
                      setFilteredTopics(
                        e.target.value || topicTypeFilter
                          ? topics.filter((topic) => {
                              let result = true;
                              if (e.target.value)
                                result =
                                  result &&
                                  topic.name
                                    .toLowerCase()
                                    .includes(e.target.value.toLowerCase());

                              if (topicTypeFilter)
                                result =
                                  result && topic.type === topicTypeFilter;

                              return result;
                            })
                          : topics
                      );
                    }}
                    type="text"></input>
                </S.SearchInput>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Categoria
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={topicTypeFilter}
                    label="Categoria"
                    onChange={(e) => {
                      let topicType = e.target.value;
                      setFilteredTopics(
                        topicType
                          ? topics.filter((topic) => topic.type == topicType)
                          : topics
                      );
                      setTopicTypeFilter(topicType);
                    }}>
                    <MenuItem value={0}>
                      <em>Todos</em>
                    </MenuItem>
                    <MenuItem value={1}>Argumentativo</MenuItem>
                    <MenuItem value={2}>Expositivo</MenuItem>
                    <MenuItem value={3}>Jurídico</MenuItem>
                  </Select>
                </FormControl>
              </S.FilterContainer>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <TableTopics
                    topics={filteredTopics}
                    tags={tags}
                    contestsById={contestsById}
                    onContestUpdate={onContestUpdate}
                    onTopicUpdate={onTopicUpdate}
                    onTopicDelete={onTopicDelete}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer justify="center">
                <GridItem>
                  <Card style={{ width: "8rem" }}>
                    <CardBody style={{ textAlign: "center" }}>
                      <h6>Cadastrar Tema</h6>
                      <IconButton style={{ color: "#4BBFD3" }}>
                        <AddCircleIcon
                          onClick={() => setOpenAddTopicModal(true)}
                        />
                      </IconButton>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </GridContainer>
  ) : (
    <GridContainer justify="center" style={{ marginTop: "10%" }}>
      <CircularProgress size={24} />
    </GridContainer>
  );
};

export default AdminContest;
