import { ValidationError } from "yup";

export default function handleError(err, callback) {
  if (err instanceof ValidationError) {
    if (err.errors && err.errors.length > 1) {
      const errorConcat = err.errors.join(" ");
      callback(errorConcat);
      return;
    }

    if (err.message) {
      callback(err.message.toString());
      return;
    }
  }

  if (err.data.ErrorMessage) callback(err.data.ErrorMessage);
  throw err;
}
