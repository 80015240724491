import { createReducer } from "@reduxjs/toolkit";
import set from "lodash/fp/set";
import compose from "lodash/fp/compose";
import * as act from "../actions/types";

const DEFAULT_STATE = {
  byId: {},
  byUserId: {},
  bySimulationIdRanking: {},
  info: {}
};

const makeEssaysById = (state, essays) =>
  essays.reduce(
    (acc, curr) => ({
      ...acc,
      [curr._id]: curr
    }),
    state.byId
  );

const essays = createReducer(DEFAULT_STATE, {
  [act.essays.type]: (state, action) =>
    set("byId", makeEssaysById(state, action.payload))(state),
  [act.essayNew.type]: (state, action) =>
    set(["byId", action.payload._id], action.payload)(state),
  [act.essay.type]: (state, action) =>
    set(["byId", action.payload.data._id], {
      ...action.payload.data,
      file: action.payload.file
    })(state),
  [act.essaysByUserId.type]: (state, action) =>
    compose(
      set(
        ["byUserId", action.payload.userid],
        action.payload.essays.map((essay) => essay._id)
      ),
      set(["byId"], makeEssaysById(state, action.payload.essays))
    )(state),
  [act.essaysSimulationRanking.type]: (state, action) =>
    set(
      ["bySimulationIdRanking", action.payload.simulationid],
      action.payload.results
    )(state),
  [act.essayStatusSet.type]: (state, action) =>
    compose(
      set(
        ["byId", action.payload.essayid, "review", "status"],
        action.payload.status
      ),
      set(["byId", action.payload.essayid, "status"], action.payload.status)
    )(state),
  [act.essaysInfo.type]: (state, action) => set("info", action.payload)(state)
});

export default essays;
