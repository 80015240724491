import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardBody,
  CardIcon,
  CardHeader,
  CardTitle
} from "components/Card";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import EssaySubmit from "views/Essay/Submit";
import { useEssaysSimulation } from "./hooks";
import styles from "./styles.module.css";

const formatDataToTable = (results = [], username) =>
  results
    .slice()
    .sort((a, b) => b.grade - a.grade)
    .map((d, index) => (
      <TableRow key={index}>
        <TableCell>{index + 1}</TableCell>
        <TableCell
          className={classNames(
            username === d.username && styles.owner,
            styles.item
          )}>
          {d.username}
        </TableCell>
        <TableCell
          className={classNames(
            username === d.username && styles.owner,
            styles.grade
          )}>
          {d.grade}
        </TableCell>
      </TableRow>
    ));

const Simulation = () => {
  const {
    loading,
    user,
    ranking
  } = useEssaysSimulation("6071c30f1903e92f0d211c96"); // hard coded for now. have a way to fetch latest applied simulation id
  const now = Date.now() / 1000;
  const [start, end] = user.simulationPeriod.length > 0 ? user.simulationPeriod : [0, 0];
  const canSubmitSimulations = start <= now && now <= end;
  return (
    <GridContainer>
      {canSubmitSimulations && (
        <GridItem xs={12}>
          <EssaySubmit isSimulation={true} />
        </GridItem>
      )}
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <EmojiEventsIcon />
            </CardIcon>
            <CardTitle title="Simulado Nacional de Redação - 11/04/2021" />
          </CardHeader>
          <CardBody>
            {loading && (
              <div className={styles.loading}>
                <CircularProgress size={24} />
              </div>
            )}
            {!loading && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Ranking</TableCell>
                      <TableCell>Aluno</TableCell>
                      <TableCell>Nota</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{formatDataToTable(ranking, user.name)}</TableBody>
                </Table>
              </TableContainer>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

Simulation.propTypes = {
  simulationId: PropTypes.number
};
Simulation.defaultProps = {
  simulationId: 772756
};

export default Simulation;
