import React, { useState, useCallback } from "react";
import Draggable from "react-draggable";
import { Card, CardHeader, CardBody } from "components/Card";
import styles from "./styles.module.css";
import { getReviewColorFromKind } from "./helpers";
// import TextEditor from "components/TextEditor";
import Button from "components/CustomButtons/Button";
import Delete from "@material-ui/icons/Delete";

const ReviewCard = ({
  initialCoords,
  kind = 0,
  reviewId,
  isEdit,
  onClose,
  bounds,
  onDelete,
  text: reviewText = "",
  onChange,
  children
}) => {
  const { title, color } = getReviewColorFromKind(kind);
  const [text, setText] = useState(reviewText);
  const handleChange = useCallback(
    (e) => {
      onChange(reviewId, e.target.value);
      setText(e.target.value);
    },
    [reviewId, onChange]
  );
  return (
    <>
      {children}
      <Draggable
        handle="#handle"
        defaultPosition={{ x: initialCoords.x, y: initialCoords.y }}
        position={null}
        bounds={bounds}>
        <div className={styles.reviewCardWrapper}>
          <Card className={styles.reviewCard}>
            <CardHeader
              icon
              id="handle"
              style={{ backgroundColor: color }}
              className={styles.reviewCardHeader}
              onClose={() => onClose(reviewId)}>
              <h4>{title}</h4>
            </CardHeader>
            <CardBody>
              {/* TODO: use TextEditor */}
              {/* <TextEditor
              onChange={handleChange}
              editorState={contentMap}
              readOnly={!isEdit}
            /> */}
              {/* TODO: autofocus at the end of the line */}
              <textarea
                className={styles.reviewText}
                value={text}
                autoFocus
                disabled={!isEdit}
                onChange={handleChange}
              />
              {isEdit && (
                <Button
                  onClick={() => onDelete(reviewId)}
                  style={{ backgroundColor: color }}
                  justIcon
                  round>
                  <Delete />
                </Button>
              )}
            </CardBody>
          </Card>
        </div>
      </Draggable>
    </>
  );
};

export default React.memo(ReviewCard);
