/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import AuthProvider from "./providers/Auth";
import NotificationProvider from "./providers/Notification";
import AuthLayout from "layouts/Auth/Auth";
import AdminLayout from "layouts/Admin/Admin";
import TeacherLayout from "layouts/Teacher/Teacher";
import StudentLayout from "layouts/Student/Student";
import store from "./redux";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import { EssaysProvider } from "hooks/essays";
import { MaterialsProvider } from "hooks/materials";
import SendEssayButton from "components/SendEssayButton";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const hist = createBrowserHistory();

ReactDOM.render(
  <ReduxProvider store={store}>
    <MaterialsProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <EssaysProvider>
          <NotificationProvider>
            <Router history={hist}>
              <Switch>
                <Route path="/auth" component={AuthLayout} />
                <AuthProvider>
                  {({ type }) => {
                    switch (type) {
                      case "student":
                        return (
                          <>
                            <SendEssayButton />
                            <Route path="/aluno" component={StudentLayout} />
                          </>
                        );
                      case "teacher":
                        return (
                          <Route path="/professor" component={TeacherLayout} />
                        );
                      case "juridical":
                        return (
                          <Route path="/professor" component={TeacherLayout} />
                        );
                      case "admin":
                        return <Route path="/admin" component={AdminLayout} />;
                      default:
                        return <Redirect from="/" to="/auth/login" />;
                    }
                  }}
                </AuthProvider>
              </Switch>
            </Router>
          </NotificationProvider>
        </EssaysProvider>
      </MuiPickersUtilsProvider>
    </MaterialsProvider>
  </ReduxProvider>,
  document.getElementById("root")
);
