import * as api from "lib/api";
import * as act from "./types";

export const onReviewEssay = (essayid, reviewerid) => async (dispatch) => {
  try {
    const response = await api.reviewNew(essayid, reviewerid);
    return dispatch(act.reviewNew(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onSaveEssayReview = (
  review,
  essayId,
  comment,
  grades,
  user,
  audioBlob
) => async (dispatch) => {
  try {
    const response = await api.reviewSave(
      review,
      essayId,
      comment,
      grades,
      user,
      audioBlob
    );

    return dispatch(act.reviews(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onDeleteAudio = (essayId) => async (dispatch) => {
  try {
    const response = await api.reviewDeleteAudio(essayId);
    return dispatch(act.reviews(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onSubmitEssayReview = (
  review,
  essayId,
  comment,
  grades,
  status,
  user,
  audioBlob
) => async (dispatch) => {
  try {
    const response = await api.reviewSubmit(
      review,
      essayId,
      comment,
      grades,
      status,
      user,
      audioBlob
    );
    return dispatch(act.reviews(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onFetchReviewsByEssay = (essayId) => async (dispatch) => {
  try {
    const response = await api.reviewsByEssay(essayId);
    return dispatch(act.reviews(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onReviewStatusChange = (essayid, status) => async (dispatch) => {
  try {
    const response = await api.reviewStatusChange(essayid, status);
    return dispatch(act.essayStatusSet(response.data));
  } catch (err) {
    throw err.response;
  }
};
