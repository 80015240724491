import React from "react";
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

const FileUpload = ({ fileName, onChange, className, acceptedFiles, ...props }) => {
  
  return (
    <div style={{ display: "flex", justifyContent: "center"}}>
      <input
        id="button-file"
        {...props}
        type="file"
        onChange={onChange}
        className={className}
        hidden
        // style={{ display: "none" }}
        accept={acceptedFiles}
      />
      <label htmlFor="button-file" style={{ paddingTop: "20px"}}>
        <Button
          variant="contained"
          style={{ backgroundColor: "#47B6CB", color: "white" }}
          startIcon={<SaveIcon />}
          component="span"
        >
          { fileName ? fileName: "Redação" }
        </Button>
      </label>        
    </div>
  );
};

export default React.memo(FileUpload);
