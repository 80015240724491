import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import useNotification from "utils/hooks/useNotification";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import stylesAdmin from "./styles.module.css";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "@material-ui/core/Button";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};
const useStyles = makeStyles(styles);

const AddInstitutionModal = ({ open, handleClose, onInstitutionNew }) => {
  const classes = useStyles();

  const { setNotificationSuccess, setNotificationError } = useNotification();

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: async ({ name }) => {
      try {
        setLoading(true);
        await onInstitutionNew(name);
        setLoading(false);
        setNotificationSuccess("Instituição criada com sucesso");
        handleClose();
      } catch (err) {
        setNotificationError(err.data.ErrorMessage);
        handleClose();

        throw err;
      }
    }
  });

  const isDisabled = formik.values.name === "";

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
      > 
        <form onSubmit={formik.handleSubmit} className={stylesAdmin.submitForm}>
          <DialogContent >
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <AccountBalanceIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}> Cadastrar uma Instituição</h4>
              </CardHeader>
              <CardBody className={stylesAdmin.addContentDialog}>
                <CustomInput
                  labelText="Nome da Instituição"
                  id="name"
                  name="name"
                  color="primary"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: formik.handleChange,
                  }}
                />
              </CardBody>

            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="text" color="danger">
              Cancelar
            </Button>
            <Button onClick={formik.handleSubmit} disabled={isDisabled} style={{ color: "#9d36b3" }}>
              {!loading ? (
                "Cadastrar"
              ) : (
                <CircularProgress size={20} color="primary" />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddInstitutionModal;
