import React, { useCallback, useState } from "react";
import * as Yup from "yup";

import useNotification from "utils/hooks/useNotification";
import { useFormik } from "formik";
import { http } from "lib/api";
import { useMaterialsContext } from "hooks/materials";

import { FiUpload, FiArrowLeft, FiCheck } from "react-icons/fi";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@material-ui/core";

import {
  BASIC_MATERIAL_CATEGORIES,
  VIDEO_MATERIAL_CATEGORIES
} from "../../constants";

import * as S from "./styles";

function EditMaterial({ material }) {
  const categoriesToLoad =
    material?.content_type === "ebook"
      ? BASIC_MATERIAL_CATEGORIES
      : VIDEO_MATERIAL_CATEGORIES;

  const { setCurrentPage } = useMaterialsContext();

  const { setNotificationSuccess, setNotificationError } = useNotification();

  const handleEditVideo = useCallback(
    async (data) => {
      try {
        const formData = new FormData();

        formData.append("id", material?.id);
        formData.append("name", data.name);
        formData.append("description", data.description);
        formData.append("contentType", data.contentType);
        formData.append("category", data.category);
        formData.append("thumbnail", data.thumbnail);
        formData.append("videoUrl", data.videoUrl);
        formData.append("videoPdf", data.videoPdf);

        await http.put("materials/videos", formData);
        setNotificationSuccess("Vídeo editado com sucesso!");
        setCurrentPage("");
      } catch (err) {
        setNotificationError(err);
      }
    },
    [material?.id]
  );

  const handleEditEbook = useCallback(
    async (data) => {
      try {
        const formData = new FormData();

        const { thumbnail, materialEbook, ...materialObj } = data;

        formData.append("id", material?.id);
        formData.append("materialEbook", materialEbook);
        formData.append("thumbnail", thumbnail);
        formData.append("document", JSON.stringify(materialObj));

        await http.put("materials/ebooks", formData);
        setNotificationSuccess("E-book editado com sucesso");
        setCurrentPage("");
      } catch (err) {
        setNotificationError(err.ErrorMessage);
      }
    },
    [material?.id]
  );

  const formik = useFormik({
    initialValues: {
      name: material?.name,
      description: material?.description,
      contentType: material?.content_type,
      category: material?.category,
      materialEbook: null,
      thumbnail: null,
      videoUrl: "",
      videoPdf: null
    },
    onSubmit: async (data) => {
      try {
        const schema = Yup.object().shape({
          name: Yup.string().required("Digite um Nome"),
          description: Yup.string().required("Digite uma descrição"),
          contentType: Yup.string().required("Selecione o tipo do material"),
          category: Yup.number().required("Categoria inválida"),
          materialEbook: "",
          thumbnail: "",
          videoUrl: data.videoUrl
            ? Yup.string()
                .url("Digite uma url válida")
                .matches(
                  /^https:\/\/vimeo\.com\/[0-9]{9,12}$/,
                  "Url do vimeo inválida."
                )
            : Yup.string()
        });
        await schema.validate(data, {
          abortEarly: false
        });
        if (data.contentType === "video") await handleEditVideo(data);
        else await handleEditEbook(data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.errors.forEach((error) => {
            setNotificationError(error);
          });
        }
      }
    },
    enableReinitialize: true
  });

  return (
    <S.Container>
      <h1>
        <FiArrowLeft onClick={() => setCurrentPage("show-videos")} />
        Edição de Material
      </h1>

      <form onSubmit={formik.handleSubmit} id="add-material-form">
        <S.Content>
          <TextField
            name="name"
            label="Nome"
            id=""
            type="text"
            onChange={formik.handleChange}
            value={formik.values.name}
          />

          <TextField
            name="description"
            label="Descrição"
            type="text"
            multiline
            onChange={formik.handleChange}
            value={formik.values.description}
          />

          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Categoria
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={formik.values.category}
              onChange={(e) => {
                formik.setFieldValue("category", e.target.value);
              }}
              autoWidth
              label="Categoria">
              {categoriesToLoad.map((category, index) => (
                <MenuItem key={category} value={index + 1}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {material?.content_type === "video" ? (
            <>
              <S.MaterialElement>
                <TextField
                  label="Video Url"
                  id=""
                  type="url"
                  name="videoUrl"
                  onChange={formik.handleChange}
                  value={formik.values.videoUrl}
                  placeholder="(opcional - mude apenas se for substituir o vídeo)"
                />
              </S.MaterialElement>
              <S.MaterialElement>
                <Button color="primary" variant="contained" component="label">
                  {formik.values.videoPdf ? <FiCheck /> : <FiUpload />} Upload
                  de PDF
                  <input
                    type="file"
                    accept="application/pdf"
                    hidden
                    onChange={(e) =>
                      formik.setFieldValue("videoPdf", e.target.files[0])
                    }
                  />
                </Button>
              </S.MaterialElement>
            </>
          ) : (
            <S.MaterialElement>
              <Button color="primary" variant="contained" component="label">
                {formik.values.materialEbook ? <FiCheck /> : <FiUpload />}{" "}
                Upload E-book (PDF)
                <input
                  type="file"
                  accept="application/pdf"
                  hidden
                  onChange={(e) => {
                    formik.setFieldValue("materialEbook", e.target.files[0]);
                  }}
                />
              </Button>
            </S.MaterialElement>
          )}
          <S.MaterialElement>
            <Button color="primary" variant="contained" component="label">
              {formik.values.thumbnail ? <FiCheck /> : <FiUpload />} Upload de
              Thumbnail
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={(e) => {
                  formik.setFieldValue("thumbnail", e.target.files[0]);
                }}
              />
            </Button>
          </S.MaterialElement>
          <S.SubmitButton>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              form="add-material-form">
              Salvar
            </Button>
          </S.SubmitButton>
        </S.Content>
      </form>
    </S.Container>
  );
}

export default EditMaterial;
