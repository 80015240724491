import axios from "axios";
import dotenv from "dotenv";

dotenv.config();

export const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? "http://localhost:4443/api/",
  withCredentials: true,
  timeout: 120000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    Cookie: "custom=her;"
  }
});

const makeFileRequest = (url, file, userid, contestid, topic) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data"
    }
  };
  const formData = new FormData();
  formData.append("userid", userid);
  //formData.append("contestid", contestid);
  formData.append("topic", topic);
  formData.append("essay", file);

  return http.post(url, formData, config);
};

// Auth routes
export const login = (email, password) =>
  http.post("login", { username: email, password });

export const logout = () => http.get("logout");

export const register = (name, email, password, type, credits) =>
  http.post("register", { name, email, password, type, credits });

export const signup = (name, email, password, token) =>
  http.post("signup", { name, email, password, token });

export const session = () => http.get("session");

// Essay Routes
export const essaysInfo = () => http.get("essays/info");

export const essays = () => http.get("essays/metadata");

export const essay = (id) => http.get(`essay/${id}`);

export const essayNew = (essay, userid, contestid, topic) =>
  makeFileRequest("essay", essay, userid, contestid, topic);

export const essaysByUserId = (userid) => http.get(`essay/user/${userid}`);

export const essaysSimulations = (contestid) =>
  http.get(`essay/ranking/${contestid}`);

// Review routes
export const reviewNew = (essayid, reviewerid) =>
  http.post("review", { essayid, reviewerid });

export const reviewSave = async (
  reviews,
  essayid,
  comment = "",
  grades = {},
  user,
  audioBlob = null
) => {
  const formData = new FormData();

  formData.append("reviews", JSON.stringify(reviews));
  formData.append("essayid", essayid);
  formData.append("comment", comment);
  formData.append("grades", JSON.stringify(grades));
  formData.append("user", JSON.stringify(user));

  if (audioBlob) formData.append("audio", audioBlob, "feedback.wav");

  return http.patch("review", formData, {
    headers: {
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
    }
  });
};

export const reviewDeleteAudio = (essayid) =>
  http.delete(`review/audio/${essayid}`);

export const reviewSubmit = (
  reviews,
  essayid,
  comment = "",
  grades = {},
  status,
  user,
  audioBlob = null
) => {
  const formData = new FormData();

  formData.append("reviews", JSON.stringify(reviews));
  formData.append("essayid", essayid);
  formData.append("comment", comment);
  formData.append("grades", JSON.stringify(grades));
  formData.append("status", status);
  formData.append("user", JSON.stringify(user));

  if (audioBlob) formData.append("audio", audioBlob, "feedback.wav");

  return http.post("review/submit", formData, {
    headers: {
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
    }
  });
};

export const reviewStatusChange = (essayid, status) =>
  http.post("review/statusset", { essayid, status });

export const reviewsByEssay = (essayid) => http.get(`review/${essayid}`);

// Users routes
export const users = () => http.get("users");

export const userDelete = (data) => http.delete(`users/${data._id}`);

export const userDetails = (id) => http.get(`users/${id}`);

export const adminUserUpdate = (
  id,
  name,
  email,
  type,
  credits,
  specialClass,
  ignoreWeeklyCreditsExpiration,
  creditsExpiresAt
) =>
  http.patch("admin/users", {
    userid: id,
    name,
    email,
    type,
    credits,
    specialClass,
    ignoreWeeklyCreditsExpiration,
    creditsExpiresAt
  });

export const userUpdate = (id, name, email, password) =>
  http.patch("users", { userid: id, name, email, password });

export const userAdminStats = () => http.get("users/adminstats");

// User password routes
export const adminUserPasswordChange = (userid, password) =>
  http.post("admin/users/password/change", { userid, password });

export const userPasswordChange = (userid, password, newpassword) =>
  http.post("users/password/change", { userid, newpassword, password });

export const userPasswordRequestReset = (email) =>
  http.post("auth/password/reset", { email });

export const userPasswordConfirmReset = (password, token) =>
  http.post("auth/password/confirm", { password, token });

// Institution routes
export const institutions = () => http.get("institutions");

export const institutionNew = (name) => http.post("institutions", { name });

export const institutionUpdate = (institutionid, name) =>
  http.patch("institutions", { institutionid, name });

export const institutionDelete = (id) => http.delete(`institutions/${id}`);

// Contests routes
export const contests = () => http.get("contests");

export const contestNew = (name, institution, topics, date) =>
  http.post("contests", { name, institution, topics, date });

export const contestUpdate = (contestid, name, institutionid, topics, date) =>
  http.patch("contests", { contestid, name, institutionid, topics, date });

export const contestDelete = (id) => http.delete(`contests/${id}`);

export const contestsSimulations = () => http.get(`contests/simulations`);

// Topics routes
export const topics = () => http.get("topics");

export const topicNew = (
  name,
  number,
  supportText,
  type,
  commentedTopicPDF,
  essayModelPDF,
  tagsIds,
  commentText
) => {
  const formData = new FormData();

  formData.append("name", name);
  formData.append("number", number);
  formData.append("supportText", supportText);
  formData.append("type", type);
  formData.append("commentedTopicPDF", commentedTopicPDF);
  formData.append("essayModelPDF", essayModelPDF);
  formData.append("tagsIds", JSON.stringify(tagsIds));
  formData.append("commentText", commentText);

  return http.post("topics", formData, {
    headers: {
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
    }
  });
};

export const topicUpdate = (
  topicid,
  name,
  number,
  supportText,
  type,
  commentedTopicPDF,
  essayModelPDF,
  tagsIds,
  commentText
) => {
  const formData = new FormData();

  formData.append("topicid", topicid);
  formData.append("name", name);
  formData.append("number", number);
  formData.append("supportText", supportText);
  formData.append("type", type);
  formData.append("commentedTopicPDF", commentedTopicPDF);
  formData.append("essayModelPDF", essayModelPDF);
  formData.append("tagsIds", JSON.stringify(tagsIds));
  formData.append("commentText", commentText);

  return http.patch("topics", formData, {
    headers: {
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
    }
  });
};

export const topicDelete = (id) => http.delete(`topics/${id}`);

// Rate routes

export const rates = () => http.get("rates");

export const rateByReviewId = (reviewId) =>
  http.get(`rates/review/${reviewId}`);

export const rateSave = (
  reviewId,
  evaluatorId,
  correctionRating,
  correctorRating,
  comment
) =>
  http.post("rates", {
    reviewId,
    evaluatorId,
    correctionRating,
    correctorRating,
    comment
  });

export const rateUpdate = (rateId, quality, speed, communication) =>
  http.patch("rates", { rateId, quality, speed, communication });

export const rateDelete = (rateId) => http.delete(`rates/${rateId}`);

export const rateAverageByCorrector = (correctorId) =>
  http.get(`rates/average/corrector/${correctorId}`);

export const rateGeneralAverage = () => http.get(`rates/average/general`);
