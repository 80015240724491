import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "./Navbar.jsx";
import Footer from "components/Footer/Footer.js";
import Sidebar from "../Sidebar.jsx";
import { studentRoutes } from "routes.js";
import NavbarLinks from "../NavbarLinks";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { useSelector } from "react-redux";
import hasPermission from "utils/hasPermissions.js";
import StudentInfoModal from "components/StudentInfoModal";
import CreditsModalBanner from "components/CreditsModalBanner";

var ps;

const useStyles = makeStyles(styles);

const StudentLayout = (props) => {
  const { ...rest } = props;
  // states and functions

  const { permissions } = useSelector(({ auth }) => auth.session.user);

  const [openInfoModal, setOpenInfoModal] = React.useState(
    !hasPermission(permissions, "Material", "read")
  );
  const [openCreditModal, setOpenCreditModal] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const color = "blue";
  const bgColor = "white";
  const logo = require("assets/img/minhadiscursiva.jpeg");
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Aluno";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !==
            -1 &&
          !routes[i].skip
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/aluno") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact={prop.exact}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const allowedRoutes = studentRoutes.filter(
    (route) =>
      !route.permission || hasPermission(permissions, route.permission, "read")
  );
  const handlerOpenModals = () => {
    setOpenCreditModal(true);
    setOpenInfoModal(false);
  };
  return (
    <>
      <CreditsModalBanner
        isOpen={openCreditModal}
        onClose={() => setOpenCreditModal((prev) => !prev)}
      />
      {!hasPermission(permissions, "Material", "read") && (
        <StudentInfoModal isOpen={openInfoModal} onClose={handlerOpenModals} />
      )}
      <div className={classes.wrapper}>
        <Sidebar
          routes={allowedRoutes.filter((r) => !r.skip)}
          logo={logo}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          bgColor={bgColor}
          miniActive={miniActive}
          NavbarLinks={NavbarLinks}
          {...rest}
        />
        <div className={mainPanelClasses} ref={mainPanel}>
          <AdminNavbar
            sidebarMinimize={sidebarMinimize.bind(this)}
            miniActive={miniActive}
            brandText={getActiveRoute(allowedRoutes)}
            handleDrawerToggle={handleDrawerToggle}
            {...rest}
          />

          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(allowedRoutes)}
                <Redirect from="/aluno" to="/aluno/dashboard" />
              </Switch>
            </div>
          </div>

          <Footer fluid />
        </div>
      </div>
    </>
  );
};

export default StudentLayout;
