import React, { useCallback, useState } from "react";
import * as Yup from "yup";

import useNotification from "utils/hooks/useNotification";
import { useFormik } from "formik";
import { http } from "lib/api";
import { useMaterialsContext } from "hooks/materials";

import { FiUpload, FiArrowLeft, FiCheck } from "react-icons/fi";
import {
  FormLabel,
  RadioGroup,
  TextField,
  FormControlLabel,
  Radio,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@material-ui/core";

import {
  BASIC_MATERIAL_CATEGORIES,
  VIDEO_MATERIAL_CATEGORIES
} from "../../constants";

import * as S from "./styles";

function AddMaterial() {
  const { setCurrentPage } = useMaterialsContext();
  const [contentType, setcontentType] = useState("ebook");

  const categoriesToLoad =
    contentType === "ebook"
      ? BASIC_MATERIAL_CATEGORIES
      : VIDEO_MATERIAL_CATEGORIES;

  const { setNotificationSuccess, setNotificationError } = useNotification();

  const handleCreateVideo = useCallback(async (data) => {
    try {
      const formData = new FormData();

      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("contentType", data.contentType);
      formData.append("category", data.category);
      formData.append("thumbnail", data.thumbnail);
      formData.append("videoUrl", data.videoUrl);
      formData.append("videoPdf", data.videoPdf);

      await http.post("materials/videos", formData);
      setNotificationSuccess("Vídeo adicionado com sucesso!");
      setCurrentPage("");
    } catch (err) {
      setNotificationError(err);
    }
  }, []);

  const handleCreateEbook = useCallback(async (data) => {
    try {
      const formData = new FormData();

      const { thumbnail, materialEbook, ...materialObj } = data;

      formData.append("materialEbook", materialEbook);
      formData.append("thumbnail", thumbnail);
      formData.append("document", JSON.stringify(materialObj));

      await http.post("materials/ebooks", formData);
      setNotificationSuccess("E-book adicionado com sucesso");
      setCurrentPage("");
    } catch (err) {
      setNotificationError(err);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      contentType: "ebook",
      category: 1,
      materialEbook: null,
      thumbnail: null,
      videoUrl: "",
      videoPdf: null
    },
    onSubmit: async (data) => {
      try {
        const schema = Yup.object().shape({
          name: Yup.string().required("Digite um Nome"),
          description: Yup.string().required("Digite uma descrição"),
          contentType: Yup.string().required("Selecione o tipo do material"),
          category: Yup.number().required("Categoria inválida"),
          materialEbook: "",
          thumbnail: "",
          videoUrl: data.videoUrl
            ? Yup.string()
                .url("Digite uma url válida")
                .matches(/^https:\/\/vimeo\.com\//, "Url do vimeo inválida.")
            : Yup.string()
        });
        await schema.validate(data, {
          abortEarly: false
        });
        if (data.contentType === "video") await handleCreateVideo(data);
        else await handleCreateEbook(data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          err.errors.forEach((error) => {
            setNotificationError(error);
          });
        }
      }
    },
    enableReinitialize: true
  });

  return (
    <S.Container>
      <h1>
        <FiArrowLeft onClick={() => setCurrentPage("")} />
        Cadastro de Materiais
      </h1>

      <form onSubmit={formik.handleSubmit} id="add-material-form">
        <S.Content>
          <TextField
            name="name"
            label="Nome"
            id=""
            type="text"
            onChange={formik.handleChange}
            value={formik.values.name}
          />

          <TextField
            name="description"
            label="Descrição"
            type="text"
            multiline
            onChange={formik.handleChange}
            value={formik.values.description}
          />

          <S.LabelContainer>
            <FormLabel component="h4">Tipo do Material</FormLabel>
          </S.LabelContainer>

          <RadioGroup
            aria-label="category"
            defaultValue="ebook"
            name="contentType"
            onChange={formik.handleChange}
            value={formik.values.contentType}>
            <FormControlLabel
              value="ebook"
              control={<Radio color="primary" />}
              label="E-Book"
              onClick={() => setcontentType("ebook")}
            />
            <FormControlLabel
              value="video"
              control={<Radio color="primary" />}
              label="Video"
              onClick={() => setcontentType("video")}
            />
          </RadioGroup>

          <FormControl sx={{ m: 1, minWidth: 80 }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Categoria
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={formik.values.category}
              onChange={(e) => {
                formik.setFieldValue("category", e.target.value);
              }}
              autoWidth
              label="Categoria">
              {categoriesToLoad.map((category, index) => (
                <MenuItem key={category} value={index + 1}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {contentType === "video" ? (
            <>
              <S.MaterialElement>
                <TextField
                  label="Video Url"
                  id=""
                  type="url"
                  name="videoUrl"
                  onChange={formik.handleChange}
                  value={formik.values.videoUrl}
                />
              </S.MaterialElement>
              <S.MaterialElement>
                <Button color="primary" variant="contained" component="label">
                  {formik.values.videoPdf ? <FiCheck /> : <FiUpload />} Upload
                  de PDF
                  <input
                    type="file"
                    accept="application/pdf"
                    hidden
                    onChange={(e) =>
                      formik.setFieldValue("videoPdf", e.target.files[0])
                    }
                  />
                </Button>
              </S.MaterialElement>
            </>
          ) : (
            <S.MaterialElement>
              <Button color="primary" variant="contained" component="label">
                {formik.values.materialEbook ? <FiCheck /> : <FiUpload />}{" "}
                Upload E-book (PDF)
                <input
                  type="file"
                  accept="application/pdf"
                  hidden
                  onChange={(e) => {
                    formik.setFieldValue("materialEbook", e.target.files[0]);
                  }}
                />
              </Button>
            </S.MaterialElement>
          )}
          <S.MaterialElement>
            <Button color="primary" variant="contained" component="label">
              {formik.values.thumbnail ? <FiCheck /> : <FiUpload />} Upload de
              Thumbnail
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={(e) => {
                  formik.setFieldValue("thumbnail", e.target.files[0]);
                }}
              />
            </Button>
          </S.MaterialElement>
          <S.SubmitButton>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              form="add-material-form">
              Enviar
            </Button>
          </S.SubmitButton>
        </S.Content>
      </form>
    </S.Container>
  );
}

export default AddMaterial;
