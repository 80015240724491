import styled from "styled-components";

export const FilterContainer = styled.div`
  margin: 20px 0;

  display: flex;

  align-items: center;

  gap: 30px;
`;

export const SearchInput = styled.div`
  border-radius: 30px;

  padding: 5px;

  display: flex;

  align-items: center;
  justify-content: center;

  border: 2px solid #00acc1;

  width: 300px;
  height: 40px;

  input {
    border: 0;

    width: 100%;
  }
`;
