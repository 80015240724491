import React, { useState } from "react";
import Button from "@material-ui/core/Button/Button";
import { validateUserPasswordChange } from "validators.js";
import CustomInput from "../../components/CustomInput/CustomInput";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import PropTypes from "prop-types";
import style from "./styles.module.css";
import { useFormik } from "formik";
import { useNotification } from "utils/hooks";
import handleError from "utils/handleError";

const PasswordChange = ({ onUserPasswordChange }) => {
  const [loading, setLoading] = useState(false);
  const { setNotificationSuccess, setNotificationError } = useNotification();
  const formik = useFormik({
    initialValues: {
      password: "",
      newpasswordconfirm: "",
      newpassword: ""
    },
    onSubmit: async ({ password, passwordconfirm, newpassword }) => {
      try {
        setLoading(true);

        await validateUserPasswordChange({
          password,
          passwordconfirm
        });
        await onUserPasswordChange(password, newpassword);

        setLoading(false);
        setNotificationSuccess("Informações atualizadas com sucesso!");
      } catch (err) {
        setLoading(false);
        handleError(err, setNotificationError);
      }
    },
    enableReinitialize: true
  });

  return (
    <form onSubmit={formik.handleSubmit} className={style.form}>
      <CustomInput
        labelText="Senha Atual"
        id="password"
        name="password"
        color="primary"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: formik.handleChange,
          type: "password"
        }}
      />
      <CustomInput
        labelText="Nova Senha"
        id="newpassword"
        name="newpassword"
        color="primary"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: formik.handleChange,
          type: "password"
        }}
      />
      <CustomInput
        labelText="Confirmar Nova Senha"
        id="newpasswordconfirm"
        name="newpasswordconfirm"
        color="primary"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: formik.handleChange,
          type: "password"
        }}
      />

      <Button type="submit">
        {!loading ? "Atualizar" : <CircularProgress color="primary" />}
      </Button>
    </form>
  );
};
PasswordChange.propTypes = {
  onUserPasswordChange: PropTypes.func.isRequired
};
export default PasswordChange;
