import React, { useState } from "react";
import moment from "moment";

//? Components...
import EmailInput from "components/LoginInputs/EmailInput";
import PasswordInput from "components/LoginInputs/PasswordInput";
import DATES from "../../utils/bannerDates";
import handleError from "../../utils/handleError";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { Link } from "react-router-dom";
import Input from "../../components/Input/Input";
import { useLogin } from "./hooks";
import useNotification from "../../utils/hooks/useNotification";
import { USER_MODE_LABELS } from "../../constants";
import { validateLogin } from "validators.js";
import { useFormik } from "formik";

//? Stylesheets...
import * as S from "./login-styles";
import styles from "./styles.module.css";

//? Images...
import AriImageLogin from "components/AriImageLogin/AriImageLogin";  //* Imagem do Ari!

//? Icons...
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";  //! Usado nos logos das redes sociais!
import { faFacebook, faTwitter, faYoutube, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";

const Login = () => {
  const { setNotificationSuccess, setNotificationError } = useNotification();
  const [loading, setLoading] = useState(false);
  const { onLogin } = useLogin();
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    onSubmit: async (data) => {
      try {
        setLoading(true);
        await validateLogin({ email: data.email, password: data.password });

        const response = await onLogin(data.email.toLowerCase(), data.password);

        setLoading(false);

        setNotificationSuccess("Sessão criada com sucesso");

        // history.push(`/${USER_MODE_LABELS[response.type]}/dashboard`);

        window.location.pathname = `/${
          USER_MODE_LABELS[response.type]
        }/dashboard`;
      } catch (err) {
        setLoading(false);
        handleError(err, setNotificationError);
      }
    }
  });

  // eslint-disable-next-line no-unused-vars
  let creditsBanner = "";
  // eslint-disable-next-line no-unused-vars
  let bannerLink = "";

  const NewDate = moment(new Date());
  const startDateLote1 = moment(DATES.START_DATE_LOTE1);
  const endDateLote1 = moment(DATES.END_DATE_LOTE1);
  const startDateLote2 = moment(DATES.START_DATE_LOTE2);
  const endDateLote2 = moment(DATES.END_DATE_LOTE2);

  if (NewDate.isBetween(startDateLote1, endDateLote1)) {
    creditsBanner =
      "https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote1/356x75 LOTE 1.png";
    bannerLink =
      "https://pay.hotmart.com/X73056678F?checkoutMode=10&offDiscount=RENOVACAO&split=12&bid=1681930251669";
  } else if (NewDate.isBetween(startDateLote2, endDateLote2)) {
    creditsBanner =
      "https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote2/356x75 LOTE 2.png";
    bannerLink =
      "https://pay.hotmart.com/X73056678F?checkoutMode=10&offDiscount=RENOVACAO2&split=12&bid=1681930265563";
  }

  return (
    <S.Container>
      <S.SocialMediaContainer>
        <S.SocialMediaText>OBRABODAREDACAO</S.SocialMediaText>

        <S.SocialMediaIcons>
          <FontAwesomeIcon icon={faFacebook} size="lg" color={"#ff8000"} />
          <FontAwesomeIcon icon={faTwitter} size="lg" color={"#ff8000"} />
          <FontAwesomeIcon icon={faYoutube} size="lg" color={"#ff8000"} />
          <FontAwesomeIcon icon={faInstagram} size="lg" color={"#ff8000"} />
        </S.SocialMediaIcons>
      </S.SocialMediaContainer>

      <S.MainContent>
        <S.FormContainer onSubmit={formik.handleSubmit}>
          <S.LogosContainer>
            <S.Logo src={require("../../assets/img/login/logo.png")} />
          </S.LogosContainer>

          <S.InputsContainer>
            <EmailInput
              onChange={(value) => formik.setFieldValue("email", value)}
              required
            />

            <PasswordInput
              onChange={(value) => formik.setFieldValue("password", value)}
              required
            />
          </S.InputsContainer>

          <S.ButtonContainer>
            <S.Button type="submit" label="Entrar">
              {
                !loading ? (
                  "Acessar a plataforma"
                ) : (
                  <CircularProgress color="primary" />
                )
              }
            </S.Button>

            <Link to="/auth/redefinir-senha" style={{ color: "#FFFFFF" }}>
              <FontAwesomeIcon icon={faLock} /> Esqueceu sua senha?
            </Link>
          </S.ButtonContainer>
        </S.FormContainer>

        <S.AriContainer>
          <AriImageLogin />
        </S.AriContainer>
      </S.MainContent>
    </S.Container>
  );
};

export default Login;
