import { createAction } from "@reduxjs/toolkit";

// Auth actions
export const login = createAction("LOGIN");
export const logout = createAction("LOGOUT");
export const register = createAction("REGISTER");
export const session = createAction("SESSION");

// Reviews actions
export const reviewNew = createAction("REVIEW_NEW");
export const reviews = createAction("REVIEWS_FETCH");

// Essays actions
export const essays = createAction("ESSAYS_FETCH");
export const essaysInfo = createAction("ESSAYS_FETCH_INFO");
export const essay = createAction("ESSAY");
export const essayNew = createAction("ESSAY_NEW");
export const essaysByUserId = createAction("ESSAYS_BY_USERID");
export const essaysSimulationRanking = createAction(
  "ESSAYS_SIMULATION_RANKING"
);
export const essayStatusSet = createAction("ESSAY_STATUS_SET");

// Users actions
export const users = createAction("USERS_FETCH");
export const userDelete = createAction("USER_DELETE");
export const userUpdate = createAction("USER_UPDATE");
export const userSelfUpdate = createAction("USER_SELF_UPDATE");
export const userAdminStats = createAction("USER_ADMIN_STATS");

// Institutions actions
export const institutions = createAction("INSTITUTIONS");
export const institutionNew = createAction("INSTITUTION_NEW");
export const institutionUpdate = createAction("INSTITUTION_UPDATE");
export const institutionDelete = createAction("INSTITUTION_DELETE");

// Contests actions
export const contests = createAction("CONTESTS");
export const contestNew = createAction("CONTEST_NEW");
export const contestUpdate = createAction("CONTEST_UPDATE");
export const contestDelete = createAction("CONTEST_DELETE");

// Topics actions
export const topics = createAction("TOPICS");
export const topicNew = createAction("TOPIC_NEW");
export const topicUpdate = createAction("TOPIC_UPDATE");
export const topicDelete = createAction("TOPIC_DELETE");
