import React from "react";

//? Icons...
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

//? Stylesheets...
import * as S from "./email.style";

const EmailInput = ({onChange}) => {
  return (
    <S.Container>
      <S.InputWrapper>
        <S.IconArea>
          <FontAwesomeIcon icon={faUser} size="lg" />
        </S.IconArea>

        <S.Input
          placeholder="E-mail"
          name="email"
          type="mail"
          onChange={(value)=> onChange(value.target.value)}
        />
      </S.InputWrapper>
    </S.Container>
  );
}

export default EmailInput;
