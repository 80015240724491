import React from "react";
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";

const DatePicker = ({ setValue, value }) => (
  <RangeDatePicker
    startDate={value[0]}
    endDate={value[1]}
    onChange={(startDate, endDate) => setValue(startDate, endDate)}
    minDate={new Date(2021, 1, 1)}
    maxDate={new Date()}
    dateFormat="DD/MM"
    monthFormat="MMM YYYY"
    startDatePlaceholder="De"
    endDatePlaceholder="Até"
    disabled={false}
    className="my-own-class-name"
    startWeekDay="sunday"
  />
);
export default DatePicker;
