import React, { useCallback } from "react";
import { BiUserCircle } from "react-icons/bi";
import { useNotification } from "utils/hooks";
// Components
import CreditsBanner from "components/CreditsBanner";

// Styles
import * as S from "./styles";

function HelpPage() {
  const { setNotificationSuccess } = useNotification();

  const EMAIL = "contato@minhadiscursiva.com";

  const handleEmailClick = useCallback(() => {
    navigator.clipboard.writeText(EMAIL);

    setNotificationSuccess('E-mail copiado para a área de transferência!');
  }, []);

  return (
    <div>
      <CreditsBanner />
      <S.Container>
        <S.Content>
          <S.IconContainer>
            <BiUserCircle size={40} />
          </S.IconContainer>

          <p>Dúvidas? Sugestões? Reclamações? Elogios? Envie um e-mail para:</p>

          <S.Link onClick={handleEmailClick}>EMAIL</S.Link>
        </S.Content>
      </S.Container>
    </div>
  );
}

export default HelpPage;
