import { useDispatch, useSelector } from "react-redux";
import * as authActions from "redux/actions/auth";
import * as authSelector from "redux/selectors/auth";

export function useLogin() {
  const dispatch = useDispatch();
  const authenticated = useSelector(authSelector.authenticated);
  const authenticatedUser = useSelector(authSelector.authenticatedUser);

  const onLogin = (email, pwd) => dispatch(authActions.onLogin(email, pwd));

  return {
    authenticated,
    authenticatedUser,
    onLogin
  };
}

export function useRegister() {
  const dispatch = useDispatch();
  const onRegister = (name, email, pwd, type) =>
    dispatch(authActions.onRegister(name, email, pwd, type, 0));

  return {
    onRegister
  };
}

export function useSignUp() {
  const dispatch = useDispatch();
  const onSignUp = (name, email, password, token) =>
    dispatch(authActions.onSignUp(name, email, password, token));

  return {
    onSignUp
  };
}

export function usePasswordReset() {
  const dispatch = useDispatch();
  const onUserPasswordReset = (email) =>
    dispatch(authActions.onUserPasswordReset(email));
  const onUserConfirmPasswordReset = (password, token) =>
    dispatch(authActions.onUserConfirmPasswordReset(password, token));

  return {
    onUserConfirmPasswordReset,
    onUserPasswordReset
  };
}
