/*eslint-disable*/
import React, { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import matchSorter from "match-sorter";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { useEssaysContext } from "hooks/essays";
import { ADMIN_MODE } from "constants.js";

const newStyles = {
  ...styles,
  formControlMargins: {
    margin: "3px 0 !important",
  },
  gridContainer: {
    justifyContent: "center",
  },
};

const useStyles = makeStyles(newStyles);

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;
  return (
    <CustomInput
      formControlProps={{
        fullWidth: true,
      }}
      inputProps={{
        value: filterValue || "",
        onChange: (e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        },
        placeholder: `Pesquisar em ${count} resultados`,
        fontSize: "20px",
      }}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data, userType, dateRange }) {
  const {
    setQueryFilters,
    queryFilters,
    pageParams,
    setPageParams,
    currentPage,
    setCurrentPage,
    essaysLoading
  } = useEssaysContext();

  //const [selectTopLocation, ]

  const [pageSelect, handlePageSelect] = React.useState(0);
  const classes = useStyles();

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const applyFilter = debounce((searchText, columnId) => {
    if (!setQueryFilters)
      return;

    setCurrentPage(1);

    switch (columnId) {
      case 'id':
        setQueryFilters({ ...queryFilters, id: searchText });
        break;

      case 'student':
        setQueryFilters({ ...queryFilters, name: searchText });
        break;

      case 'name':
        setQueryFilters({ ...queryFilters, name: searchText });
        break;

      case 'type':
        setQueryFilters({ ...queryFilters, type: searchText });
        break;

      case 'email':
        setQueryFilters({ ...queryFilters, email: searchText });
        break;

      case 'contest':
        if (userType === ADMIN_MODE)
          setQueryFilters({ ...queryFilters, email: searchText });
        else
          setQueryFilters({ ...queryFilters, contestName: searchText });
        break;

      case 'grades':
        setQueryFilters({ ...queryFilters, grade: searchText });
        break;

      case 'date':
        setQueryFilters({ ...queryFilters, createdAt: searchText });
        break;

      case 'createdAt':
        setQueryFilters({ ...queryFilters, createdAt: searchText });
        break;

      case 'datereview':
        setQueryFilters({ ...queryFilters, updatedAt: searchText });
        break;

      case 'reviewer':
        setQueryFilters({ ...queryFilters, reviewer: searchText });
        break;

      case 'status':
        setQueryFilters({ ...queryFilters, status: searchText });
        break;
    }
  }, 500)

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: 1000, pageIndex: 0 },
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination
  );

  const menuItemRef = useRef(null);

  const [totalMenuItems, setTotalMenuItems] = useState(20);

  const handleInfiniteScroll = useCallback((event) => {
    if (!menuItemRef.current) return;

    const element = event.target;

    const currentScroll = element.scrollTop;
    const currentTotalHeight = event.target.scrollHeight - 300;

    if (currentScroll >= currentTotalHeight) {
      setTotalMenuItems(prev => prev + 20 <= pageParams.totalPages ? prev + 20 : prev);

      if (totalMenuItems < pageParams.totalPages - 20)
        event.target.scrollTop = currentTotalHeight / 2;
    }
    if (currentScroll <= 4) {
      setTotalMenuItems(prev => prev - 20 >= 20 ? prev - 20 : 20);

      if (totalMenuItems > 20)
        event.target.scrollTop = currentTotalHeight / 2;
    }
  }, [menuItemRef.current, totalMenuItems, pageParams]);

  const loadPageOptions = React.useCallback(() => {
    const options = [];
    for (let i = totalMenuItems - 19; i <= totalMenuItems + pageParams.totalPages % totalMenuItems && i <= pageParams.totalPages; i++) {
      options.push(
        <MenuItem
          ref={menuItemRef}
          key={i}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={i}
        >
          Página {i}
        </MenuItem>);
    }
    return options;
  }, [totalMenuItems, pageParams])

  function loadTableCells() {
    return page.map((row, i) => {
      prepareRow(row);
      return (
        <tr
          {...row.getRowProps()}
          className={classnames(
            "rt-tr -even"
            // { " -odd": i % 2 === 0 },
            // { " -even": i % 2 === 1 }
          )}
        >
          {row.cells.map((cell) => {
            return (
              <td {...cell.getCellProps()} className="rt-td">
                {cell.render("Cell")}
              </td>
            );
          })}
        </tr>
      );
    })
  }

  useEffect(() => {
    if (dateRange)
      setQueryFilters({ ...queryFilters, start: dateRange[0]?.toISOString().slice(0, 10), end: dateRange[1]?.toISOString().slice(0, 10) })
  }, [dateRange])

  useEffect(() => {
    handlePageSelect(currentPage);
  }, [currentPage]);

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  let pageSelectData = Array.apply(
    null,
    Array(pageOptions.length)
  ).map(function () { });
  let numberOfRowsData = [5, 10, 20, 25, 50, 100];
  return (
    <>

      <div className="ReactTable -striped -highlight">
        <div className="pagination-top">
          <div className="-pagination">
            <div className="-previous">
              <button
                type="button"
                onClick={() => {
                  setCurrentPage(
                    currentPage - 1
                  );
                }}
                disabled={!(currentPage > 1)}
                className="-btn"
              >
                Anterior
              </button>
            </div>
            <div className="-center">
              <GridContainer className={classes.gridContainer}>
                <GridItem xs={12} sm={6} md={4}>
                  <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >

                    <Select

                      onScroll={handleInfiniteScroll}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={pageSelect}
                      onChange={(event) => {
                        setCurrentPage(event.target.value);
                        handlePageSelect(event.target.value);
                      }}
                      inputProps={{
                        name: "pageSelect",
                        id: "page-select",
                      }}
                    >
                      {loadPageOptions()}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={pageParams.pageSize}
                      onChange={(event) => {
                        setPageParams({ ...pageParams, pageSize: Number.parseInt(event.target.value) });
                        setCurrentPage(1);
                        setTotalMenuItems(20)
                      }}
                      inputProps={{
                        name: "numberOfRows",
                        id: "number-of-rows",
                      }}
                    >
                      {numberOfRowsData.map((prop) => {
                        return (
                          <MenuItem
                            key={prop}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={prop}
                          >
                            {prop} linhas
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </div>
            <div className="-next">
              <button
                type="button"
                onClick={() => {
                  setCurrentPage(
                    currentPage + 1
                  );
                }
                }
                disabled={!(currentPage < pageParams.totalPages)}
                className="-btn"
              >
                Próximo
              </button>
            </div>
          </div>
        </div>
        <table {...getTableProps()} className="rt-table">
          <thead className="rt-thead -header">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                {headerGroup.headers.map((column, key) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classnames("rt-th rt-resizable-header", {
                      "-cursor-pointer": headerGroup.headers.length - 1 !== key,
                      "-sort-asc": column.isSorted && !column.isSortedDesc,
                      "-sort-desc": column.isSorted && column.isSortedDesc,
                    })}
                  >
                    <div className="rt-resizable-header-content">
                      {column.render("Header")}
                    </div>
                    {/* Render the columns filter UI */}
                    <div>
                      {key !== headerGroup.headers.length - 1 && <CustomInput
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => {
                            applyFilter(e.target.value, column.id)
                          },
                          placeholder: `Pesquisar resultados`,
                          fontSize: "20px",
                        }}
                      />}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="rt-tbody">
            {essaysLoading ? <CircularProgress style={{ margin: 'auto' }} size={30} color="primary" /> : loadTableCells()}
          </tbody>
        </table>
        <div className="pagination-bottom"></div>
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
