import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const IconArea = styled.div`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  @media (min-width: 1280px) and (max-width: 1480px) {
    margin-top: 0.5rem;
  }

  @media (min-width: 935px) and (max-width: 1280px) {
    margin-top: 0.5rem;
  }

  @media (max-width: 935px) {
    margin-top: 0.5rem;
  }

  @media (max-width: 480px) {
    margin-top: 0.5rem;
  }
`;

export const Input = styled.input`
  font-size: 1rem;
  height: 3.8rem;
  width: 35rem;
  padding: 0.5rem;
  padding-left: 3rem;
  border: 0.15rem solid #FF8000;
  border-radius: 1rem;

  @media (min-width: 1280px) and (max-width: 1440px) {
    width: 25rem;
    margin-top: 1rem;
  }

  @media (min-width: 935px) and (max-width: 1280px) {
    width: 25rem;
    margin-top: 1rem;
  }

  @media (max-width: 935px) {
    width: 25rem;
    margin-top: 1rem;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;
