import styled from "styled-components";

export const TopicData = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;

  white-space: nowrap;
`;

export const Advise = styled.h6`
  font-size: 13px;
  text-transform: none;
`;

export const AudioSection = styled.div`
  margin: 0 10px;
  padding-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
