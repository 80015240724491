import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Muted from "components/Typography/Muted.js";
import useNotification from "utils/hooks/useNotification";
import ModalConfirm from "components/Modal/ModalConfirm";
import EditTagModal from "views/Admin/Modals/EditTagModal";
import localStyles from "./styles.module.css";
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";
import { http } from "lib/api";

const useStyles = makeStyles(styles);

const TableTags = ({ tags }) => {
  const classes = useStyles();

  const { setNotificationSuccess, setNotificationError } = useNotification();

  const [loading, setLoading] = useState(false);

  const [manageTag, setManageTag] = useState(null);

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleDeleteModal = useCallback(async () => {
    await http.delete(`tags/${manageTag._id}`);
    setNotificationSuccess("Tag deletada com sucesso.");
    setOpenDeleteModal(false);
  }, [manageTag, setNotificationSuccess]);

  return (
    <>
      <EditTagModal
        open={openUpdateModal}
        handleClose={() => setOpenUpdateModal(false)}
        tag={manageTag}
      />
      <ModalConfirm
        show={openDeleteModal}
        body="Você tem certeza que quer deletar essa Tag?"
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={handleDeleteModal}
      />
      <Table className={classes.table}>
        <TableBody>
          {tags.length !== 0 ? (
            tags.map((tag) => {
              return (
                <TableRow key={tag._id} className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    <b>{tag.name}</b>: {tag.description}
                  </TableCell>

                  <TableCell className={localStyles.actionButtons}>
                    {loading ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      <>
                        <Tooltip
                          id="tooltip-top"
                          title="Editar"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}>
                          <IconButton
                            aria-label="Edit"
                            className={classes.tableActionButton}
                            onClick={() => {
                              setManageTag(tag);
                              setOpenUpdateModal(true);
                            }}>
                            <Edit
                              className={
                                classes.tableActionButtonIcon +
                                " " +
                                classes.edit
                              }
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          id="tooltip-top-start"
                          title="Deletar"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}>
                          <IconButton
                            aria-label="Close"
                            className={classes.tableActionButton}
                            onClick={() => {
                              setManageTag(tag);
                              setOpenDeleteModal(true);
                            }}>
                            <Close
                              className={
                                classes.tableActionButtonIcon +
                                " " +
                                classes.close
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell>
                <div className={localStyles.noThemeMsg}>
                  <Muted>Cadastre temas para serem relacionados a temas</Muted>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default TableTags;
