import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  align-items: center;
  justify-content: center;

  h1 {
    display: flex;
    align-items: center;

    gap: 15px;

    width: 60%;

    margin: 0 auto;

    line-height: 100px;

    svg {
      cursor: pointer;
    }
  }

  button {
    background-color: #00acc1;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  width: 60%;

  padding: 40px;

  display: flex;
  flex-direction: column;

  gap: 5px;

  background-color: white;

  border-radius: 10px;

  align-items: center;
  justify-content: center;

  div {
    width: 100%;
    border-color: #00acc1;

    :after {
      border-color: #00acc1;
    }
  }

  svg {
    color: #00acc1;
  }
`;

export const LabelContainer = styled.div`
  padding-top: 20px;

  h4 {
    font-weight: bold;
  }
`;

export const MaterialElement = styled.div`
  padding: 20px 0;

  width: 100%;

  button,
  label {
    span {
      display: flex;

      gap: 10px;
    }

    svg {
      color: white;
    }

    background-color: #00acc1;

    color: white;

    &:hover {
      background-color: #005b66;
    }
  }
`;

export const SubmitButton = styled.div`
  width: 100%;

  button {
    background-color: #00acc1;
    width: 100%;

    color: white;

    &:hover {
      background-color: #005b66;
    }
  }
`;
