import { useSelector } from "react-redux";
import * as authSelector from "redux/selectors/auth";

function useAuthUser() {
  const user = useSelector(authSelector.authenticatedUser);
  return {
    user
  };
}

export default useAuthUser;
