import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import Editor, { getTextAlignClassName } from "react-rte";
import classNames from "classnames";

const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "LINK_BUTTONS",
    "HISTORY_BUTTONS",
    "BLOCK_ALIGNMENT_BUTTONS"
  ],
  BLOCK_ALIGNMENT_BUTTONS: [
    { label: "Align Left", style: "ALIGN_LEFT" },
    { label: "Align Center", style: "ALIGN_CENTER" },
    { label: "Align Right", style: "ALIGN_RIGHT" },
    { label: "Align Justify", style: "ALIGN_JUSTIFY" }
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "B", style: "BOLD" },
    { label: "I", style: "ITALIC" },
    { label: "U", style: "UNDERLINE" }
  ],

  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Título 1", style: "header-three" },
    { label: "Título 2", style: "header-four" },
    { label: "Título 3", style: "header-five" }
  ]
};

const TextEditor = ({ onChange, initialText, className, readOnly }) => {
  const [text, setText] = useState(
    Editor.createValueFromString(initialText, "html")
  );
  const handleEdit = (value) => {
    onChange(
      value
        .toString("html")
        .replaceAll("    ", "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")
        .replaceAll("\t", "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")
    );
    setText(value);
  };
  return (
    <Editor
      value={text}
      readOnly={readOnly}
      blockStyleFn={getTextAlignClassName}
      rootStyle={{ border: "none" }}
      className={classNames(className, styles.editorWrapper)}
      editorClassName={styles.editor}
      toolbarClassName={styles.toolbar}
      toolbarConfig={toolbarConfig}
      onChange={handleEdit}
    />
  );
};

TextEditor.propTypes = {
  onChange: PropTypes.func,
  initialText: PropTypes.string,
  className: PropTypes.string
};

TextEditor.defaultProps = {
  initialText: "",
  onChange: () => {}
};

export default TextEditor;
