import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import auth from "./models/auth";
import reviews from "./models/reviews";
import users from "./models/users";
import essays from "./models/essays";
import institutions from "./models/institutions";
import contests from "./models/contests";
import topics from "./models/topics";

const rootReducer = combineReducers({
  auth,
  reviews,
  users,
  essays,
  institutions,
  contests,
  topics
});

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunkMiddleware)
});

export default store;
