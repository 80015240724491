import styled from "styled-components";
import Vimeo from "@u-wave/react-vimeo";

export const Container = styled.div`
  padding: 20px;

  background: white;
  border-radius: 20px;

  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);

  svg {
    cursor: pointer;
  }
`;

export const Title = styled.h1`
  font-weight: bold;
`;

export const VideoContent = styled.div`
  display: flex;

  margin: auto;

  justify-content: center;
  align-items: center;

  overflow-x: hidden;
`;

export const VimeoElement = styled(Vimeo)``;

export const AnnotationSquare = styled.div`
  width: 100%;
  height: 100%;

  flex: 1;

  border-radius: 10px;

  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);

  h3 {
    display: block;

    margin: 0;

    padding: 10px;

    border-radius: 10px 10px 0 0;
    border-bottom: 3px solid #00acc1;
  }

  div {
    padding: 10px;
  }
`;

export const DescriptionContainer = styled.div`
  margin: 15px 0;

  width: 100%;

  h3 {
    display: block;

    margin: 0;

    padding: 10px;

    border-radius: 10px 10px 0 0;
    border-bottom: 3px solid #00acc1;
  }

  div {
    padding: 10px;
  }
`;

export const ChangeVideo = styled.div`
  display: flex;

  width: 100%;

  justify-content: space-between;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    background: #00acc1;

    color: white;

    font-weight: bold;

    padding: 10px;

    border: 0;
    border-radius: 5px;

    :hover {
      background: #005f6b;
    }
  }
`;

export const Header = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;
`;

export const DownloadButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  background: #00acc1;

  color: white;

  font-weight: bold;

  padding: 10px;

  border: 0;
  border-radius: 5px;

  transition: 0.4s background;

  display: ${({ isEnabled }) => (isEnabled ? "flex" : "none")};

  :hover {
    background: #005f6b;
    color: white;
  }
`;
