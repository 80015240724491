import { useDispatch, useSelector } from "react-redux";
import * as essaysActions from "redux/actions/essays";
import { useFetchOnce } from "utils/hooks/useFetchMachine";
import * as essaysSelector from "redux/selectors/essays";
import * as authSelector from "redux/selectors/auth";
import * as usersSelector from "redux/selectors/users";
import * as usersActions from "redux/actions/users";

import { getDataChartAdmin } from "./helpers";

export function useEssaysByAuthUser() {
  const dispatch = useDispatch();

  const user = useSelector(authSelector.authenticatedUser);
  const essays = useSelector(essaysSelector.essaysByUserId(user._id));

  const onFetchEssaysByUserId = () =>
    dispatch(essaysActions.onFetchEssaysByUserId(user._id));
  const { loading, error } = useFetchOnce(onFetchEssaysByUserId, []);

  return {
    essays,
    loading,
    error,
    user
  };
}

export function useAdminStats() {
  const dispatch = useDispatch();
  const stats = useSelector(usersSelector.userAdminStats);
  const onFetchUserAdminStats = () =>
    dispatch(usersActions.onFetchUserAdminStats());
  const { loading, error } = useFetchOnce(onFetchUserAdminStats, []);

  const dataChart = getDataChartAdmin(stats.essays, stats.reviews);

  return {
    stats,
    dataChart,
    loading,
    error
  };
}
