import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// core components
import AuthNavbar from "./AuthNavbar";
import { authRoutes } from "routes.js";
import styles from "./Auth.module.css";

const Auth = (props) => {
  const { ...rest } = props;
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    });
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Autenticação";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  return (
    <div>
      <AuthNavbar brandText={getActiveRoute(authRoutes)} {...rest} />
      <div className={styles.container}>
        <Switch>
          {getRoutes(authRoutes)}
          <Redirect from="/auth" to="/auth/login" />
        </Switch>
      </div>
    </div>
  );
};

export default Auth;
