import React, { Component } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

class AudioController extends Component {
  constructor(props) {
    super(props);
    this.src = props.src;
    this.state = {
      playbackRate: 1 // Velocidade padrão
    };
    this.player = null; // Inicializamos o ref
  }

  changeSpeed = () => {
    if (this.player && this.player.audio.current) {
      let newSpeed =
        this.state.playbackRate === 1
          ? 1.5
          : this.state.playbackRate === 1.5
          ? 2
          : 1;
      this.player.audio.current.playbackRate = newSpeed; // Modifica a velocidade do áudio
      this.setState({ playbackRate: newSpeed });
    }
  };

  render() {
    return (
      <div style={styles.wrapper}>
        <div style={styles.audioPlayer}>
          <AudioPlayer
            src={this.src}
            loop={false}
            autoPlay={false}
            ref={(ref) => (this.player = ref)} // Atribuindo o ref
          />
        </div>

        <div style={styles.controls}>
          <p>Velocidade:</p>
          <button onClick={() => this.changeSpeed()} style={styles.button}>
            {this.state.playbackRate}x
          </button>
        </div>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",

    width: "100%",
    gap: "10px"
  },
  audioPlayer: {
    flex: 9
  },
  controls: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-beetwen",

    height: "89px",

    flex: 1,

    fontWeight: "300",

    padding: "10px 15px",
    backgroundColor: "#fff",
    boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.2)"
  },
  button: {
    border: "none",
    color: "white",
    textAlign: "center",
    textDecoration: "none",
    fontSize: "16px",
    margin: "4px 2px",
    cursor: "pointer",
    borderRadius: "8px",

    backgroundColor: "#4CAF50",

    height: "50%"
  }
};

export default AudioController;
