import * as api from "lib/api";
import * as act from "./types";

export const onFetchInstitutions = () => async (dispatch) => {
  try {
    const response = await api.institutions();
    return dispatch(act.institutions(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onInstitutionNew = (name) => async (dispatch) => {
  try {
    const response = await api.institutionNew(name);
    return dispatch(act.institutionNew(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onInstitutionUpdate = (institutionid, name) => async (
  dispatch
) => {
  try {
    const response = await api.institutionUpdate(institutionid, name);
    return dispatch(act.institutionUpdate(response.data));
  } catch (err) {
    throw err.response;
  }
};

export const onInstitutionDelete = (institutionid) => async (dispatch) => {
  try {
    const response = await api.institutionDelete(institutionid);
    return dispatch(act.institutionDelete(response.data));
  } catch (err) {
    throw err.response;
  }
};
