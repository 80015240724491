import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import ImageMapper from "components/ImageMapper";
import ReviewCard from "./ReviewCard";
import ReviewButtons from "./ReviewButtons";
import {
  getAreaFromCoords,
  getCoordinatesFromEvent,
  getImageMap,
  getCurrentReviewByCoords,
  getReviewsAreas,
  updateReviewPropById
} from "./helpers";
import { POLL_REVIEW_SAVE_INTERVAL } from "constants.js";
import { useInterval } from "utils/hooks";

const EssayReview = ({
  image,
  onReview,
  initialReviews = [],
  readOnly,
  onIneligible,
  essayStatus,
  onSubmit,
  gramaticalOnly
}) => {
  const imageRef = useRef();
  const [markerInitialCoords, setMarkerInitialCoords] = useState([]);
  const [markerCoords, setMarkerCoords] = useState([]);
  const [showAreaMarker, setShowAreaMarker] = useState(false);

  const [reviewsProps, setReviewsProps] = useState(initialReviews);
  const [kind, setKind] = useState(0);

  const onSetReviewPropsById = useCallback(
    (id, props) => {
      setReviewsProps(updateReviewPropById(reviewsProps, id, props));
    },
    [reviewsProps]
  );

  const handleImageClick = (e) => {
    if (readOnly || kind === 0) return;
    const [x, y] = [e.nativeEvent.layerX, e.nativeEvent.layerY];
    const id = Date.now();
    if (readOnly) return;
    if (!showAreaMarker) {
      setMarkerInitialCoords([x, y]);
      setShowAreaMarker(true);
    } else {
      const newArea = getAreaFromCoords(markerCoords, kind, id);
      setShowAreaMarker(false);
      const newProps = [
        ...reviewsProps,
        {
          reviewId: id,
          show: true,
          initialCoords: { x, y },
          area: newArea,
          isEdit: true,
          kind
        }
      ];
      setReviewsProps(newProps);
      setMarkerInitialCoords([]);
      setMarkerCoords([]);
    }
  };

  const handleImageMouseMove = useCallback(
    (e) => {
      if (showAreaMarker) {
        const [x, y] = getCoordinatesFromEvent(e);
        const [mx, my] = markerInitialCoords;
        setMarkerCoords([mx, my, x, y]);
      }
    },
    [markerInitialCoords, showAreaMarker]
  );

  const handleMouseMove = (area, _, e) => {
    if (showAreaMarker && area._id === "marker") {
      handleImageMouseMove(e);
    }
  };

  const handleEscPress = (e) => {
    if (e.keyCode === 27) {
      setMarkerCoords([]);
      setShowAreaMarker(false);
    }
  };

  const handleClickArea = (area, _, e) => {
    if (area._id === "marker" && showAreaMarker) {
      return handleImageClick(e);
    }
    const currentArea = getCurrentReviewByCoords(reviewsProps, area.coords);
    if (!currentArea) return;
    onSetReviewPropsById(currentArea.reviewId, { show: true });
  };

  const handleCloseReview = (id) => {
    onSetReviewPropsById(id, { show: false });
  };

  const handleDelete = (id) => {
    const reviews = reviewsProps.filter(({ reviewId }) => reviewId !== id);
    setReviewsProps(reviews);
  };

  const onChangeReviewText = (reviewId, text) => {
    onSetReviewPropsById(reviewId, { text });
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscPress, false);
    return () => {
      document.removeEventListener("keydown", handleEscPress, false);
    };
  }, []);

  // Save essay periodically
  useInterval(
    () => !readOnly && onReview(reviewsProps),
    POLL_REVIEW_SAVE_INTERVAL
  );

  const imageMap = getImageMap(
    markerCoords,
    getReviewsAreas(reviewsProps),
    kind
  );
  return (
    <>
      <ReviewButtons
        onClick={setKind}
        onReset={() => {
          setMarkerCoords([]);
          setShowAreaMarker(false);
        }}
        onIneligible={() => onIneligible(reviewsProps)}
        onSave={() => onReview(reviewsProps)}
        onSubmit={() => onSubmit(reviewsProps)}
        readOnly={readOnly}
        essayStatus={essayStatus}
        gramaticalOnly={gramaticalOnly}
      />
      {reviewsProps.map((reviewProps) => (
        <>
          {/* <S.ReadmeTitle
            top={
              (reviewProps.area.coords[1] + reviewProps.area.coords[3]) / 2 + 45
            }
            left={
              (reviewProps.area.coords[0] + reviewProps.area.coords[2]) / 2 - 10
            }
            color={reviewProps.area.strokeColor}>
            Leia-me
          </S.ReadmeTitle> */}
          {reviewProps.show && (
            <ReviewCard
              key={reviewProps.reviewId}
              {...reviewProps}
              isEdit={!readOnly && !gramaticalOnly}
              onClose={handleCloseReview}
              onDelete={handleDelete}
              onChange={onChangeReviewText}
              // bottom: imageRef.current.container.clientHeight,
              bounds={{
                right: imageRef.current
                  ? imageRef.current.container.clientWidth - 200
                  : 100,
                bottom: imageRef.current
                  ? imageRef.current.container.clientHeight
                  : 100,
                top: 0,
                left: 0
              }}></ReviewCard>
          )}
        </>
      ))}
      <div style={{ cursor: readOnly || kind === 0 ? "default" : "crosshair" }}>
        <ImageMapper
          src={image}
          ref={imageRef}
          map={imageMap}
          width={1000}
          active={!!imageMap.areas.length}
          onImageClick={handleImageClick}
          onClick={handleClickArea}
          onImageMouseMove={handleImageMouseMove}
          onMouseMove={handleMouseMove}
        />
      </div>
    </>
  );
};

EssayReview.propTypes = {
  image: PropTypes.string.isRequired,
  onReview: PropTypes.func,
  initialReviews: PropTypes.array,
  readOnly: PropTypes.bool
};
EssayReview.defaultProps = {
  onReview: () => {},
  readOnly: false
};

export default EssayReview;
