/* eslint-disable no-unused-vars */
import styled from "styled-components";

export const Banner1 = styled.div`
  width: 100%;
  max-width: 1560px;
  height: 150px;

  border-radius: 6px;

  background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote1/1560x150.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  border-radius: 5px;

  margin: 0 auto;

  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);

  &:hover {
    cursor: pointer;
  }
  @media screen and (min-width: 1921px) {
    max-width: 100%;
    background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote1/1600x150 LOTE 1.png");
    background-size: cover;
  }
  @media screen and (max-width: 1920px) {
    max-width: 1600px;
    height: 150px;
    background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote1/1600x150 LOTE 1.png");
  }
  @media screen and (max-width: 1280px) {
    max-width: 1066px;
    height: 100px;
    background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote1/1066x100 LOTE 1.png");
  }
  @media screen and (max-width: 935px) {
    max-width: 935px;
    height: 100px;
    background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote1/935x100.png");
  }

  @media screen and (max-width: 680px) {
    max-width: 680px;
    height: 100px;
    background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote1/680x100.png");
  }
  @media screen and (max-width: 480px) {
    max-width: 356px;
    height: 75px;
    background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote1/356x75 LOTE 1.png");
  }
  @media screen and (max-width: 270px) {
    max-width: 270px;
    height: 75px;
    background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote1/270x75.png");
  }
`;

export const Banner2 = styled.div`
  width: 100%;
  max-width: 1560px;
  height: 150px;

  border-radius: 6px;

  background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote2/1560x150 lote2.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  border-radius: 5px;

  margin: 0 auto;

  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);

  &:hover {
    cursor: pointer;
  }
  @media screen and (min-width: 1921px) {
    max-width: 100%;
    background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote2/1600x150 LOTE 2.png");
    background-size: cover;
  }
  @media screen and (max-width: 1920px) {
    max-width: 1600px;
    height: 150px;
    background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote2/1600x150 LOTE 2.png");
  }
  @media screen and (max-width: 1280px) {
    max-width: 1066px;
    height: 100px;
    background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote2/1066x100 LOTE 2.png");
  }
  @media screen and (max-width: 935px) {
    max-width: 935px;
    height: 100px;
    background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote2/935x100 lote2.png");
  }

  @media screen and (max-width: 680px) {
    max-width: 680px;
    height: 100px;
    background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote2/680X100 lote2.png");
  }
  @media screen and (max-width: 480px) {
    max-width: 356px;
    height: 75px;
    background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote2/356x75 LOTE 2.png");
  }
  @media screen and (max-width: 270px) {
    max-width: 270px;
    height: 75px;
    background-image: url("https://minhadiscursiva-materials.nyc3.digitaloceanspaces.com/lote2/270x75 lote2.png");
  }
`;
